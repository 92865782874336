import React, {Suspense, lazy} from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import SessionConditionalRoute from "./components/SessionConditionalRoute";
import PublicFrontRedirect from "./components/PublicFrontRedirect";
import SSOCallback from "./components/SSOCallback";

const LoginSignup = lazy(() => import("./containers/app/LoginSignup"));

// const UserDoc = lazy(() => import('./containers/app/UserDoc'));
// const Maintenance = lazy(() => import('./containers/app/Maintenance'));
const Forbidden = lazy(() => import("./containers/app/Forbidden"));
const AppContainer = lazy(() => import("./containers/app/AppContainer"));
// const KnowledgeCenter = lazy(() => import('./containers/app/KnowledgeCenter'));
const Bodacc = lazy(() => import("./containers/app/Bodacc"));
const QuickbooksAuth = lazy(() => import("./components/QuickbooksAuth"));
const TiimeAuth = lazy(() => import("./components/TiimeAuth"));
const PennylaneAuth = lazy(() => import("./components/PennylaneAuth"));

const NotFound = lazy(() => import("./containers/NotFound"));
const ActivationPending = lazy(() => import("./containers/ActivationPending"));
const AccountActivation = lazy(() => import("./components/AccountActivation"));
const MonitoringUnsubscribe = lazy(() => import("./components/MonitoringUnsubscribe"));
const LinkedInLogin = lazy(() => import("./components/LinkedInLogin"));
// const TestCrm = lazy(() => import('./containers/app/TestCrm'));

const Routes = ({childProps}) => (
	<Suspense fallback={null}>
		<Switch>
			<Route path="/" exact>
				<Redirect to="/login" />
			</Route>
			<Route path="/maintenance" exact>
				<Redirect to="/login" />
			</Route>

			<Route path="/login" exact render={(props) => <LoginSignup {...props} {...childProps} action="login" />} />
			<Route path="/signup" exact render={(props) => <LoginSignup {...props} {...childProps} action="signup" />} />
			<Route path="/reinitpassword/:pwdreinitemail/:pwdreinitcode" exact render={(props) => <LoginSignup {...props} {...childProps} action="passwordreinit" />} />
			<Route path="/sso-callback" exact render={(props) => <SSOCallback {...props} {...childProps} />} />
			{/*<AppliedRoute path="/tiime-auth" exact component={TiimeAuth} props={childProps} />
			<AppliedRoute path="/pennylane-auth" exact component={PennylaneAuth} props={childProps} />*/}
			<Route path="/tiime-auth" exact render={(props) => <TiimeAuth {...props} {...childProps} />} />
			<Route path="/pennylane-auth" exact render={(props) => <PennylaneAuth {...props} {...childProps} />} />
			<PublicFrontRedirect path="/contact" />
			<PublicFrontRedirect path="/entrepreneurs" />
			<PublicFrontRedirect path="/evaluateurs" />
			<PublicFrontRedirect path="/accompagnateurs" />
			<PublicFrontRedirect path="/fonctionnalites/:sectionid?" />
			<PublicFrontRedirect path="/tarifs" />
			<PublicFrontRedirect path="/faq" />
			<PublicFrontRedirect path="/blog/:postid?" />
			<PublicFrontRedirect path="/nouveautes" />
			<PublicFrontRedirect path="/partenaires/:partnerid?" />
			<Route path="/forbidden" exact component={Forbidden} />
			<Route path="/activateaccount/:email/:activationcode" exact component={AccountActivation} props={childProps} />
			<Route path="/activateaccount/:email" exact component={AccountActivation} props={childProps} />
			<Route path="/activationpending" exact component={ActivationPending} />
			{/*<Route 										path="/admin/board" 														exact render={ props => <AdminBoard {...props} {...childProps}/>} />*/}
			<AppliedRoute path="/admin/board" exact component={AppContainer} props={childProps} />
			<Route path="/monitoring/unsubscribe/:recipient_email/:dashboard_id" exact component={MonitoringUnsubscribe} props={childProps} />
			<Route path="/linkedin-login" exact component={LinkedInLogin} props={childProps} />
			{/*<Route 										path="/admin/board" 														exact component={AdminBoard} props={childProps}/>} />*/}
			{/*<AppliedRoute 						path="/kjdfnbgkuefhglerkgnjerkge/test-doc" 			exact component={UserDoc} props={childProps} />*/}
			<AppliedRoute path="/companies" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/profile" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/subscribe" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/subscribe/white-label" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/subscribe/:planid" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/referral" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/advisor" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/project-templates" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/project-template" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/users" exact component={AppContainer} props={childProps} />
			{/*<AppliedRoute 						path="/knowledge" 															exact component={KnowledgeCenter} props={childProps} />*/}
			<AppliedRoute path="/market" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/maps" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/lookup" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/bodacc/:siren" exact component={Bodacc} props={childProps} />

			<SessionConditionalRoute path="/company" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/financials/accounts" exact component={AppContainer} props={childProps} />
			<AppliedRoute path="/financials/quickbooks-auth" exact component={QuickbooksAuth} props={childProps} />
			<SessionConditionalRoute path="/financials/benchmark" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/project/build" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/forecasts" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/report" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/monitoring" exact component={AppContainer} props={childProps} />
			<SessionConditionalRoute path="/dashboard" exact component={AppContainer} props={childProps} />

			{/*<Route							path="/test-crm-jshdgfbjhegjdgfjefv" 						exact component={TestCrm} props={childProps} />*/}
			<Route component={NotFound} />
		</Switch>
	</Suspense>
);

export default Routes;
