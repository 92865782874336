import React from "react";
import {withRouter} from "react-router-dom";
import {OverlayTrigger, Popover} from "react-bootstrap";
// import Isvg from 'react-inlinesvg';
import SvgWrap from "./SvgWrap";
// import 'bootstrap-icons/font/bootstrap-icons.css';
import iconLink from "../images/icons/link.svg";
import iconUnlink from "../images/icons/unlink.svg";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import iconCalculator from "../images/icons/calculette.svg";

import "../App.css";
import "./InfoTips.css";

export function CompanySectorInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CompanySectorInfoTip"} className="infoPopover medium">
					<Popover.Body>
						L&apos;indication du secteur d&apos;activité de l&apos;entreprise n&apos;est pas obligatoire mais permettra, pour une entreprise française uniquement, la comparaison de
						ses ratios financiers par rapport à ceux des autres entreprises du même secteur (option &quot;Comparaison aux pairs&quot;).
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyMicroStatusInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={
				<Popover id={(props.id || "") + "CompanyMicroStatusInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le régime de la micro-entreprise est un régime fiscal simplifié ouvert aux entreprises individuelles qui ne dépassent pas certains seuils de chiffre d’affaires.
						<br />
						Il permet à l’entrepreneur de bénéficier d’obligations comptables et déclaratives relativement réduites, de relever du régime micro-social simplifié, d’être en
						franchise de TVA et d’opter sous conditions pour le versement fiscal libératoire.
						<br />
						Pour plus d&apos;informations, consulter&nbsp;
						<a href="https://www.service-public.fr/professionnels-entreprises/vosdroits/F23267" target="_blank" rel="noopener noreferrer">
							le site du Gouvernement
						</a>
						.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyMicroStatusParameterInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "CompanyMicroStatusParameterInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Vous pouvez indiquer ici s&apos;il s&apos;agit d&apos;une entreprise individuelle sous le régime de la micro-entreprise, afin d&apos;adapter automatiquement les
						paramètres d&apos;imposition.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyMicroTypeParameterInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={
				<Popover id={(props.id || "") + "CompanyMicroTypeParameterInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Selon le type d&apos;activité, des seuils de chiffre d&apos;affaires et des barèmes d&apos;imposition spécifiques s&apos;appliquent :
						<ul>
							<li>éligibilité au statut de micro-entreprise jusqu&apos;à certains montants de chiffre d&apos;affaires annuel</li>
							<li>franchise de TVA jusqu&apos;à certains montants de chiffre d&apos;affaires annuel</li>
							<li>taux d&apos;imposition sur le chiffre d&apos;affaires</li>
						</ul>
						<a href="https://www.economie.gouv.fr/entreprises/micro-entreprise-auto-entreprise-charges-sociales" target="_blank" rel="noopener noreferrer">
							Plus d&apos;informations sur le site du Gouvernement
						</a>
						<br />
						<br />
						En indiquant ici le type d&apos;activité de l&apos;entreprise, la cohérence de vos volumes d&apos;activité prévisionnels pourra être vérifiée par rapport aux seuils
						limites de chiffre d&apos;affaires, et les paramètres d&apos;imposition seront adaptés.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyLocalIdInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CompanyLocalIdInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Si l&apos;entreprise dispose d&apos;un numéro d&apos;identification, indiquez-le ici de sorte à pouvoir charger ses états financiers historiques.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyDeptInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CompanyDeptInfoTip"} className="infoPopover medium">
					<Popover.Body>
						L&apos;indication du département de l&apos;entreprise n&apos;est pas obligatoire mais permettra, pour une entreprise française uniquement, la comparaison de ses ratios
						financiers par rapport à ceux des autres entreprises de la même zone géographique (option &quot;Comparaison aux pairs&quot;).
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

/*
export function NewCoYearInfoTip(props) {
	return (
		<OverlayTrigger trigger="click" rootClose placement="top" overlay={(
				<Popover id={(props.id || "") + "NewCoYearInfoTip"} className="infoPopover small"><Popover.Body>
					Correspond à l&apos;année à laquelle vous souhaitez commencer à saisir les éléments de votre projet (réalisés ou prévisionnels).<br/>
					<br/>
					<b>Note</b> :<br/>
					<ul>
						<li>Dans le cas d&apos;un <b>exercice fiscal décalé</b> (clôturant un autre mois que décembre), l&apos;année choisie doit être celle de la clôture du 1er exercice</li>
						<li>Si le 1er exercice fiscal est de <b>durée supérieure à 12 mois</b>, la clôture interviendra l&apos;année suivant celle sélectionnée</li>
					</ul>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

export function NewCoYearInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "NewCoYearInfoTip"} className="infoPopover small">
					<Popover.Body>
						Indiquez la date à laquelle démarre votre prévisionnel, et la date de clôture du premier exercice comptable.
						<br />
						<br />
						Note :<br />
						L&apos;exercice doit être d&apos;une <b>durée comprise entre 1 et 24 mois</b>.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecSpecsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={typeof props.placement !== "undefined" ? props.placement : "left"}
			overlay={
				<Popover id={(props.id || "") + "FecSpecsInfoTip"} className="infoPopover large">
					<Popover.Body>
						Le Fichier des Écritures Comptables (FEC) est un document informatique qui contient l&apos;ensemble des enregistrements du système comptable d&apos;une entreprise. Il
						est généré directement depuis le logiciel de comptabilité de l&apos;entreprise ou celui de l&apos;expert-comptable.
						<br />
						<br />
						Pour être accepté le fichier doit :<br />
						<br />
						<ul>
							<li>
								<b>Respecter la convention de nommage :</b>
								<br />
								Le fichier FEC doit être nommé
								<br />
								<i>&quot;[SIREN sur 9 chiffres]FEC[Date de clôture au format AAAAMMJJ]&quot;</i>
								,<br />
								par exemple <i>&quot;123456789FEC20181231.csv&quot;</i>
							</li>
							<br />
							<li>
								<b>
									Avoir un contenu conforme aux{" "}
									<a href="https://bofip.impots.gouv.fr/bofip/9028-PGP" target="_blank" rel="noopener noreferrer">
										spécifications officielles du Fichier des Écritures Comptables
									</a>
								</b>
							</li>
						</ul>
						<br />
						Le FEC peut correspondre :
						<ul>
							<li>
								soit à un exercice fiscal complet, c&apos;est-à-dire allant du premier jour de l&apos;exercice jusqu&apos;à la date de clôture de l&apos;exercice, auquel cas il
								pourra être utilisé comme <b>point de départ de la projection</b>,
							</li>
							<li>
								soit à une situation intermédiaire en cours d&apos;exercice, qui pourra être utilisée pour <b>suivre le réalisé ou mettre à jour un prévisionnel</b>.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function GLSpecsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={typeof props.placement !== "undefined" ? props.placement : "left"}
			overlay={
				<Popover id={(props.id || "") + "GLSpecsInfoTip"} className="infoPopover large">
					<Popover.Body>
						Le Grand livre comptable est un fichier informatique qui contient l&apos;ensemble des enregistrements du système comptable d&apos;une entreprise, sur une période de
						dates donnée. Il est généré directement depuis le logiciel de comptabilité de l&apos;entreprise ou celui de l&apos;expert-comptable.
						<br />
						<br />
						Il se décline généralement en 2 formats :
						<ul>
							<li>Le grand livre général, qui contient les opérations sur l&apos;ensemble des comptes du plan comptable,</li>
							<li>Les grands livres auxiliaires, tels que le grand livre clients ou fournisseurs.</li>
						</ul>
						<b>Seul le grand livre général permet d&apos;obtenir une situation cohérente dans l&apos;application.</b>
						<br />
						<br />
						Pour être accepté le fichier doit :<br />
						<br />
						<ul>
							<li>
								être au bon format :
								<ul>
									<li>Excel (xlsx, xls, xlsm)</li>
									<li>Texte ou csv</li>
								</ul>
							</li>
							<li>
								contenir a minima les colonnes suivantes :
								<ul>
									<li>Numéro de compte (exemple : 101),</li>
									<li>Intitulé du compte (exemple : Capital),</li>
									<li>Code du journal de comptabilisation de l&apos;opération (exemple : AN, HA, VT...),</li>
									<li>Libellé du journal de comptabilisation de l&apos;opération,</li>
									<li>Date de l&apos;opération,</li>
									<li>Libellé de l&apos;opération,</li>
									<li>Numéro de pièce comptable ou référence de l’opération,</li>
									<li>Montant au débit,</li>
									<li>Montant au crédit.</li>
								</ul>
							</li>
						</ul>
						<br />
						Le grand livre peut correspondre :
						<ul>
							<li>
								soit à un exercice fiscal complet, c&apos;est-à-dire allant du premier jour de l&apos;exercice jusqu&apos;à la date de clôture de l&apos;exercice, auquel cas il
								pourra être utilisé comme <b>point de départ de la projection</b>,
							</li>
							<li>
								soit à une situation intermédiaire en cours d&apos;exercice, qui pourra être utilisée pour <b>suivre le réalisé ou mettre à jour un prévisionnel</b>.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ForecastModeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "ForecastModeInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le <b>mode de projection</b> choisi détermine la manière dont l&apos;antériorité financière est prise en compte.
						<br />
						<br />
						<ul>
							<li>
								En mode <b>Continuité</b>&nbsp;
								{/*<Isvg wrapper={React.createFactory('span')} src={iconLink} className="textSvg" />*/}
								<SvgWrap wrapperTag="span" src={iconLink} className="textSvg" />
								, les éléments correspondant à l&apos;activité d&apos;exploitation de la dernière année connue (provenant des éléments financiers historiques) sont reconduits sur
								l&apos;horizon de projection. Il s&apos;agit des revenus, charges, créances et dettes d&apos;exploitation.
								<br />
								Par ailleurs, en cohérence avec la continuité d&apos;exploitation, le niveau des investissements est automatiquement maintenu : l&apos;amortissement des
								immobilisations est compensé par un montant égal de ré-investissement.
								<br />
								Les hypothèses du projet, de revenus et de charges, viennent s&apos;ajouter aux éléments reconduits dans le temps.
							</li>
							<br />
							<li>
								En mode <b>Rupture</b>&nbsp;
								{/*<Isvg wrapper={React.createFactory('span')} src={iconUnlink} className="textSvg" />*/}
								<SvgWrap wrapperTag="span" src={iconUnlink} className="textSvg" />
								, les revenus et charges d&apos;exploitation de la dernière année connue ne sont pas reconduits automatiquement.
								<br />
								Les créances clients et dettes fournisseurs sont encaissées / décaissées dans un délai correspondant au délai de paiement moyen calculé à partir des états
								financiers de référence.
								<br />
								Les immobilisations s&apos;amortissent sans ré-investissement.
								<br />
								La projection d&apos;activité future de l&apos;entreprise repose alors entièrement sur les hypothèses du projet, de revenus, de charges, et d&apos;investissements.
								<br />
								Les actifs et ressources à long terme tels que les fonds propres, les dettes financières et les investissements sont quant à eux conservés, avec leur éventuel
								amortissement.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DebtScheduleInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DebtScheduleInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Saisissez ci-dessous les montants des <b>remboursements</b> (prévisionnels ou réalisés) d&apos;emprunts, et non les montants d&apos;emprunt restant à rembourser.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

/*
export function TradeReceivablesScheduleInfoTip(props) {
	return (
		<OverlayTrigger trigger="click" rootClose placement="top" overlay={(
				<Popover id={(props.id || "") + "TradeReceivablesScheduleInfoTip"} className="infoPopover small"><Popover.Body>
					<p>Saisissez ci-dessous les montants des <b>encaissements</b> (prévisionnels ou réalisés) sur factures clients, et non les montants restant à encaisser.</p>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}

export function TradePayablesScheduleInfoTip(props) {
	return (
		<OverlayTrigger trigger="click" rootClose placement="top" overlay={(
				<Popover id={(props.id || "") + "TradePayablesScheduleInfoTip"} className="infoPopover small"><Popover.Body>
					<p>Saisissez ci-dessous les montants des <b>décaissements</b> (prévisionnels ou réalisés) sur factures fournisseurs, et non les montants restant à décaisser.</p>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}

export function DeferredIncomeReversalInfoTip(props) {
	return (
		<OverlayTrigger trigger="click" rootClose placement="top" overlay={(
				<Popover id={(props.id || "") + "DeferredIncomeReversalInfoTip"} className="infoPopover small"><Popover.Body>
					<p>Des produits et charges constatés d&apos;avance sont enregistrés au bilan lorsque des factures ont été émises mais que la prestation ou la livraison correspondante 
					n&apos;a pas encore eu lieu à la clôture de l&apos;exercice.</p>
					<p>Généralement, ces montant sont passés en compte de résultat (<i>extournés</i>) à l&apos;ouverture de l&apos;exercice suivant. C&apos;est le traitement par défaut dans l&apos;application.</p>
					<p>Pour répondre à certains cas particuliers, vous pouvez choisir ici de les maintenir au bilan.</p>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

/*
export function StockForecastModeInfoTip(props) {
	return (
		<OverlayTrigger trigger="click" rootClose placement="right" overlay={(
				<Popover id={(props.id || "") + "StockForecastModeInfoTip"} className="infoPopover medium"><Popover.Body>
					<p>Un nouveau mode de calcul des stocks a été introduit dans l&apos;application depuis janvier 2020.</p>
					<p>
						Jusqu&apos;à présent dans les projections calculées, les niveaux de stocks de marchandises, matières premières et produits étaient établies par défaut &quot;à délai de rotation
						constant&quot; *, et dépendaient ainsi directement des montants d&apos;achats de marchandises et matières premières de chaque période. Ce mode de calcul ayant l&apos;avantage d&apos;être simple 
						et d&apos;éviter tout paramétrage, ne permettait pas toujours de refléter avec précision la réalité économique de la gestion des stocks de l&apos;entreprise.
					</p>
					<p>
						Un nouveau mode de calcul est désormais accessible. Il permet, <b>pour chaque type d&apos;hypothèse parmi les achats et ventes de marchandises, achats
						de matières premières et ventes de produits</b>, de définir quel sera <b>son impact sur les stocks de la catégorie concernée</b>, et ainsi d&apos;obtenir des projections de trésorerie 
						et de compte de résultat plus précises et fidèles au fonctionnement de l&apos;activité.
					</p>
					<p><b>Choisissez ici si vous souhaitez appliquer ce nouveau mode de calcul des stocks.</b></p>
					<p>
						A noter : le changement de mode de calcul peut faire varier les projections financières précédemment calculées. Nous vous invitons à contrôler la cohérence de vos 
						hypothèses d&apos;achats et de ventes. Vous pouvez par exemple utiliser pour cela l&apos;outil de visualisation de l&apos;évolution des stocks (menu &quot;Outils&quot;) pour vérifier que les niveaux de stocks découlant 
						de vos hypothèses restent bien positifs sur l&apos;horizon de projection (autrement dit que vous ne vendez pas plus que ce que vous achetez ou produisez).
					</p>
					<p className="smallNote">
						* Le délai de rotation d&apos;un stock représente la durée moyenne nécessaire à l&apos;écoulement du stock.
					</p>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

export function AdvancedStockModeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AdvancedStockModeInfoTip"} className="infoPopover xlarge">
					<Popover.Body>
						<p>
							Le mode avancé de gestion des stocks permet,{" "}
							<b>pour chaque type d&apos;hypothèse parmi les achats et ventes de marchandises, achats de matières premières et ventes de produits</b>, de moduler quel sera{" "}
							<b>son impact sur les stocks de la catégorie concernée</b>, et ainsi sur les projections de compte de résultat.
						</p>
						<p>Si vous activez le mode avancé, vous aurez accès à 2 paramètres supplémentaires, vous permettant de définir comment les stocks prévisionnels évolueront :</p>
						<ul>
							<li>
								Sur les <b>achats</b> de marchandises et de matières premières (au niveau de la saisie avancée) : la possibilité d&apos;indiquer s&apos;il s&apos;agit d&apos;achats{" "}
								<b>stockés</b> ou bien consommés immédiatement.
								<br />
								Lorsque les achats sont consommés immédiatement, cette consommation créé une charge d&apos;exploitation en compte de résultat.
								<br />
								Lorsqu&apos;ils sont stockés, alors ils viennent augmenter le stock prévisionnel, et l&apos;impact en compte de résultat est neutre. Vous devez alors préciser le
								niveau de consommation du stock via le paramètre de taux de marge sur les ventes (cf. ci-dessous).
							</li>
							<br />
							<li>
								Sur les <b>ventes</b> de marchandises et de produits (au niveau de la saisie avancée) : la possibilité de saisir un <b>taux de marge</b> (commerciale ou brute) à
								partir duquel la consommation de stock sera calculée.
								<br />
								<b>Par défaut</b>, si vous n&apos;activez pas le mode avancé, alors les consommations sont liées aux achats (cf. ci-dessus) et non aux ventes.
							</li>
						</ul>
						<p>
							Par exemple :<br />
							En mode avancé, si vous saisissez une hypothèse de ventes de marchandises de 120 par an, avec un taux de marge commerciale de 20% sur coût de revient, alors la
							consommation annuelle de stock sera égale à 100.
							<br />
							Si en parallèle vous saisissez une hypothèse d&apos;achats de marchandises stockées de 110 par an, alors chaque année le stock prévisionnel de marchandises augmentera
							de 10 (+110 stockés -100 consommés).
						</p>
						<p>
							<b>Par défaut</b>, si vous n&apos;activez pas le mode avancé, alors les achats sont considérés comme immédiatement consommés, et aucune consommation supplémentaire
							n&apos;est calculée à partir des ventes. Ainsi, le stock reste constant sur l&apos;horizon de projection.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AdvancedAccountingDefermentInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AdvancedAccountingDefermentInfoTip"} className="infoPopover xlarge">
					<Popover.Body>
						<p>
							En comptabilité, les charges et les produits doivent être rattachés à la période à laquelle ils se réfèrent, c&apos;est-à-dire la période pendant laquelle les
							prestations ou services sont exécutés, qui peut être différente de la période à laquelle les montants correspondant sont encaissés ou décaissés.
						</p>
						<p>Par exemple :</p>
						<ul>
							<li>
								La charge correspondant à une prime d&apos;assurance payée annuellement peut être étalée sur 12 mois puisqu&apos;elle porte sur un service (la protection
								d&apos;assurance) s&apos;appliquant tout au long de l&apos;année.
							</li>
							<li>Ou encore, un contrat de prestation de service vendu et encaissé d&apos;avance en une seule fois, dont la réalisation est étalée sur plusieurs mois.</li>
						</ul>
						<p>
							En activant le paramètre de gestion de l&apos;étalement comptable des charges et des produits, vous avez la possibilité de préciser, dans la définition de vos
							hypothèses de Coûts externes et de Ventes de services, si la comptabilisation doit être étalée sur plusieurs mois ou non.
						</p>
						<p>
							<u>A noter</u> : L&apos;activation de l&apos;étalement comptable a une incidence sur le calcul du résultat net comptable annuel, et donc sur le montant d&apos;impôt
							sur les bénéfices auquel peut être soumise l&apos;entreprise. Il n&apos;a en revanche pas d&apos;effet direct sur la date d&apos;encaissement (ou de décaissement)
							correspondant à la prestation vendue (ou achetée).
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function StocksCheckerInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "StocksCheckerInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Un stock négatif signifie que les consommations sont supérieures aux approvisionnements, autrement dit que vous vendez plus que ce que vous achetez ou produisez.</p>
						<p>Il est nécessaire dans ce cas d&apos;augmenter les achats, soit de marchandises soit de matières premières, pour rééquilibrer les stocks.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutoReinvestInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "AutoReinvestInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Lorsque la projection s&apos;appuie sur des états financiers historiques, l&apos;application peut calculer automatiquement le montant de réinvestissement annuel
							nécessaire pour maintenir à niveau la valeur des immobilisations corporelles, incorporelles et financières nettes des amortissements.
						</p>
						<p>
							Lorsque la projection est effectuée en mode <i>continuité</i> par rapport aux états financiers historiques, le paramètre de réinvestissement est activé par défaut, en
							cohérence avec un maintien à niveau de l&apos;outil de production.
						</p>
						<p>
							Si le réinvestissement automatique est désactivé, les immobilisations continuent de s&apos;amortir au même rythme que celui calculé à partir des états financiers
							historiques.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function KeepHistoOpsMetricsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "KeepHistoOpsMetricsInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Lorsque la projection s&apos;appuie sur des états financiers historiques, vous pouvez choisir d&apos;utiliser, ou non, les valeurs de délais de paiement et de taux de
							marge historiques comme valeurs par défaut des hypothèses prévisionnelles saisies.
						</p>
						<p>Si ce paramètre de reconduction est désactivé, les délais de paiement et taux de marge sont par défaut remis à zéro.</p>
						<p>Dans tous les cas, vous gardez la possibilité de définir les valeurs de délai de paiement et de taux de marge au niveau de chaque hypothèse concernée.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

function InvCorpInfoTip() {
	return (
		<span>
			Les investissements corporels sont les <b>investissements physiques</b> destinés à être utilisés durablement par l&apos;entreprise pour{" "}
			<b>maintenir ou accroître le potentiel de production</b>.<br />
			<br />
			Exemples : du matériel et outillage industriels, constructions, installations techniques ...
		</span>
	);
}

function InvIncorpInfoTip() {
	return (
		<span>
			Les investissements incorporels sont les biens immatériels, qui <b>n&apos;ont pas de substance physique</b>.<br />
			Ils sont détenus par l&apos;entreprise sur une durée supérieure à une année.
			<br />
			<br />
			Exemples : un fonds commercial, des logiciels, une marque, un droit au bail, un brevet, des licences ...
		</span>
	);
}

function InvFinInfoTip() {
	return (
		<span>
			Les investissements financiers peuvent être des cautions, des titres de participations ...
			<br />
			<br />
			Exemple : l&apos;achat de parts du capital d&apos;une autre société dans le but d&apos;exercer une influence durable sur cette société
		</span>
	);
}

function CoutsMarchInfoTip() {
	return (
		<span>
			Les marchandises sont des <b>biens</b> que l&apos;entreprise achète dans le but de les <b>revendre tels quels</b> (sans intégration ni transformation) tout en faisant un
			bénéfice.
		</span>
	);
}

function CoutsMatPremInfoTip() {
	return (
		<span>
			Les matières premières désignent l&apos;ensemble des <b>produits servant à la production</b> ou à la fabrication de produits finis ou manufacturés.
			<br />
			Cela regroupe aussi bien les matériaux permettant de fabriquer ces produits finis que les denrées énergétiques nécessaires à leur élaboration.
			<br />
			<br />
			Exemples : les métaux, le blé, les huiles, les minerais, le gaz ...
		</span>
	);
}

function CoutsPersoInfoTip() {
	return (
		<span>
			Il s&apos;agit des <b>rémunérations versées au personnel</b> de l’entreprise (salariés ou dirigeants), y compris les <b>cotisations sociales et fiscales</b> liées à ces
			rémunérations.
			<br />
			<br />
			Exemples : les salaires, les primes exceptionnelles, les indemnités ...
		</span>
	);
}

function CoutsExtInfoTip() {
	return (
		<span>
			Il s&apos;agit des charges et dépenses <b>autres que les salaires, les taxes et les coûts des ventes</b>.<br />
			<br />
			Exemples :<br />
			Les études et recherches, les honoraires d’expert comptable et d’avocat, les prestations de conseil, la publicité, les dépenses d’eau et d&apos;électricité, les fournitures
			administratives, le personnel intérimaire, la redevance de crédit-bail, la location mobilière ou immobilière, les charges locatives, l&apos;entretien et les réparations, les
			assurances, le transport, les frais de télécommunications, de représentation et de déplacements, les services bancaires, les cadeaux d’entreprise ...
		</span>
	);
}

function CoutsExploitInfoTip() {
	return (
		<span>
			Les autres coûts d’exploitation regroupent les charges liées à l’activité de l’entreprise n’entrant pas dans les catégories ci-dessus.
			<br />
			<br />
			Exemples : les taxes et assimilés, les redevances pour licences, brevets, droits d’auteur ...
		</span>
	);
}

function CoutsTaxInfoTip() {
	return (
		<span>
			Il s&apos;agit des différents impôts et taxes autres que l&apos;impôt sur les bénéfices, dont l&apos;entreprise doit s&apos;acquitter auprès de l&apos;administration ou des
			collectivités territoriales.
			<br />
			<br />
			En France par exemple cela peut correspondre à la contribution foncière des entreprises, à la taxe d&apos;apprentissage, etc. Ces taxes peuvent être définies comme un montant
			fixe, ou bien en pourcentage du chiffre d&apos;affaires réalisé par l&apos;entreprise.
		</span>
	);
}

function CoutsFinInfoTip() {
	return (
		<span>
			Les charges financières correspondent à la somme <b>des frais financiers et des intérêts d&apos;emprunts</b>.
			<br />
			Remarque : le capital remboursé d’un crédit n’est pas une charge financière.
		</span>
	);
}

function CoutsDividInfoTip() {
	return <span>Les dividendes correspondent à la part du résultat bénéficiaire de l&apos;exercice précédent distribuée aux actionnaires de la société.</span>;
}

function CoutsProvExploitInfoTip() {
	return <span>Des provisions peuvent être passées pour constater une charge future ou un risque de perte probable.</span>;
}

function RevNegoInfoTip() {
	return (
		<span>
			Le chiffre d&apos;affaires de négoce correspond à la <b>revente directe de marchandises</b> à vos clients (achat-revente).
			<br />
			Le montant doit être saisi Hors-Taxes (HT).
			<br />
			<br />
			Exemple :<br />
			Vous fabriquez et vendez un appareil électronique et vous proposez une housse de protection supplémentaire que vous achetez à un fournisseur. Le revenu de la vente des
			protections correspond à du chiffre d&apos;affaire négoce.
		</span>
	);
}

function RevProdInfoTip() {
	return (
		<span>
			Le chiffre d&apos;affaires de production correspond à la <b>vente de produits finis que vous avez fabriqués ou transformés</b> à partir de matières premières.
			<br />
			Le montant doit être saisi Hors-Taxes (HT).
			<br />
			<br />
			Exemples : du pain pour une boulangerie, des pièces de moteur électrique ...
		</span>
	);
}

function RevServInfoTip() {
	return (
		<span>
			Le chiffre d&apos;affaires de services se caractérise par la vente d&apos;une <b>prestation technique</b> (ex: fourniture d’un transport) <b>ou intellectuelle</b> (ex:
			formation).
			<br />
			Le montant doit être saisi Hors-Taxes (HT).
			<br />
			<br />
			Exemples :<br />
			le chiffre d&apos;affaires lié à des prestations de conseil (en direct ou via de la sous-traitance), la formation de vos clients sur le produit que vous avez fabriqué ...
		</span>
	);
}

function RevExploitInfoTip() {
	return (
		<span>
			Cela correspond à tous les revenus qui ne sont pas liés à l&apos;activité normale de votre entreprise.
			<br />
			<br />
			Exemple : vous êtes une société de conseil en marketing, et vous êtes propriétaire d&apos;un bâtiment. Vous louez le 1er étage. Les revenus de cette location sont considérés
			comme &quot;Autres revenus d&apos;exploitation&quot;.
		</span>
	);
}

function RevFinInfoTip() {
	return (
		<span>
			Revenus acquis par l&apos;entreprise qui se rapportent à des éléments financiers (titres, comptes bancaires, devises, placements).
			<br />
			<br />
			Exemples :<br />
			la rémunération de vos dépôts à la banque, les dividendes versés par une autre entreprise dont votre entreprise détient des parts, la rémunération d&apos;un prêt que vous
			avez accordé à une entreprise de votre groupe ...
		</span>
	);
}

function RevExcepInfoTip() {
	return (
		<span>
			Les produits exceptionnels correspondent à des revenus acquis par l&apos;entreprise ayant un caractère ponctuel ou imprévu, et n&apos;étant pas liés à son cycle de
			fonctionnement habituel.
			<br />
			<br />
			Exemples :<br />
			des indemnités ou pénalités perçues, des plus-values de cession de biens...
		</span>
	);
}

function CoutsExcepInfoTip() {
	return (
		<span>
			Charges ou dépenses ayant un caractère ponctuel ou imprévu, et n&apos;étant pas liées au cycle de fonctionnement habituel de l&apos;entreprise.
			<br />
			<br />
			Exemples :<br />
			des indemnités ou pénalités versées, des franchises d&apos;assurance, des moins-values de cession de biens...
		</span>
	);
}

function FinKPInfoTip() {
	return <span>Les capitaux propres correspondent aux sommes versées au capital par les associés ou les actionnaires.</span>;
}

function FinCCAssoInfoTip() {
	return <span>Les comptes courants d&apos;associés correspondent aux sommes avancées par les associés ou les actionnaires.</span>;
}

function FinDetCTInfoTip() {
	return (
		// <span>
		// Les <b>emprunts</b> à court terme sont ceux remboursables sous une <b>durée inférieure à 1 an</b>, <i>in fine</i> (c&apos;est-à-dire en totalité à l&apos;échéance).<br/>
		// Le montant représente le capital emprunté par l&apos;entreprise.<br/>
		// <br/>
		// Exemples : une ligne de crédit obtenue auprès de votre banque, un découvert autorisé ...
		// </span>
		<span>
			Un crédit de trésorerie est un <b>emprunt</b> à court terme, remboursable sous une <b>durée maximale d&apos;1 an</b>, <i>in fine</i> (c&apos;est-à-dire en totalité à
			l&apos;échéance).
			<br />
			Le montant représente le capital emprunté par l&apos;entreprise.
			<br />
			<br />
			Exemples : une ligne de crédit obtenue auprès de votre banque, un découvert autorisé ...
		</span>
	);
}

function FinDetLTInfoTip() {
	return (
		<span>
			Les <b>emprunts financiers</b> correspondent aux crédits obtenus auprès d&apos;établissements financiers, institutions, plateformes de financement participatif, etc.
			<br />
			Ils sont en général souscrits pour une <b>durée initiale en général supérieure à 1 an</b>.<br />
			Le montant représente le capital emprunté par l&apos;entreprise.
			<br />
			<br />
			Exemples : un prêt bancaire, un prêt BPI, un prêt à la création d&apos;entreprise ...
			<br />
			<br />
			A noter : par défaut le taux d&apos;intérêt annuel appliqué sur les montants restant à rembourser est fixé à 2%. Les montants d&apos;intérêts correspondants sont pris en
			compte dans le poste &quot;Autres charges&quot; du compte de résultat synthétique.
			<br />
			Vous avez la possibilité de modifier le taux dans la section &quot;Saisie avancée&quot; de l&apos;hypothèse créée.
			<br />
			<br />
			Remarque: si les prévisions conduisent à un <b>besoin d&apos;un financement</b> (si la trésorerie devient négative sur une période limitée sur l&apos;horizon du
			prévisionnel), vous avez la possibilité d&apos;utiliser la fonction de <b>calcul automatique d&apos;un prêt optimal</b> dans le menu OUTILS &gt; Besoin de financement.
			Attention, cette fonction ne donne un résultat que si l&apos;entreprise est en mesure de rembourser l’emprunt.
		</span>
	);
}

function FinSubvExploitInfoTip() {
	return (
		<span>
			Aides versées à titre définitif et <b>sans aucune obligation de remboursement</b>.<br />
			Généralement octroyées par des collectivités territoriales, destinées à compenser certaines charges d&apos;exploitation ou des revenus d&apos;exploitation insuffisants.
			<br />
			<br />
			Exemples : les aides reçues lors de l&apos;embauche de certains salariés en contrat aidé, les compensations tarifaires ...
		</span>
	);
}

function FinSubvInvInfoTip() {
	return (
		<span>
			Financements versés à titre définitif et <b>sans aucune obligation de remboursement</b>.<br />
			Généralement octroyées par des collectivités territoriales, destinées à financer des investissements.
			<br />
			<br />
			Exemples : les aides à la R&D d&apos;innovation, à la réalisation d&apos;un projet de développement de l&apos;activité ...
		</span>
	);
}

function FinCrImpotInfoTip() {
	return (
		<span>
			Somme octroyée par l&apos;administration fiscale, venant en <b>déduction de l&apos;impôt sur les sociétés</b>.
			<br />
			<br />
			Si le montant du crédit d&apos;impôt est supérieur au montant des impôts payable l&apos;année concernée, l&apos;excédent est reporté sur la ou les années suivantes.
			<br />
			<br />
			Exemples : CIR, CICE, crédit d&apos;impôt innovation ...
		</span>
	);
}

function FinExImpotInfoTip() {
	return (
		<span>
			<b>Exonération totale ou partielle d&apos;impôt sur les sociétés</b> accordée par l&apos;administration fiscale.
			<br />
			<br />
			Les impôts exonérés sont annulés définitivement et ne sont pas reportés aux années suivantes.
			<br />
			<br />
			Exemples : Entreprises ayant le statut de JEI (Jeune Entreprise Innovante), ou bien s&apos;installant dans certaines zones du territoire considérées comme défavorisées.
		</span>
	);
}

function UnitVariableInfoTip() {
	return (
		<span>
			Utilisez des variables élémentaires pour modéliser les <b>données ou unités d&apos;oeuvre</b> desquelles dépendent les valeurs des autres hypothèses.
			<br />
			<br />
			Par exemple, un nombre d&apos;ETP ou d&apos;embauches, un montant sous gestion, un nombre de clients ou de produits...etc.
		</span>
	);
}

/*
function FinFinPartInfoTip () {
	return (
		<span>
			Désigne le <b>montant emprunté lors d&apos;une campagne</b> de financement participatif (aussi appelé crowdfunding ou crowdlending).
		</span>
	);
}
*/

const BPItemInfoTipComponents = {
	InvCorp: InvCorpInfoTip,
	InvIncorp: InvIncorpInfoTip,
	InvFin: InvFinInfoTip,
	CoutsMarch: CoutsMarchInfoTip,
	CoutsMatPrem: CoutsMatPremInfoTip,
	CoutsPerso: CoutsPersoInfoTip,
	CoutsExt: CoutsExtInfoTip,
	CoutsExploit: CoutsExploitInfoTip,
	CoutsTax: CoutsTaxInfoTip,
	CoutsFin: CoutsFinInfoTip,
	CoutsExcep: CoutsExcepInfoTip,
	CoutsDivid: CoutsDividInfoTip,
	CoutsProvExploit: CoutsProvExploitInfoTip,
	RevNego: RevNegoInfoTip,
	RevProd: RevProdInfoTip,
	RevServ: RevServInfoTip,
	RevExploit: RevExploitInfoTip,
	RevFin: RevFinInfoTip,
	RevExcep: RevExcepInfoTip,
	FinKP: FinKPInfoTip,
	FinCCAsso: FinCCAssoInfoTip,
	FinDetCT: FinDetCTInfoTip,
	FinDetLT: FinDetLTInfoTip,
	FinSubvExploit: FinSubvExploitInfoTip,
	FinSubvInv: FinSubvInvInfoTip,
	FinCrImpot: FinCrImpotInfoTip,
	FinExImpot: FinExImpotInfoTip,
	UnitVariable: UnitVariableInfoTip,
};

export function BPItemInfoTip(props) {
	const InfoTipComp = BPItemInfoTipComponents[props.bpItemType];
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "BPItemInfoTip"} className="infoPopover small">
					<Popover.Body>
						<InfoTipComp />
					</Popover.Body>
				</Popover>
			}
		>
			<span className="bpItemInfoTip">
				<i className="bi bi-info-circle-fill" />
			</span>
		</OverlayTrigger>
	);
}

export function AmortizableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={props.placement}
			overlay={
				<Popover id={(props.id || "") + "AmortizableInfoTip"} className="infoPopover large">
					<Popover.Body>
						Amortissement :<br />
						<br />
						<ul>
							<li>
								Pour un <b>investissement</b> l&apos;amortissement d’un investissement est la constatation d&apos;un amoindrissement ou d&apos;une dépréciation de sa valeur.
								<br />
								La durée d&apos;amortissement est égale à la période au bout de laquelle l&apos;élément n&apos;aura plus aucune valeur marchande.
							</li>
							<br />
							<li>
								Pour un <b>emprunt</b>, l’amortissement correspond au <b>profil de remboursement</b>. Un emprunt est dit amortissable lorsque le capital est remboursé
								progressivement sur la durée de l’emprunt.
							</li>
							<br />
							<li>
								Pour une <b>subvention</b>, l&apos;amortissement correspond à l&apos;
								<b>amortissement de l&apos;investissement subventionné</b>. La subvention est alors étalée en résultat chaque année comme un produit, sur la durée de
								l&apos;amortissement saisi.
							</li>
						</ul>
						Remarques :<br />
						<ul>
							<li>
								Le mode d’amortissement retenu par défaut est linéaire. Pour un emprunt, vous pouvez modifier les paramètres d&apos;amortissement ci-dessous via la Saisie avancée.
							</li>
							<li>
								Si le bien a une valeur résiduelle (c&apos;est-à-dire qu’il a encore de la valeur au bout de sa durée d’amortissement), celle-ci peut être modélisée sous forme
								d’une 2ème tranche d’investissement non amortissable.
							</li>
							<li>La plupart des actifs ont un amortissement encadré par la fiscalité. Veillez à respecter cette contrainte.</li>
						</ul>
						<br />
						Exemples :<br />
						<ul>
							<li>Une machine de production d&apos;une durée de vie estimée de 10 ans peut être amortie sur 10 ans.</li>
							<li>Une marque est le plus souvent à considérer comme un élément non amortissable.</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CoutsPersoAmountInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={
				<Popover id={(props.id || "") + "CoutsPersoAmountInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le coût total pour l&apos;entreprise correspond à la somme du salaire brut et des cotisations patronales.
						{props.countryCode === "FRA" && (
							<>
								<br />
								Vous pouvez utiliser le simulateur de l&apos;URSSAF pour l&apos;estimer.
							</>
						)}
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SalaryAmountTypeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "SalaryAmountTypeInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>
							Le <b>salaire net</b> correspond à la rémunération perçue par le salarié avant impôt à la source.
						</p>
						<p>
							Le <b>salaire brut</b> sert d&apos;assiette à l&apos;URSSAF pour calculer le montant des cotisations. Il représente la &quot;part salarié&quot; constituée du salaire
							net et des cotisations sociales versées aux organismes (santé, retraite, chômage, etc.).
						</p>
						<p>
							Le <b>coût total</b> pour l&apos;entreprise correspond à la somme du salaire brut et des cotisations patronales.
						</p>
						<p>
							Ces valeurs sont reliées entre elles par les formules suivantes :
							<br />
							<span className="equation">Salaire net = Salaire brut &times; (1 - taux de cotisations salariales)</span>
							<br />
							<span className="equation">Coût total = Salaire brut &times; (1 + taux de cotisations patronales)</span>
						</p>
						<p>
							Vous pouvez également saisir directement un <b>montant de charges</b> sociales.
						</p>
						<br />
						<i className="bi bi-question-circle marginRight" />
						Ces différentes composantes peuvent être estimées avec le simulateur de l&apos;URSSAF.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SalaryCompanyLegalFormInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "SalaryCompanyLegalFormInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>Liste des régimes de cotisations :</p>
						<table className="marginTop10">
							<tbody>
								<tr>
									<td className="paddingBottom10">SAS</td>
									<td className="paddingBottom10 paddingLeft10">Société par actions simplifiée</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SASU</td>
									<td className="paddingBottom10 paddingLeft10">Société par actions simplifiée unipersonnelle</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SARL</td>
									<td className="paddingBottom10 paddingLeft10">Société à responsabilité limitée</td>
								</tr>
								<tr>
									<td className="paddingBottom10">EURL</td>
									<td className="paddingBottom10 paddingLeft10">Entreprise unipersonnelle à responsabilité limitée, à l&apos;IS</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SELARL</td>
									<td className="paddingBottom10 paddingLeft10">Société d&apos;exercice libéral à responsabilité limitée</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SELARLU</td>
									<td className="paddingBottom10 paddingLeft10">Société d&apos;exercice libéral à responsabilité limitée unipersonnelle, à l&apos;IS</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SELAS</td>
									<td className="paddingBottom10 paddingLeft10">Société d&apos;exercice libéral par actions simplifiée</td>
								</tr>
								<tr>
									<td className="paddingBottom10">SELASU</td>
									<td className="paddingBottom10 paddingLeft10">Société d&apos;exercice libéral par actions simplifiée unipersonnelle, à l&apos;IS</td>
								</tr>
								<tr>
									<td className="paddingBottom10">EI</td>
									<td className="paddingBottom10 paddingLeft10">Entreprise unipersonnelle à l&apos;IS</td>
								</tr>
								<tr>
									<td className="paddingBottom10">Association</td>
									<td className="paddingBottom10 paddingLeft10">Association</td>
								</tr>
								<tr>
									<td className="paddingBottom10">Autre</td>
									<td className="paddingBottom10 paddingLeft10">Toute autre forme - cas général</td>
								</tr>
							</tbody>
						</table>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AverageFinancialDebtsInterestRateInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AverageFinancialDebtsInterestRateInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>Taux moyen calculé à partir des montants de dettes et d&apos;intérêts de l&apos;année de référence.</p>
						<p><b>Ce taux s&apos;applique par défaut au montant résiduel de dette</b>, après déduction des échéanciers que vous aurez saisis.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function RemainingAmountToScheduleInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "RemainingAmountToScheduleInfoTip"} className="infoPopover large">
					<Popover.Body>
						S&apos;il reste un montant à échéancer, celui-ci est maintenu au bilan sur l&apos;horizon de projection.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleInitAmountInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleInitAmountInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>Indiquez le <b>montant restant à échéancer</b> (c&apos;est-à-dire inscrit au bilan) à la date sélectionnée.</p>
						<p>
							Vous pouvez saisir le montant à la dernière clôture annuelle, ou bien à toute autre date antérieure. Par exemple pour un emprunt, vous avez le choix d&apos;indiquer 
							soit le capital restant à rembourser à la dernière clôture annuelle, soit le capital initial à la date d&apos;emprunt.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleAmortizationTypeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleAmortizationTypeInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>
							Si vous choisissez l&apos;un des modes d&apos;amortissement standards, celui-ci sera calculé automatiquement sur la durée définie.
						</p>
						<p>
							<b>Linéaire</b> : le montant est amorti par fractions égales à chaque période (mensuelle ou annuelle) jusqu&apos;à l&apos;échéance.
							<br />
							<b>Constant</b> (emprunts seulement) : l&apos;échéancier de remboursement est calculé de telle sorte qu&apos;un montant fixe, comprenant capital et intérêts, soit remboursé chaque année (si
							amortissement annuel) ou chaque mois (si amortissement mensuel). On parle alors d&apos;annuités ou mensualités constantes.
							<br />
							<b>In fine</b> (emprunts seulement) : le capital emprunté est remboursé en totalité à son échéance.
						</p>
						<p>Sinon, sélectionnez <b>personnalisé</b> pour définir votre propre échéancier.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleInterestRateInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleInterestRateInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Saisissez le <b>taux d&apos;intérêt annuel</b> de l&apos;emprunt.</p>
						<p>Le taux d&apos;intérêt influera sur le montant des charges financières inscrites au compte de résultat.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleAmortizableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleAmortizableInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Indiquez si le montant saisi est amortissable ou non.</p>
						<p>
							S&apos;il est <b>amortissable</b>, vous pouvez définir les paramètres d&apos;amortissement.<br/>
							S&apos;il est <b>non amortissable</b>, le montant indiqué sera maintenu au bilan sur la durée de la prévision.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleAmortizationFrequencyInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleAmortizationFrequencyInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Indiquer la fréquence de remboursement de l&apos;emprunt :<br />
						<br />
						<b>Mensuelle</b> : un montant correspondant à la mensualité est remboursé chaque mois, jusqu&apos;à l&apos;échéance.
						<br />
						<br />
						<b>Trimestrielle</b> : les remboursements ont lieu tous les 3 mois, jusqu&apos;à l&apos;échéance.
						<br />
						<br />
						<b>Annuelle</b> : un montant de capital correspondant à l&apos;annuité est remboursé au terme de chaque année, jusqu&apos;à l&apos;échéance.
						<br />
						<br />A noter : dans tous les cas les intérêts sont calculés (et versés) chaque mois sur le montant du capital restant dû.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CustomScheduleDefermentMonthPeriodInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CustomScheduleDefermentMonthPeriodInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Vous pouvez indiquer ici si l&apos;emprunt fait l&apos;objet d&apos;un différé de remboursement. Dans ce cas, le remboursement du capital emprunté commence après le
						délai spécifié (en mois).
						<br />
						<br />
						Pendant la période de différé, les intérêts sont calculés (et versés) chaque mois sur le montant total du capital.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function UnitVariableReferenceTypeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "UnitVariableReferenceTypeInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>Indiquez de quelle manière le montant de l&apos;hypothèse va être calculé :</p>
						<ul>
							<li>
								<p>
									Par rapport aux <b>quantités</b>
								</p>
								<p>
									Le montant ou le pourcentage saisi est multiplié chaque mois par la quantité en cours de la variable de référence (ou de chaque variable si plusieurs sont
									sélectionnées).
									<br />
									Exemple d&apos;utilisation : un montant de salaire multiplié par le nombre de salariés.
								</p>
							</li>
							<li>
								<p>
									Par rapport à la <b>valeur des augmentations</b>
								</p>
								<p>
									Le montant ou le pourcentage saisi est multiplié chaque mois par le volume d&apos;augmentation de la variable de référence.
									<br />
									Si le niveau de la variable de référence n&apos;augmente pas, alors la contribution de l&apos;hypothèse est nulle.
									<br />
									Exemple d&apos;utilisation : modélisation de revenus ou de coûts <b>variables</b>, par exemple des frais de dossier multipliés par le nombre de nouveaux clients.
								</p>
							</li>
							<li>
								<p>
									Par rapport à <b>chaque augmentation</b>
								</p>
								<p>
									Le montant ou le pourcentage saisi s&apos;applique chaque fois que la variable de référence augmente, quel que soit le volume de l&apos;augmentation.
									<br />
									Si le niveau de la variable de référence n&apos;augmente pas, alors la contribution de l&apos;hypothèse est nulle.
									<br />
									Exemple d&apos;utilisation : modélisation de revenus ou de coûts <b>fixes</b>, par exemple le coût de publication d&apos;une annonce lors d&apos;une vente
									immobilière, dont le montant ne dépend pas du prix de vente.
								</p>
							</li>
							<li>
								<p>
									Par rapport à la <b>valeur des diminutions</b>
								</p>
								<p>
									Le montant ou le pourcentage saisi est multiplié chaque mois par le volume de diminution de la variable de référence.
									<br />
									Si le niveau de la variable de référence ne diminue pas, alors la contribution de l&apos;hypothèse est nulle.
									<br />
									Exemple d&apos;utilisation : modélisation de revenus ou de coûts <b>variables</b> tels que ceux cités ci-dessus.
								</p>
							</li>
							<li>
								<p>
									Par rapport à <b>chaque diminution</b>
								</p>
								<p>
									Le montant ou le pourcentage saisi s&apos;applique chaque fois que la variable de référence diminue, quel que soit le volume de la diminution.
									<br />
									Si le niveau de la variable de référence ne diminue pas, alors la contribution de l&apos;hypothèse est nulle.
									<br />
									Exemple d&apos;utilisation : modélisation de revenus ou de coûts <b>fixes</b> tels que ceux cités ci-dessus.
								</p>
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function InterimAccountsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "InterimAccountsInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>
							Si vous disposez d&apos;une situation comptable plus récente que la dernière clôture annuelle, sélectionnez-la en tant que <i>Situation intermédiaire</i> afin
							d&apos;intégrer le <b>réalisé</b> au prévisionnel.
						</p>
						Ainsi, jusqu&apos;à la date de la situation intermédiaire choisie, les montants prévisionnels sont remplacés par les données comptables. Au-delà, les hypothèses
						prévisionnelles restent actives.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function InterimAccountsDateInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "InterimAccountsDateInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Lorsque vous mettez à jour votre prévisionnel avec une{" "}
						<span className="statusInfo emphasis noWrap marginLeft05">
							<i className="bi bi-input-cursor marginRight" />
							Situation intermédiaire
						</span>
						, les hypothèses sont remplacées par les données comptables sur la période de la situation intermédiaire.
						<br />
						Au-delà de la période comptable utilisée, les hypothèses prévisionnelles restent actives.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function BSTableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "BSTableInfoTip"} className="infoPopover small">
					<Popover.Body>
						<b>Le bilan répertorie le patrimoine de l’entreprise</b>, c’est-à-dire qu’il renseigne à un instant T sur ce que l’entreprise possède et sur ce qu’elle doit.
						<br />
						Il permet également de voir <b>la façon dont l&apos;entreprise finance son activité</b> (fonds propres, dettes financières, etc.) ainsi que sa <b>solvabilité</b>{" "}
						(trésorerie, immobilisations).
						<br />
						<br />
						Ce tableau est une <b>représentation synthétique</b> du bilan de l&apos;entreprise, projeté sur les années suivantes, tenant compte des hypothèses mentionnées dans le
						business plan.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ISTableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "ISTableInfoTip"} className="infoPopover small">
					<Popover.Body>
						Ce tableau est une <b>représentation synthétique</b> du compte de résultat de l&apos;entreprise, projeté sur les années suivantes, tenant compte des hypothèses
						mentionnées dans le business plan.
						<br />
						<br />
						<b>Le compte de résultat reflète l&apos;activité de l&apos;entreprise</b> et permet d&apos;obtenir le résultat de l&apos;exercice (différence entre les produits et les
						charges, avant et après impôts).
						<br />
						On sait alors si l&apos;entreprise enregistre un bénéfice ou un déficit sur la période écoulée.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function BreakEvenChartInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "BreakEvenChartInfoTip"} className="infoPopover small">
					<Popover.Body>
						L&apos;horizon de rentabilité correspond au moment où l&apos;activité d&apos;exploitation commence à dégager des bénéfices, c&apos;est-à-dire lorsque le chiffre
						d&apos;affaires devient durablement supérieur au total des coûts d&apos;exploitation, fixes et variables.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function MarketBenchmarkChartInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "BreakEvenChartInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Les indicateurs et ratios géo-sectoriels sont calculés à partir des bilans des entreprises françaises qui publient leurs comptes publiquement.</p>
						<p>La comparaison se fait sur le secteur d&apos;activité (code NAF) et la zone géographique (département) de l&apos;entreprise.</p>
						<p>
							Dans le cas où l&apos;échantillon de comparaison est trop faible (moins de 30 entreprises), l&apos;algorithme élargit en premier lieu la zone géographique à la région
							ou au territoire national le cas échéant, puis en second lieu le secteur d&apos;activité.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

/*
export function MetricsTableInfoTip(props) {	
	return (
		<OverlayTrigger trigger="click" rootClose placement="right" overlay={(
				<Popover id={(props.id || "") + "MetricsTableInfoTip"} className="infoPopover large"><Popover.Body>
					Ce tableau présente les indicateurs régulièrement suivis par les investisseurs dans le cas d’un prêt, mais aussi par les dirigeants et les actionnaires qui pilotent l’activité.<br/>
					<br/>
					En cliquant sur l&apos;info-bulle de chaque indicateur vous trouverez une aide plus détaillée sur leur signification et la manière de les interpréter.<br/>
					<br/>
					<ul>
						<li><b>Trésorerie</b> : Montant du solde de trésorerie</li>
						<li><b>BFR</b> : Montant du besoin en fonds de roulement</li>
						<li><b>EBE</b> : Montant de l&apos;excédent brut d&apos;exploitation</li>
						<li><b>EBITDA</b> : Montant de l&apos;EBITDA (Earnings Before Interest, Taxes, Depreciation and Amortization)</li>
						<li><b>CAF</b> : Montant de la Capacité d&apos;Autofinancement</li>
						<li><b>Dettes nettes &lt; 1 an</b> : Montant des dettes financières remboursables dans l&apos;année, net des disponibilités</li>
						<li><b>Couverture du BFR</b> : Ratio du montant du fonds de roulement rapporté au montant du besoin en fonds de roulement (ratio exprimé en unité et non en pourcentage)</li>
						<li><b>Dettes financières nettes &divide; CAF</b> : Ratio du montant des dettes financières nettes des disponibilités, rapporté à la capacité d&apos;autofinancement (ratio exprimé en unité et non en pourcentage)</li>
						<li><b>Marge opérationnelle</b> : Ratio du montant du résultat d&apos;exploitation rapporté au montant du chiffre d&apos;affaires (ratio exprimé en pourcentage)</li>
						<li><b>Marge nette</b> : Ratio du montant du résultat net rapporté au montant du chiffre d&apos;affaires (ratio exprimé en pourcentage)</li>
						<li><b>Rentabilité des FP</b> : Ratio du montant du résultat net rapporté au montant des fonds propres (ratio exprimé en pourcentage)</li>
						<li><b>Ratio d&apos;endettement</b> : Ratio du montant des dettes financières nettes des disponibilités, rapporté au montant des fonds propres (ratio exprimé en unité et non en pourcentage)</li>
						<li><b>Liquidité générale</b> : Ratio du montant des actifs à court terme (stocks + créances + disponibilités) rapporté au montant des dettes à court terme (dettes financières à moins d&apos;un an + dettes d&apos;exploitation) (ratio exprimé en unité et non en pourcentage)</li>
						<li><b>Couverture des dettes</b> : Ratio du montant des actifs pouvant être cédés (immobilisations nettes + stocks) rapporté au montant des dettes financières nettes (ratio exprimé en unité et non en pourcentage)</li>
						<li><b>Délai de paiement clients</b> : Délai moyen de paiement par les clients, égal au ratio du montant des créances clients rapportées au montant du chiffre d&apos;affaires (TTC), multiplié par 365 (exprimé en jours)</li>
						<li><b>Délai de paiement fourn.</b> : Délai moyen de paiement des fournisseurs, égal au ratio du montant des dettes fournisseurs rapportées au montant des approvisionnements (achats de marchandises, matières premières et charges externes) (TTC), multiplié par 365 (exprimé en jours)</li>
					</ul>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

export function OpsMetricsTableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "OpsMetricsTableInfoTip"} className="infoPopover small">
					<Popover.Body>
						Ce tableau présente les indicateurs utiles au pilotage opérationnel de l&apos;activité et de son cycle d&apos;exploitation.
						<br />
						<br />
						En cliquant sur l&apos;info-bulle de chaque indicateur vous trouverez une aide plus détaillée sur leur signification et la manière de les interpréter.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FinMetricsTableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FinMetricsTableInfoTip"} className="infoPopover small">
					<Popover.Body>
						Ce tableau présente les indicateurs régulièrement suivis par les banquiers ou investisseurs dans le cas d’un financement, mais aussi par les dirigeants et les
						actionnaires qui pilotent l’activité.
						<br />
						<br />
						En cliquant sur l&apos;info-bulle de chaque indicateur vous trouverez une aide plus détaillée sur leur signification et la manière de les interpréter.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function StructMetricsTableInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "StructMetricsTableInfoTip"} className="infoPopover small">
					<Popover.Body>
						Ce tableau présente les ratios de structure d&apos;activité, utiles pour suivre l&apos;évolution dans le temps de plusieurs postes clés du bilan et du compte de
						résultat, ou pour comparer sa situation par rapport aux autres entreprises du même secteur (rendez-vous pour cela dans le module Comparaison aux pairs).
						<br />
						<br />
						En cliquant sur l&apos;info-bulle de chaque indicateur vous trouverez une aide plus détaillée sur leur signification et la manière de les interpréter.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DebtCashInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DebtCashInfoTip"} className="infoPopover small">
					<Popover.Body>
						Représentation de l&apos;évolution du montant total des dettes financières (court et long terme), comparé au solde de trésorerie disponible.
						<br />
						<br />
						Lorsque le montant de trésorerie disponible est supérieur à celui de l&apos;endettement, cela traduit une capacité à rembourser la dette financière en totalité.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

function TresoInfoTip() {
	return (
		<span>
			La trésorerie représente <b>la somme des fonds disponibles sans délai</b>. On parle aussi de liquidités disponibles en caisse ou en banque. Elle inclut les placements à très
			court terme, cessibles immédiatement.
		</span>
	);
}

function BFREInfoTip() {
	return (
		<span>
			Le niveau du besoin en fonds de roulement d&apos;exploitation (BFRE) est un indicateur d&apos;
			<b>autonomie financière</b> de l&apos;entreprise à court-terme.
			<br />
			Le BFRE désigne le montant des capitaux nécessaires à l&apos;exploitation de l&apos;entreprise, soit au{" "}
			<b>décalage entre les encaissements des ventes aux clients et le paiement des achats aux fournisseurs</b>, et à la <b>gestion des stocks</b>.
			<br />
			<br />
			Note : le BFRE peut être :<br />
			<ul>
				<li>
					nul ou <b>négatif</b> lorsque l&apos;exploitation génère des flux de trésorerie positifs, par exemple si les fournisseurs sont réglés après le paiement par les clients,
				</li>
				<li>
					<b>positif</b> si l&apos;activité de l&apos;entreprise a besoin d&apos;être financée, par son fonds de roulement et/ou des dettes financières à court-terme.
				</li>
			</ul>
			Formule:
			<br />
			BFR = Stocks + Créances clients - Dettes fournisseurs
		</span>
	);
}

function BFRInfoTip() {
	return (
		<span>
			Le niveau du besoin en fonds de roulement (BFR) est un indicateur d&apos;
			<b>autonomie financière</b> de l&apos;entreprise à court-terme.
			<br />
			Le BFR désigne le montant des capitaux nécessaires au fonctionnement de l&apos;entreprise, tenant compte de son exploitation et des créances ou dettes qu&apos;elle peut avoir
			vis à vis d&apos;autres tiers comme l&apos;Etat.
			<br />
			Le BFR est plus large que la notion de BFR d&apos;exploitation (BFRE) qui est elle strictement limitée aux opérations de ventes et d&apos;achats.
			<br />
			<br />
			Note : le BFR peut être :<br />
			<ul>
				<li>
					nul ou <b>négatif</b> lorsque l&apos;activité de l&apos;entreprise génère des flux de trésorerie positifs, par exemple si les fournisseurs sont réglés après le paiement
					par les clients,
				</li>
				<li>
					<b>positif</b> si l&apos;activité de l&apos;entreprise a besoin d&apos;être financée, par son fonds de roulement et/ou des dettes financières à court-terme.
				</li>
			</ul>
			Formule:
			<br />
			BFR = Stocks + Créances clients + Autres créances - Dettes fournisseurs - Dettes fiscales et sociales
		</span>
	);
}

export function BFRNormInfoTip() {
	return (
		<span>
			Le niveau du besoin en fonds de roulement (BFR) est un indicateur d&apos;
			<b>autonomie financière</b> de l&apos;entreprise à court-terme.
			<br />
			Le BFR désigne le montant des capitaux nécessaires au fonctionnement de l&apos;activité de l&apos;entreprise, soit au{" "}
			<b>décalage entre les encaissements des ventes aux clients et le paiement des achats aux fournisseurs</b>
			.<br />
			<br />
			Note : le BFR peut être :<br />
			<ul>
				<li>
					nul ou <b>négatif</b> lorsque l&apos;activité de l&apos;entreprise génère des flux de trésorerie positifs, par exemple si les fournisseurs sont réglés après le paiement
					par les clients,
				</li>
				<li>
					<b>positif</b> si l&apos;activité de l&apos;entreprise a besoin d&apos;être financée, par son fonds de roulement et/ou des dettes financières à court-terme.
				</li>
			</ul>
			<br />
			Note : ici le BFR est exprimé <b>en jours de chiffre d&apos;affaires</b> (aussi appelé BFR normatif), afin de pouvoir être comparé à celui des autres entreprises de
			l&apos;échantillon, indépendamment de la taille de l&apos;entreprise.
			<br />
			Le nombre de jours calculé correspond à la durée pendant laquelle l&apos;entreprise doit financer son cycle d&apos;exploitation.
			<br />
			<br />
			Formule:
			<br />
			BFR en jours de CA ={" "}
			<div className="equation">
				<div className="fraction">
					<div>
						Stocks
						<br />+ Créances clients
						<br />- Dettes fournisseurs
						<br />- Dettes fiscales et sociales
					</div>
					<div>Chiffre d&apos;affaires annuel</div>
				</div>
			</div>{" "}
			&times; 365
		</span>
	);
}

function RexInfoTip() {
	return (
		<span>
			Le Résultat d&apos;Exploitation (ou REX) mesure le montant des bénéfices dégagés par le cycle d&apos;exploitation courante de l&apos;entreprise, avant paiement des intérêts
			financiers, et de l’impôt sur les bénéfices.
			<br />
			Le REX est un indicateur de <b>performance opérationnelle</b> de l&apos;entreprise. Il permet de mesurer le <b>niveau de rentabilité du processus d’exploitation</b>.<br />
			<br />
			Un <b>REX positif</b> signifie que l’exploitation d’une entreprise est rentable. Toutefois, cela ne signifie pas nécessairement qu’elle est bénéficiaire globalement puisque
			l&apos;EBE ne prend pas en compte la politique d’investissement et de financement.
			<br />
			Un <b>REX négatif</b> signifie que l’entreprise n’est pas profitable au niveau opérationnel et donne donc un mauvais signal d&apos;investissement.
			<br />
			<br />
			Formule:
			<br />
			REX = produits d&apos;exploitation - charges d&apos;exploitation,
			<br />
			où :<br />
			<br />
			<u>Produits d&apos;exploitation</u> = chiffre d’affaires net + production stockée ou immobilisée + subventions d&apos;exploitation + reprises sur amortissements et provisions
			+ autres produits d&apos;exploitation
			<br />
			<br />
			<u>Charges d&apos;exploitation</u> = achats et variation de stock de marchandises + achat et variation de stock de matières premières + charges de personnel + charges
			externes + impôts et taxes + dotations aux amortissements et provisions + autres charges d&apos;exploitation
		</span>
	);
}

function EBEInfoTip() {
	return (
		<span>
			L’EBE (Excédent Brut d&apos;Exploitation) mesure le montant des bénéfices dégagés par le cycle d&apos;exploitation de l&apos;entreprise, avant paiement des intérêts
			financiers, des dotations aux amortissements et aux provisions, et de l’impôt sur les bénéfices.
			<br />
			L’EBE est un indicateur de <b>performance opérationnelle</b> de l&apos;entreprise. Il permet de mesurer le <b>niveau de rentabilité du processus d’exploitation</b>.<br />
			<br />
			Un <b>EBE positif</b> signifie que l’exploitation d’une entreprise est rentable. Toutefois, cela ne signifie pas nécessairement qu’elle est bénéficiaire globalement puisque
			l&apos;EBE ne prend pas en compte la politique d’investissement, de financement et l’impact des amortissements.
			<br />
			Un <b>EBE négatif</b> signifie que l’entreprise n’est pas profitable au niveau opérationnel et donne donc un mauvais signal d&apos;investissement.
			<br />
			<br />
			Formule:
			<br />
			EBE = chiffre d’affaires net + production stockée ou immobilisée + subventions d&apos;exploitation - achats et variation de stock de marchandises - achat et variation de
			stock de matières premières - charges de personnel - charges externes - impôts et taxes
		</span>
	);
}

export function MargeEBEInfoTip() {
	return (
		<span>
			L’EBE (Excédent Brut d&apos;Exploitation) mesure le montant des bénéfices dégagés par le cycle d&apos;exploitation de l&apos;entreprise, avant paiement des intérêts
			financiers, des dotations aux amortissements et aux provisions, et de l’impôt sur les bénéfices.
			<br />
			L’EBE est un indicateur de <b>performance opérationnelle</b> de l&apos;entreprise. Il permet de mesurer le <b>niveau de rentabilité du processus d’exploitation</b>.<br />
			<br />
			Un <b>EBE positif</b> signifie que l’exploitation d’une entreprise est rentable. Toutefois, cela ne signifie pas nécessairement qu’elle est bénéficiaire globalement puisque
			l&apos;EBE ne prend pas en compte la politique d’investissement, de financement et l’impact des amortissements.
			<br />
			Un <b>EBE négatif</b> signifie que l’entreprise n’est pas profitable au niveau opérationnel et donne donc un mauvais signal d&apos;investissement.
			<br />
			<br />
			Note : ici l&apos;EBE est exprimé sous la forme d&apos;une marge d&apos;EBE en % par rapport au chiffre d&apos;affaires (CA) afin de pouvoir être comparé à celui des autres
			entreprises de l&apos;échantillon, indépendamment de la taille de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Marge d&apos;EBE ={" "}
			<div className="equation">
				<div className="fraction">
					<div>
						chiffre d’affaires net
						<br />+ production stockée ou immobilisée
						<br />- achats et variation de stock de marchandises
						<br />- achat et variation de stock de matières premières
						<br />- charges de personnel
						<br />- charges externes
						<br />+ subventions d&apos;exploitation
						<br />- impôts et taxes
					</div>
					<div>Chiffre d’affaires net</div>
				</div>
			</div>
		</span>
	);
}

function EbitdaInfoTip() {
	return (
		<span>
			L’EBITDA (Earnings Before Interest, Taxes, Depreciation and Amortization) est un indicateur international de <b>performance du cycle d&apos;exploitation</b> dont la
			définition est très proche de celle de l&apos;EBE (Excédent Brut d&apos;Exploitation) français.
			<br />
			<br />
			Un <b>EBITDA positif</b> signifie que l’activité d’une entreprise est rentable. Toutefois, cela ne signifie pas nécessairement qu’elle est bénéficiaire globalement puisque
			l’EBITDA ne prend pas en compte la politique d’investissement, de financement et l’impact des amortissements.
			<br />
			Un <b>EBITDA négatif</b> signifie que l’entreprise n’est pas profitable au niveau opérationnel et donne donc un mauvais signal d&apos;investissement.
			<br />
			<br />
			Formule:
			<br />
			EBITDA = chiffre d’affaires net + production stockée ou immobilisée - achats et variation de stock de marchandises - achat et variation de stock de matières premières -
			charges de personnel - charges externes + subventions d&apos;exploitation - impôts et taxes - dotations nettes aux provisions - participation des salariés
		</span>
	);
}

export function MargeEbitdaInfoTip() {
	return (
		<span>
			L’EBITDA (Earnings Before Interest, Taxes, Depreciation and Amortization) est un indicateur international de <b>performance du cycle d&apos;exploitation</b> dont la
			définition est très proche de celle de l&apos;EBE (Excédent Brut d&apos;Exploitation) français.
			<br />
			<br />
			Un <b>EBITDA positif</b> signifie que l’activité d’une entreprise est rentable. Toutefois, cela ne signifie pas nécessairement qu’elle est bénéficiaire globalement puisque
			l’EBITDA ne prend pas en compte la politique d’investissement, de financement et l’impact des amortissements.
			<br />
			Un <b>EBITDA négatif</b> signifie que l’entreprise n’est pas profitable au niveau opérationnel et donne donc un mauvais signal d&apos;investissement.
			<br />
			<br />
			Note : ici l’EBITDA est exprimé sous la forme d&apos;une marge d’EBITDA en % par rapport au chiffre d&apos;affaires (CA) afin de pouvoir être comparé à celui des autres
			entreprises de l&apos;échantillon, indépendamment de la taille de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Marge d&apos;EBITDA ={" "}
			<div className="equation">
				<div className="fraction">
					<div>
						chiffre d’affaires net
						<br />+ production stockée ou immobilisée
						<br />- achats et variation de stock de marchandises
						<br />- achat et variation de stock de matières premières
						<br />- charges de personnel
						<br />- charges externes
						<br />+ subventions d&apos;exploitation
						<br />- impôts et taxes
						<br />- dotations nettes aux provisions
						<br />- participation des salariés
					</div>
					<div>Chiffre d’affaires net</div>
				</div>
			</div>
		</span>
	);
}

function CafInfoTip() {
	return (
		<span>
			La capacité d&apos;autofinancement (CAF) est le surplus de trésorerie encaissable dégagé durant l&apos;exercice.
			<br />
			Elle permet notamment de financer de nouveaux <b>investissements</b>, de rembourser les <b>emprunts</b> bancaires, et verser des <b>dividendes</b>.
			<br />
			La capacité d&apos;emprunt bancaire de l&apos;entreprise est souvent évaluée au regard de la CAF dégagée : en général, la capacité d&apos;emprunt ne peut excéder 4 à 5 fois
			la CAF.
			<br />
			<br />
			Son évolution dans le temps doit être attentivement suivie en tant qu&apos;<b>indicateur des perspectives</b> d&apos;avenir de l&apos;entreprise, de son{" "}
			<b>indépendance financière</b> (ou de sa dépendance), de sa <b>résistance</b> aux aléas conjoncturels.
			<br />
			<br />
			Note :
			<ul>
				<li>
					Plus la <b>CAF est élevée</b>, plus les opportunités de croissance sont importantes.
				</li>
				<li>
					La CAF peut se trouver <b>négative</b> ponctuellement, toutefois une CAF durablement négative traduit une situation critique pour l&apos;entreprise.
				</li>
			</ul>
			Formule:
			<br />
			La CAF peut se calculer de 2 manières :<br />
			<ul>
				<li>
					A partir du résultat net :<br />
					CAF = Résultat net - produits comptables non encaissables (reprises d&apos;amortissements et provisions, plus-values de cessions d&apos;actifs, quote-part des subventions
					d’investissement affectée au résultat) + charges comptables non encaissables (dotations aux amortissements et provisions)
				</li>
				<li>
					A partir de l&apos;excédent brut d&apos;exploitation :<br />
					CAF = EBE + autres produits encaissables (autres produits d&apos;exploitation, produits financiers et exceptionnels) - autres charges encaissables (autres charges
					d&apos;exploitation, charges financières et exceptionnelles, impôt sur les sociétés, participation des salariés)
				</li>
			</ul>
		</span>
	);
}

export function CafNormInfoTip() {
	return (
		<span>
			La capacité d&apos;autofinancement (CAF) est le surplus de trésorerie encaissable dégagé durant l&apos;exercice.
			<br />
			Elle permet notamment de financer de nouveaux <b>investissements</b>, de rembourser les <b>emprunts</b> bancaires, et verser des <b>dividendes</b>.
			<br />
			La capacité d&apos;emprunt bancaire de l&apos;entreprise est souvent évaluée au regard de la CAF dégagée : en général, la capacité d&apos;emprunt ne peut excéder 4 à 5 fois
			la CAF.
			<br />
			<br />
			Son évolution dans le temps doit être attentivement suivie en tant qu&apos;<b>indicateur des perspectives</b> d&apos;avenir de l&apos;entreprise, de son{" "}
			<b>indépendance financière</b> (ou de sa dépendance), de sa <b>résistance</b> aux aléas conjoncturels.
			<br />
			<br />
			Note :
			<ul>
				<li>
					Plus la <b>CAF est élevée</b>, plus les opportunités de croissance sont importantes.
				</li>
				<li>
					La CAF peut se trouver <b>négative</b> ponctuellement, toutefois une CAF durablement négative traduit une situation critique pour l&apos;entreprise.
				</li>
			</ul>
			<br />
			Note : ici la CAF est exprimée en % par rapport au chiffre d&apos;affaires (CA) afin de pouvoir être comparée à celle des autres entreprises de l&apos;échantillon,
			indépendamment de la taille de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			La CAF peut se calculer de 2 manières :<br />
			<ul>
				<li>
					A partir du résultat net :<br />
					CAF = Résultat net - produits comptables non encaissables (reprises d&apos;amortissements et provisions, plus-values de cessions d&apos;actifs, quote-part des subventions
					d’investissement affectée au résultat) + charges comptables non encaissables (dotations aux amortissements et provisions)
				</li>
				<li>
					A partir de l&apos;excédent brut d&apos;exploitation :<br />
					CAF = EBE + autres produits encaissables (autres produits d&apos;exploitation, produits financiers et exceptionnels) - autres charges encaissables (autres charges
					d&apos;exploitation, charges financières et exceptionnelles, impôt sur les sociétés, participation des salariés)
				</li>
			</ul>
		</span>
	);
}

function DetNetCTInfoTip() {
	return (
		<span>
			Montant des dettes financières remboursables dans l&apos;année, net des disponibilités.
			<br />
			L&apos;indicateur représente le solde créditeur ou débiteur de l&apos;entreprise vis-à-vis de son ou ses établissements bancaires.
			<br />
			<br />
			Note : S&apos;il est :
			<ul>
				<li>
					<b>positif</b>, les emprunts à court terme de l&apos;entreprise (lignes de crédit, découverts, etc.) sont supérieurs à ses disponibilités (liquidités et placements à
					court terme).
				</li>
				<li>
					<b>négatif</b>, l&apos;entreprise dispose d&apos;un excédent de disponibilités par rapport à ses emprunts à court terme.
				</li>
			</ul>
			Formule :<br />
			Dettes nettes &lt; 1 an = Dettes financières à moins d&apos;1 an (remboursables dans l&apos;année) - trésorerie
		</span>
	);
}

export function CouvBFRInfoTip() {
	return (
		<span>
			Le ratio de couverture du BFR est un indicateur d&apos;
			<b>autonomie financière</b> à court-terme. Il désigne la capacité de l&apos;entreprise à couvrir son besoin en fonds de roulement par des capitaux stables.
			<br />
			<br />
			Note : le ratio peut être :
			<ul>
				<li>Supérieur à 1 si le fonds de roulement (FR) couvre en totalité le BFR</li>
				<li>Compris entre 0 et 1 si le fonds de roulement (FR) ne couvre que partiellement le BFR</li>
				<li>Inférieur à 0 si le BFR est négatif (auquel cas l&apos;activité de l&apos;entreprise génère des flux de trésorerie positifs)</li>
			</ul>
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Fonds de roulement (FR)</div>
					<div>Besoin en fonds de roulement (BFR)</div>
				</div>
			</div>{" "}
			={" "}
			<div className="equation">
				<div className="fraction">
					<div>capitaux propres + dettes financières à moyen-long terme</div>
					<div>stocks + créances clients - dettes fournisseurs - dettes fiscales et sociales</div>
				</div>
			</div>
		</span>
	);
}

export function DetCafInfoTip() {
	return (
		<span>
			Il s&apos;agit d&apos;un indicateur de <b>capacité de remboursement</b> de la dette. Il exprime le nombre d&apos;années nécessaire à l&apos;entreprise pour rembourser ses
			emprunts au moyen de sa capacité d’autofinancement.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des dettes financières nettes</div>
					<div>Montant de la CAF</div>
				</div>
			</div>{" "}
			, exprimé en années
		</span>
	);
}

export function ValAjoutInfoTip() {
	return (
		<span>
			La valeur ajoutée est une mesure de la <b>richesse</b> créée par l&apos;entreprise. Elle est égale à la différence entre le prix de vente des biens et services qu&apos;elle
			produit, et la somme des coûts directs intervenus dans la production de ces biens et services (comme par exemple des achats de matières premières).
			<br />
			La valeur ajoutée représente le supplément de valeur apporté à un produit par l&apos;entreprise grâce à son processus de transformation / production, ou encore le supplément
			de valeur que les clients sont prêts à payer pour acheter le produit transformé, par rapport à la matière première non transformée.
			<br />
			<br />
			Formule:
			<br />
			Valeur ajoutée = chiffre d’affaires net + production stockée ou immobilisée - achats et variation de stock de marchandises - achat et variation de stock de matières premières
			- charges externes
		</span>
	);
}

export function MargOpInfoTip() {
	return (
		<span>
			La marge opérationnelle, ou marge d&apos;exploitation, représente la capacité de l’entreprise à <b>générer des profits seulement à partir de son activité</b>, sans tenir
			compte de son résultat financier et de son résultat exceptionnel.
			<br />
			La marge opérationnelle permet d’étudier la <b>pérennité</b> de l’entreprise.
			<br />
			Si ce taux augmente, alors l’entreprise augmente sa rentabilité.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant du résultat d&apos;exploitation</div>
					<div>Montant du chiffre d&apos;affaires</div>
				</div>
			</div>{" "}
			, exprimé en %
		</span>
	);
}

export function MargNetInfoTip() {
	return (
		<span>
			Elle offre une vision de la <b>capacité globale de l’entreprise à générer des profits</b> à partir de son activité d&apos;exploitation ainsi que de ses résultats financier et
			exceptionnel.
			<br />
			La marge nette détermine le niveau de <b>rentabilité</b> de l&apos;entreprise une fois qu&apos;elle a payé toutes ses charges.
			<br />
			Si ce taux augmente, alors l’entreprise augmente sa rentabilité.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant du résultat net</div>
					<div>Montant du chiffre d&apos;affaires</div>
				</div>
			</div>{" "}
			, exprimé en %
		</span>
	);
}

export function RentaFPInfoTip() {
	return (
		<span>
			La rentabilité des fonds propres est un ratio <b>destiné aux actionnaires</b>.<br />
			Il permet de mesurer la <b>capacité des capitaux investis</b> par les actionnaires et associés (capitaux propres) <b>à dégager du profit</b>.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant du résultat net</div>
					<div>Montant des fonds propres</div>
				</div>
			</div>{" "}
			, exprimé en %
		</span>
	);
}

export function GearingInfoTip() {
	return (
		<span>
			Il s&apos;agit du <b>taux d’endettement moyen</b> de l&apos;entreprise, aussi connu sous le nom de <b>Gearing</b>.
			<br />
			Il indique la proportion dans laquelle une entreprise se finance : <b>sources externes</b> (emprunts et dettes financières) vs. <b>sources internes</b> (associés et
			actionnaires).
			<br />
			<br />
			Il est exprimé en unité et non en pourcentage.
			<br />
			<br />
			S&apos;il est :
			<ul>
				<li>
					<b>supérieur à 1</b> (100%), il indique que l&apos;entreprise se finance en majorité avec des sources externes
				</li>
				<li>
					<b>inférieur à 1</b>, il indique que l&apos;entreprise se finance en majorité avec des sources internes
				</li>
				<li>
					<b>trop élevé</b> (en général un niveau supérieur à 2 est considéré comme élevé), l&apos;entreprise dépend fortement des prêteurs, et les charges financières associées
					risquent d&apos;avoir un impact négatif sur le résultat net de l&apos;entreprise et ses conditions d&apos;emprunt
				</li>
				<li>
					<b>faible</b> (proche de zéro), on pourrait en conclure que la société dépend beaucoup des investisseurs et se montre très voire trop prudente dans sa gestion
				</li>
			</ul>
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des dettes financières nettes des disponibilités</div>
					<div>Montant des fonds propres</div>
				</div>
			</div>{" "}
			, exprimé en unité
		</span>
	);
}

export function LiqGenInfoTip() {
	return (
		<span>
			Le ratio de liquidité générale mesure la <b>capacité à régler ses dettes à court terme</b>. Il s’agit principalement des dettes fournisseurs, dettes fiscales et dettes
			sociales. Il est exprimé en unité.
			<br />
			<br />
			<b>Les valeurs de ratio acceptables dépendent du secteur</b>. Un ratio d&apos;environ 2 est considéré comme raisonnable.
			<br />
			<br />
			Si le ratio est :<br />
			<ul>
				<li>
					<b>inférieur à 1</b>, les dettes à court terme dépassent les actifs à court terme, et l&apos;entreprise peut ne pas être en mesure de tenir ses engagements.
				</li>
				<li>
					<b>trop élevé</b>, il est possible que l&apos;entreprise n&apos;utilise pas efficacement ses actifs.
				</li>
			</ul>
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des actifs à court terme (stocks + créances + disponibilités)</div>
					<div>Montant des dettes à court terme (dettes financières à moins d&apos;un an + dettes d&apos;exploitation)</div>
				</div>
			</div>{" "}
			, exprimé en unité et non en pourcentage
		</span>
	);
}

export function CouvDetInfoTip() {
	return (
		<span>
			Le ratio de couverture des dettes est un ratio de <b>solvabilité</b> dans un scénario de liquidation de l&apos;entreprise. Il exprime la{" "}
			<b>capacité à rembourser les dettes financières</b> par liquidation des actifs cessibles (en faisant l&apos;hypothèse que les actifs puissent être cédés à leur valeur
			d&apos;enregistrement au bilan). Il est exprimé en unité.
			<br />
			<br />
			S&apos;il est :<br />
			<ul>
				<li>
					<b>supérieur à 1</b> (100%), l&apos;entreprise a théoriquement la capacité à rembourser la totalité de ses dettes financières en cédant ses actifs.
				</li>
				<li>
					<b>compris entre 0 et 1</b>, dans un scénario de liquidation l&apos;entreprise ne serait pas en mesure de rembourser la totalité de ses dettes en cédant ses actifs.
				</li>
				<li>
					<b>négatif</b>, l’entreprise a la capacité à rembourser la totalité de ses dettes financières grâce à sa trésorerie.
				</li>
			</ul>
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des actifs pouvant être cédés (immobilisations nettes + stocks)</div>
					<div>Montant des dettes financières nettes (dettes moyen-long terme + dettes court terme - solde de trésorerie)</div>
				</div>
			</div>{" "}
			, exprimé en unité et non en pourcentage
		</span>
	);
}

export function DelaiCliInfoTip() {
	return (
		<span>
			Il correspond au <b>délai de paiement moyen</b> que vous accordez à vos clients. Il est exprimé en nombre de jours.
			<br />
			<br />
			Ce délai de règlement <b>influe sur la trésorerie</b> de l&apos;entreprise. Plus le délai est court, plus les sommes d&apos;argent arrivent rapidement sur le compte,
			améliorant ainsi la trésorerie.
			<br />
			En France, ce délai est généralement compris entre 0 et 90 jours. Ce délai est à négocier individuellement avec chaque client.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des créances clients</div>
					<div>Montant du chiffre d&apos;affaires TTC</div>
				</div>
			</div>{" "}
			&times; 365, exprimé en jours
		</span>
	);
}

export function DelaiFourInfoTip() {
	return (
		<span>
			Il correspond au <b>délai de paiement moyen</b> que les fournisseurs accordent à l&apos;entreprise. Il est exprimé en nombre de jours.
			<br />
			<br />
			Ce délai de règlement <b>influe sur la trésorerie</b> de l&apos;entreprise. Plus le délai est long, plus les sommes d&apos;argent restent longtemps sur le compte, améliorant
			ainsi la trésorerie.
			<br />
			En France, ce délai est généralement compris entre 30 et 90 jours. Ce délai est à négocier individuellement avec chaque fournisseur.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant des dettes fournisseurs</div>
					<div>Montant des approvisionnements TTC (achats de marchandises, matières premières et charges externes)</div>
				</div>
			</div>{" "}
			&times; 365, exprimé en jours
		</span>
	);
}

export function DelaiStockMarchInfoTip() {
	return (
		<span>
			Il correspond au <b>délai de stockage moyen</b> des marchandises achetées, encore appelé <b>délai d&apos;écoulement des stocks</b> de marchandises. Il est exprimé en nombre
			de jours.
			<br />
			<br />
			Ce délai de stockage <b>influe sur votre trésorerie</b>. Plus le délai est court, plus rapidement les marchandises achetées sont revendues. Votre BFR diminue, améliorant
			ainsi votre trésorerie.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant moyen des stocks de marchandises</div>
					<div>Montant des achats de marchandises HT - variation des stocks de marchandises</div>
				</div>
			</div>{" "}
			&times; 365, exprimé en jours
		</span>
	);
}

export function DelaiStockMatPremInfoTip() {
	return (
		<span>
			Il correspond au <b>délai de stockage moyen</b> des matières premières achetées, encore appelé <b>délai d&apos;écoulement des stocks</b> de matières premières. Il est exprimé
			en nombre de jours.
			<br />
			<br />
			Ce délai de stockage <b>influe sur votre trésorerie</b>. Plus le délai est court, plus rapidement les matières premières achetées sont utilisées pour la production, réduisant
			ainsi le cycle de production et le besoin de trésorerie nécessaire pour le financer.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant moyen des stocks de matières premières</div>
					<div>Montant des achats de matières premières HT - variation des stocks de matières premières</div>
				</div>
			</div>{" "}
			&times; 365, exprimé en jours
		</span>
	);
}

export function DelaiStockProdInfoTip() {
	return (
		<span>
			Il correspond au <b>délai de stockage moyen</b> des produits fabriqués, encore appelé <b>délai d&apos;écoulement des stocks</b> de produits. Il est exprimé en nombre de
			jours.
			<br />
			<br />
			Ce délai de stockage <b>influe sur votre trésorerie</b>. Plus le délai est court, plus rapidement les produits fabriqués sont vendus, améliorant ainsi votre trésorerie.
			<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Montant moyen des stocks de produits</div>
					<div>Montant des ventes de produits HT + variation des stocks de produits</div>
				</div>
			</div>{" "}
			&times; 365, exprimé en jours
		</span>
	);
}

export function TauxMargeCommMarchInfoTip() {
	return (
		<span>
			La marge commerciale est un indicateur utile dans le cas des <b>activités de négoce</b>. Elle correspond à la différence entre le montant des ventes de marchandises et leur
			coût d&apos;achat.
			<br />
			<br />
			Le <b>taux de marge commerciale</b> est défini comme la marge commerciale rapportée au coût d&apos;achat des marchandises consommées.
			<br />
			<br />
			Il s&apos;agit donc ici d&apos;un <b>taux de marge sur coût</b>.<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Chiffre d&apos;affaire de négoce - Consommation de marchandises</div>
					<div>Consommation de marchandises</div>
				</div>
			</div>{" "}
			, exprimé en %<br />
			où :<br />
			Consommation de marchandises = Achats de marchandises - Variation de stocks de marchandises
		</span>
	);
}

export function TauxMargeBruteProdInfoTip() {
	return (
		<span>
			La marge brute de production, aussi appelée <b>marge sur consommations de matières premières</b> est un indicateur utile dans le cas des <b>activités industrielles</b>. Elle
			correspond à la différence entre le montant de la production et celui de la consommation de matières premières.
			<br />
			<br />
			Le <b>taux de marge brute</b> est défini comme la marge brute rapportée à la consommation de matières premières et vous informe sur le niveau de la{" "}
			<b>rentabilité de votre cycle de production</b>.<br />
			<br />
			Il s&apos;agit donc ici d&apos;un <b>taux de marge sur coût</b>.<br />
			<br />
			Formule:
			<br />
			<div className="equation">
				<div className="fraction">
					<div>Production - Consommation de matières premières</div>
					<div>Consommation de matières premières</div>
				</div>
			</div>{" "}
			, exprimé en %<br />
			où :<br />
			Production = Chiffre d&apos;affaires de production + Production stockée + Production immobilisée
			<br />
			Consommation de matières premières = Achats de matières premières - Variation de stocks de matières premières
		</span>
	);
}

export function ValAjoutNormInfoTip() {
	return (
		<span>
			La valeur ajoutée est une mesure de la <b>richesse</b> créée par l&apos;entreprise. Elle est égale à la différence entre le prix de vente des biens et services qu&apos;elle
			produit, et la somme des coûts directs intervenus dans la production de ces biens et services (comme par exemple des achats de matières premières).
			<br />
			La valeur ajoutée représente le supplément de valeur apporté à un produit par l&apos;entreprise grâce à son processus de transformation / production, ou encore le supplément
			de valeur que les clients sont prêts à payer pour acheter le produit transformé, par rapport à la matière première non transformée.
			<br />
			<br />
			Note : ici la valeur ajoutée est exprimée en % par rapport au chiffre d&apos;affaires (CA) afin de pouvoir être comparée à celle des autres entreprises de l&apos;échantillon,
			indépendamment de la taille de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Valeur ajoutée / CA ={" "}
			<div className="equation">
				<div className="fraction">
					<div>
						chiffre d’affaires net
						<br />+ production stockée ou immobilisée
						<br />- achats et variation de stock de marchandises
						<br />- achats et variation de stock de matières premières
						<br />- charges externes
					</div>
					<div>chiffre d’affaires net</div>
				</div>
			</div>
		</span>
	);
}

export function PoidsSalInfoTip() {
	return (
		<span>
			Mesure le poids de la masse salariale (charges comprises) dans le total des charges d&apos;exploitation.
			<br />
			<br />
			Formule:
			<br />
			Poids des salaires ={" "}
			<div className="equation">
				<div className="fraction">
					<div>salaires + charges sociales</div>
					<div>total des charges d&apos;exploitation</div>
				</div>
			</div>
			<br />
			<br />
			Note : pour vérifier si votre ratio de poids des salaires est cohérent avec votre activité, vous pouvez consulter les valeurs de votre secteur à l’étape Comparaison aux
			pairs.
		</span>
	);
}

export function SalCAInfoTip() {
	return (
		<span>
			Le ratio de masse salariale sur chiffre d&apos;affaires est un indicateur du <b>modèle économique</b> de l&apos;entreprise.
			<br />
			Il varie beaucoup d’un secteur à un autre : plutôt faible dans l’industrie, un secteur en général consommateur de matières premières et investissements, et plutôt élevé dans
			le secteur des services qui traditionnellement utilise davantage de ressources humaines.
			<br />
			<br />
			Formule:
			<br />
			Ratio de masse salariale sur CA ={" "}
			<div className="equation">
				<div className="fraction">
					<div>salaires + charges sociales</div>
					<div>chiffre d’affaires net</div>
				</div>
			</div>
		</span>
	);
}

export function PoidsChargFinInfoTip() {
	return (
		<span>
			Mesure le poids des charges financières (intérêts des emprunts et autres charges financières) dans le total des charges.
			<br />
			<br />
			Formule:
			<br />
			Poids des charges financières ={" "}
			<div className="equation">
				<div className="fraction">
					<div>charges d&apos;intérêts des emprunts + autres charges financières</div>
					<div>total des charges</div>
				</div>
			</div>
		</span>
	);
}

export function StocksCAInfoTip() {
	return (
		<span>
			Le ratio de stocks sur chiffre d&apos;affaires est un indicateur du <b>performance de la gestion des stocks</b> de l&apos;entreprise.
			<br />
			Plus le ratio est élevé, plus les stocks pèsent sur le besoin en fonds de roulement.
			<br />
			<br />
			Formule:
			<br />
			Ratio de stocks sur CA ={" "}
			<div className="equation">
				<div className="fraction">
					<div>total des stocks</div>
					<div>chiffre d’affaires net</div>
				</div>
			</div>
		</span>
	);
}

export function PoidsStocksInfoTip() {
	return (
		<span>
			Mesure le poids des stocks dans le total des actifs de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Poids des stocks ={" "}
			<div className="equation">
				<div className="fraction">
					<div>total des stocks nets</div>
					<div>total des actifs nets</div>
				</div>
			</div>
			<br />
			<br />
			Note : pour vérifier si votre ratio de poids des stocks est cohérent avec votre activité, vous pouvez consulter les valeurs de votre secteur à l’étape Comparaison aux pairs.
		</span>
	);
}

export function PoidsImmosInfoTip() {
	return (
		<span>
			Mesure le poids des immobilisations dans le total des actifs de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Poids des immobilisations ={" "}
			<div className="equation">
				<div className="fraction">
					<div>total des immobilisations nettes</div>
					<div>total des actifs nets</div>
				</div>
			</div>
			<br />
			<br />
			Note : pour vérifier si votre ratio de poids des immobilisations est cohérent avec votre activité, vous pouvez consulter les valeurs de votre secteur à l’étape Comparaison
			aux pairs.
		</span>
	);
}

export function PoidsImmosCorpInfoTip() {
	return (
		<span>
			Mesure le poids des immobilisations corporelles dans le total des actifs de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Poids des immobilisations corporelles ={" "}
			<div className="equation">
				<div className="fraction">
					<div>total des immobilisations corporelles nettes</div>
					<div>total des actifs nets</div>
				</div>
			</div>
		</span>
	);
}

export function PoidsImmosIncorpInfoTip() {
	return (
		<span>
			Mesure le poids des immobilisations incorporelles dans le total des actifs de l&apos;entreprise.
			<br />
			<br />
			Formule:
			<br />
			Poids des immobilisations incorporelles ={" "}
			<div className="equation">
				<div className="fraction">
					<div>total des immobilisations incorporelles nettes</div>
					<div>total des actifs nets</div>
				</div>
			</div>
		</span>
	);
}

export function VetusteImmosCorpInfoTip() {
	return (
		<span>
			Le ratio de vétusté mesure le degré d’usure de l’outil de production de l’entreprise.
			<br />
			Proche de 100%, il signifie que l’outil de production est presque neuf. Dans le cas contraire, il indique qu’il est vieillissant et nécessitera un renouvellement plus ou
			moins proche.
			<br />
			<br />
			Formule:
			<br />
			Ratio de vétusté ={" "}
			<div className="equation">
				<div className="fraction">
					<div>immobilisations corporelles nettes</div>
					<div>immobilisations corporelles brutes</div>
				</div>
			</div>
		</span>
	);
}

export function ChargesExplVariablesInfoTip() {
	return (
		<span>
			Les charges variables sont celles dont le montant est <b>proportionnel au chiffre d&apos;affaires</b>. Par exemple, les approvisionnements, ou certains coûts salariaux.
			<br />
			<br />
			Dans l&apos;application, il s&apos;agit des charges prévisionnelles <b>saisies en pourcentage du chiffre d&apos;affaires</b>.<br />
			<br/>
			Ce montant porte sur les charges d&apos;exploitation uniquement (notamment, hors charges financières).
		</span>
	);
}

export function ChargesExplFixesInfoTip() {
	return (
		<span>
			Les charges fixes sont celles dont le montant est <b>indépendant du chiffre d&apos;affaires</b>, autrement dit, que l&apos;entreprise aura à payer quel que soit son volume
			d&apos;activité. Par exemple, les loyers, les frais administratifs, ou encore l&apos;amortissement des immobilisations.
			<br />
			<br />
			Dans l&apos;application, il s&apos;agit des charges prévisionnelles <b>saisies en montant</b> dans le prévisionnel (et non en % du chiffre d&apos;affaires).<br/>
			<br/>
			Ce montant porte sur les charges d&apos;exploitation uniquement (notamment, hors charges financières).
		</span>
	);
}

export function MargeCoutsVariablesInfoTip() {
	return (
		<span>
			La marge sur coûts variables est un indicateur de <b>rentabilité</b>.
			<br />
			Elle représente la marge de manoeuvre financière dont dispose l&apos;entreprise afin de financer ses frais fixes.
			<br />
			<br />
			Elle est égale à la différence entre le chiffre d&apos;affaires et les charges variables d&apos;exploitation.
		</span>
	);
}

export function SeuilRentaExplInfoTip() {
	return (
		<span>
			Le seuil de rentabilité correspond au <b>niveau minimum de chiffre d&apos;affaires permettant d&apos;atteindre un résultat d&apos;exploitation positif</b>
			.<br />
			Si le chiffre d&apos;affaires est inférieur au seuil de rentabilité, l&apos;entreprise est en perte d&apos;exploitation.
			<br />
			S&apos;il est au-dessus, l&apos;entreprise dégage un bénéfice d&apos;exploitation.
			<br />
			<br />
			Le seuil de rentabilité se calcule à partir de la ventilation coûts fixes / coûts variables, selon la formule suivante :<br />
			<div className="equation">
				Seuil de rentabilité ={" "}
				<div className="fraction">
					<div>Charges fixes</div>
					<div>Marge sur coûts variables (en % du CA)</div>
				</div>
			</div>
			<br />
			{/*
			Le seuil de rentabilité est déterminé comme étant le montant du chiffre d&apos;affaires au moins égal à celui de la somme des charges d&apos;exploitation variables et charges 
			d&apos;exploitation fixes.<br/>
			*/}
			<br />
			<u>Note</u> :<br />
			Les charges variables calculées ici correspondent aux hypothèses de coûts variables saisies dans le prévisionnel, hors montants éventuellement issus d&apos;états
			financiers précédents qui sont considérés en tant que coûts fixes.
		</span>
	);
}

export function PointMortExplInfoTip() {
	return (
		<span>
			Le point mort annuel correspond à la <b>durée nécessaire pour atteindre le seuil de rentabilité</b>.<br />
			Il est exprimé en nombre de jours.
			<br />
			{/*Si le seuil de rentabilité ne peut être atteint sur l&apos;horizon du prévisionnel, le point mort n&apos;est pas calculé.*/}
			Si le seuil de rentabilité annuel ne peut être atteint dans l&apos;année, le point mort annuel n&apos;est pas calculé.
		</span>
	);
}

const MetricInfoTipComponents = {
	bs_tresorerie: TresoInfoTip,
	bfre: BFREInfoTip,
	bfr: BFRInfoTip,
	bfr_j_ca: BFRNormInfoTip,
	is_resultat_exploitation: RexInfoTip,
	is_excedent_brut_exploitation: EBEInfoTip,
	ebitda: EbitdaInfoTip,
	is_caf: CafInfoTip,
	dettes_nettes_inf_1_an: DetNetCTInfoTip,
	couverture_bfr: CouvBFRInfoTip,
	dettes_financieres_nettes_caf: DetCafInfoTip,
	is_valeur_ajoutee: ValAjoutInfoTip,
	marge_operationnelle: MargOpInfoTip,
	marge_ebe: MargeEBEInfoTip,
	marge_ebitda: MargeEbitdaInfoTip,
	marge_nette: MargNetInfoTip,
	rentabilite_fonds_propres: RentaFPInfoTip,
	ratio_endettement: GearingInfoTip,
	liquidite_generale: LiqGenInfoTip,
	couverture_dettes: CouvDetInfoTip,
	delai_paiement_clients: DelaiCliInfoTip,
	delai_paiement_fournisseurs: DelaiFourInfoTip,
	delai_stocks_marchandises: DelaiStockMarchInfoTip,
	delai_stocks_matières_premières: DelaiStockMatPremInfoTip,
	delai_stocks_produits: DelaiStockProdInfoTip,
	taux_marge_commerciale_negoce: TauxMargeCommMarchInfoTip,
	taux_marge_brute_production: TauxMargeBruteProdInfoTip,
	poids_salaires: PoidsSalInfoTip,
	salaires_ca: SalCAInfoTip,
	poids_charges_financieres: PoidsChargFinInfoTip,
	poids_stocks: PoidsStocksInfoTip,
	stocks_ca: StocksCAInfoTip,
	poids_immobilisations: PoidsImmosInfoTip,
	poids_immobilisations_corporelles: PoidsImmosCorpInfoTip,
	poids_immobilisations_incorporelles: PoidsImmosIncorpInfoTip,
	vetuste_immobilisations_corporelles: VetusteImmosCorpInfoTip,
	is_charges_variables_exploitation: ChargesExplVariablesInfoTip,
	is_charges_fixes_exploitation: ChargesExplFixesInfoTip,
	is_marge_couts_variables: MargeCoutsVariablesInfoTip,
	seuil_rentabilite_exploitation: SeuilRentaExplInfoTip,
	point_mort_exploitation: PointMortExplInfoTip,
};

export function MetricInfoTip(props) {
	const InfoTipComp = MetricInfoTipComponents[props.metricId];
	if (typeof InfoTipComp === "undefined") return null;
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={props.placement}
			overlay={
				<Popover id={(props.id || "") + props.metricId} className={"infoPopover " + (props.boxSize ? props.boxSize : "medium")}>
					<Popover.Body>
						<InfoTipComp />
					</Popover.Body>
				</Popover>
			}
		>
			<span className={"infotip " + (typeof props.symbolSize !== "undefined" ? props.symbolSize : "")}>
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

/*
export function CashChartInfoTip(props) {	
	return (
		<OverlayTrigger trigger="click" rootClose placement="left" overlay={(
				<Popover id={(props.id || "") + "CashChartInfoTip"} className="infoPopover small"><Popover.Body>
					Ce graphique représente l&apos;évolution de la trésorerie (caisse + disponibilités à court terme) projetée sur les années suivantes.
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

/*
export function MarginChartInfoTip(props) {	
	return (
		<OverlayTrigger trigger="click" rootClose placement="left" overlay={(
				<Popover id={(props.id || "") + "MarginChartInfoTip"} className="infoPopover large"><Popover.Body>
					<ul>
						<li>
							La <b>marge opérationnelle</b> représente la capacité de l’entreprise à <b>générer des profits seulement à partir de son activité</b>, sans tenir compte de son résultat financier et de son résultat exceptionnel.<br/>
							La marge opérationnelle permet d’étudier la <b>pérennité</b> de l’entreprise.<br/>
							Si ce taux augmente, alors l’entreprise augmente sa rentabilité.<br/>
							<br/>
							Formule:<br/>
							Montant du résultat d&apos;exploitation &divide; montant du chiffre d&apos;affaires, exprimé en %<br/>
						</li>
						<br/>
						<li>
							La <b>marge nette</b> offre une vision de la <b>capacité globale de l’entreprise à générer des profits</b> à partir de son activité d&apos;exploitation ainsi que de ses résultats financier et exceptionnel.<br/>
							La marge nette détermine le niveau de <b>rentabilité</b> de l&apos;entreprise une fois qu&apos;elle a payé toutes ses charges.<br/>
							Si ce taux augmente, alors l’entreprise augmente sa rentabilité.<br/>
							<br/>
							Formule:<br/>
							<div className="equation"><div className="fraction"><div>Montant du résultat net</div><div>montant du chiffre d&apos;affaires</div></div></div> , exprimé en %
						</li>
					</ul>
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

/*
export function GearingChartInfoTip(props) {	
	return (
		<OverlayTrigger trigger="click" rootClose placement="left" overlay={(
				<Popover id={(props.id || "") + "GearingChartInfoTip"} className="infoPopover small"><Popover.Body>
					Ce graphique représente l&apos;évolution du levier financier projetée sur les années suivantes. 
					Le levier financier est défini comme le ratio du montant des dettes financières nettes des disponibilités, rapportées au montant des fonds propres (ratio exprimé en unité et non en pourcentage).
				</Popover.Body></Popover>
			)}>
			<span className="infotip"><i className="bi bi-info-circle"/></span>
		</OverlayTrigger>
	);
}
*/

export function MetricEligibilityInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "MetricEligibilityInfoTip"} className="infoPopover small">
					<Popover.Body>
						L&apos;éligibilité du projet à un financement par crédit bancaire est généralement conditionnée, parmi d&apos;autres critères, aux niveaux de certains indicateurs et
						ratios financiers.
						<br />
						La liste ci-dessous reprend, pour les principaux, les niveaux moyens le plus souvent observés par des financeurs bancaires.
						<br />
						Grâce à la symbolique Vert / Orange / Rouge, suivez en un coup d&apos;oeil l&apos;éligibilité financière de votre projet.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

function TresoTestInfoTip() {
	return (
		<span>
			La trésorerie représente la somme des fonds disponibles sans délai. On parle aussi de liquidités disponibles en caisse ou en banque. Elle inclut les placements à très court
			terme, cessibles immédiatement.
			<br />
			<br />
			Si la trésorerie devient négative cela revient à une faillite de l’entreprise.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			La trésorerie reste positive sur l&apos;horizon de projection
			<br />
			<div className="rogLight red on" />
			La trésorerie est négative au moins pendant une année sur l&apos;horizon de projection
		</span>
	);
}

function CouvBFRTestInfoTip() {
	return (
		<span>
			Le ratio de couverture du BFR est un indicateur d&apos;
			<b>autonomie financière</b> à court-terme. Il désigne la capacité de l&apos;entreprise à couvrir son besoin en fonds de roulement par des capitaux stables.
			<br />
			<br />
			Note : le ratio peut être :
			<ul>
				<li>Supérieur à 1 si le fonds de roulement (FR) couvre en totalité le BFR</li>
				<li>Compris entre 0 et 1 si le fonds de roulement (FR) ne couvre que partiellement le BFR</li>
				<li>Inférieur à 0 si le BFR est négatif (auquel cas l&apos;activité de l&apos;entreprise génère des flux de trésorerie positifs)</li>
			</ul>
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Le fonds de roulement couvre le BFR sur tout l&apos;horizon de projection
			<br />
			<div className="rogLight orange on" />
			Le fonds de roulement est parfois insuffisant pour couvrir le BFR
			<br />
			<div className="rogLight red on" />
			Le fonds de roulement ne couvre jamais le BFR sur tout l&apos;horizon de projection
		</span>
	);
}

function DetCafTestInfoTip() {
	return (
		<span>
			Il s&apos;agit d&apos;un indicateur de <b>capacité de remboursement</b>. Il exprime le nombre d&apos;années nécessaire à l&apos;entreprise pour rembourser ses emprunts au
			moyen de sa capacité d’autofinancement.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Inférieur à 3 ans
			<br />
			<div className="rogLight orange on" />
			Compris entre 3 et 8 ans
			<br />
			<div className="rogLight red on" />
			Supérieur à 8 ans
		</span>
	);
}

function MargOpTestInfoTip() {
	return (
		<span>
			Elle représente la capacité de l’entreprise à <b>générer des profits seulement à partir de son activité</b>, sans tenir compte de son résultat financier et de son résultat
			exceptionnel. <br />
			La marge opérationnelle permet d’étudier la <b>pérennité</b> de l’entreprise.
			<br />
			Si ce taux augmente, alors l’entreprise augmente sa rentabilité.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Niveau de marge positif et supérieur à celui de départ, au terme de l&apos;horizon de projection
			<br />
			<div className="rogLight orange on" />
			Niveau de marge inférieur à celui de départ, au terme de l&apos;horizon de projection
			<br />
			<div className="rogLight red on" />
			Marge décroissante sur tout l&apos;horizon de projection
		</span>
	);
}

function RentaFPTestInfoTip() {
	return (
		<span>
			La rentabilité des fonds propres est un ratio <b>destiné aux actionnaires</b>.<br />
			Il permet de mesurer la <b>capacité des capitaux investis</b> par les actionnaires et associés (capitaux propres) <b>à dégager du profit</b>.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Toujours supérieure à 7,5%
			<br />
			<div className="rogLight orange on" />
			Parfois comprise entre 3% et 7,5%
			<br />
			<div className="rogLight red on" />
			Parfois inférieure à 3%
		</span>
	);
}

function GearingTestInfoTip() {
	return (
		<span>
			Il s&apos;agit du <b>taux d’endettement moyen</b> de l&apos;entreprise, aussi connu sous le nom de <b>Gearing</b>.
			<br />
			Il indique la proportion dans laquelle une entreprise se finance : <b>sources externes</b> (emprunts et dettes financières) vs. <b>sources internes</b> (associés et
			actionnaires).
			<br />
			<br />
			Il est exprimé en unité et non en pourcentage.
			<br />
			<br />
			Si il est supérieur à 1 (100%), il indique que l&apos;entreprise se finance en majorité avec des sources externes.
			<br />
			SI il est inférieur à 1, il indique que l&apos;entreprise se finance en majorité avec des sources internes.
			<br />
			Si il est trop élevé (en général un niveau supérieur à 2 est considéré comme élevé), la société dépend fortement des prêteurs, et les charges financières associées risquent
			d&apos;avoir un impact négatif sur le résultat net de l&apos;entreprise et ses conditions d&apos;emprunt.
			<br />
			Si il est faible (proche de zéro), on pourrait en conclure que la société dépend beaucoup des investisseurs et se montre trop prudente dans sa gestion.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Toujours compris entre 0 et 1,5
			<br />
			<div className="rogLight orange on" />
			Parfois compris entre 1,5 et 2<br />
			<div className="rogLight red on" />
			Parfois négatif ou supérieur à 2
		</span>
	);
}

function LiqGenTestInfoTip() {
	return (
		<span>
			Le ratio de liquidité générale mesure la <b>capacité à régler ses dettes à court terme</b>. Il s’agit principalement des dettes fournisseurs, dettes fiscales et dettes
			sociales. Il est exprimé en unité.
			<br />
			<br />
			<b>Les ratios acceptables dépendent du secteur</b>, mais un ratio d&apos;environ 2 est considéré comme raisonnable.
			<br />
			Si les dettes à court terme dépassent les actifs à court terme (c&apos;est-à-dire si le ratio est inférieur à 1), l&apos;entreprise peut ne pas être en mesure de tenir ses
			engagements.
			<br />
			Tandis que si le ratio est trop élevé, il est possible que l&apos;entreprise n&apos;utilise pas efficacement ses actifs.
			<br />
			<br />
			Résultats :<br />
			<div className="rogLight green on" />
			Toujours supérieure à 1,2
			<br />
			<div className="rogLight orange on" />
			Parfois comprise entre 1 et 1,2
			<br />
			<div className="rogLight red on" />
			Parfois inférieure à 1
		</span>
	);
}

function CouvDetTestInfoTip() {
	return (
		<span>
			Le ratio de couverture des dettes est un ratio de <b>solvabilité</b> dans un scénario de liquidation de l&apos;entreprise. Il exprime la{" "}
			<b>capacité à rembourser les dettes financières</b> par liquidation des actifs cessibles (en faisant l&apos;hypothèse que les actifs puissent être cédés à leur valeur
			d&apos;enregistrement au bilan). Il est exprimé en unité.
			<br />
			<br />
			S&apos;il est :<br />
			<ul>
				<li>
					<b>supérieur à 1</b> (100%), l&apos;entreprise a théoriquement la capacité à rembourser la totalité de ses dettes financières en cédant ses actifs.
				</li>
				<li>
					<b>compris entre 0 et 1</b>, dans un scénario de liquidation l&apos;entreprise ne serait pas en mesure de rembourser la totalité de ses dettes en cédant ses actifs.
				</li>
				<li>
					<b>négatif</b>, l’entreprise a la capacité à rembourser la totalité de ses dettes financières grâce à sa trésorerie.
				</li>
			</ul>
			Résultats :<br />
			<div className="rogLight green on" />
			Toujours négatif ou supérieur à 1<br />
			<div className="rogLight orange on" />
			Parfois compris entre 0 et 1<br />
			<div className="rogLight red on" />
			Toujours compris entre 0 et 1
		</span>
	);
}

const MetricTestInfoTipComponents = {
	tresorerie: TresoTestInfoTip,
	couverture_bfr: CouvBFRTestInfoTip,
	dettes_financieres_nettes_caf: DetCafTestInfoTip,
	marge_operationnelle: MargOpTestInfoTip,
	rentabilite_fonds_propres: RentaFPTestInfoTip,
	ratio_endettement: GearingTestInfoTip,
	liquidite_generale: LiqGenTestInfoTip,
	couverture_dettes: CouvDetTestInfoTip,
};

export function MetricTestInfoTip(props) {
	const InfoTipComp = MetricTestInfoTipComponents[props.metricId];
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={props.placement}
			overlay={
				<Popover id={(props.id || "") + props.metricId + "InfoTip"} className="infoPopover small">
					<Popover.Body>
						<InfoTipComp />
					</Popover.Body>
				</Popover>
			}
		>
			<span className={"infotip " + (typeof props.symbolSize !== "undefined" ? props.symbolSize : "")}>
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ReportUnitsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "ReportUnitsInfoTip"} className="infoPopover small">
					<Popover.Body>
						Par défaut sur le rapport exporté, l&apos;unité offrant le maximum de lisibilité des tableaux est appliquée automatiquement.
						<br />
						Vous pouvez cependant forcer l&apos;unité des montants si vous souhaitez par exemple les afficher avec plus de détail.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ReportColorSchemeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "ReportColorSchemeInfoTip"} className="infoPopover small">
					<Popover.Body>
						Choisissez la variante de couleurs du rapport
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function MonthlyTableWidgetInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "MonthlyTableWidgetInfoTip"} className="infoPopover small">
					<Popover.Body>
						Par défaut, à l&apos;export seule l&apos;année sélectionnée est affichée en mensuel.<br/>
						En activant l&apos;export mensuel sur toutes les années, vous pouvez afficher le tableau en mensuel sur tout l&apos;horizon de projection, avec
						une année par page du rapport.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SimulInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "SimulInfoTip"} className="infoPopover large">
					<Popover.Body>
						Vous avez la possibilité de créer plusieurs simulations. Les hypothèses et les résultats de toutes vos simulations se retrouveront dans votre dossier à la dernière
						étape.
						<br />
						<br />
						Les simulations vous permettent d&apos;observer l&apos;évolution des indicateurs financiers dans des contextes complexes. Vous pouvez, en réaction à un scénario de
						stress, élaborer des stratégies.
						<br />
						Pour ce faire, créez et sélectionnez une simulation. Sélectionnez ensuite des scénarios pour les ajouter à la simulation.
						<br />
						<br />
						Exemple :<br />
						Suite à une « récession dans votre secteur d’activité », vous pouvez appliquer des scénarios de coupe budgétaire pour mettre en avant vos marges de manœuvre, vos
						capacités d’anticipation et de gestion de crise.
						<br />
						<br />
						Pour plus de détails sur l&apos;utilisation des simulations, regardez le didacticiel &quot;Simulations&quot;.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ScenarInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "ScenarInfoTip"} className="infoPopover large">
					<Popover.Body>
						Vous avez la possibilité de créer plusieurs scénarios afin des les utiliser dans une ou plusieurs simulations.
						<br />
						Un scenario est un ensemble d’hypothèses qui représentent une réalité interne ou externe à l&apos;entreprise.
						<br />
						<br />
						Créez des scénarios négatifs, aussi connus sous le nom de stress-tests, pour observer comment se dégrade la situation financière de votre entreprise et ainsi déterminer
						sa robustesse.
						<br />
						<br />
						Par exemple :<br />
						Une « crise des matières premières » peut se traduire par un scénario avec une hypothèse :<br />
						- Matières premières : croissance annuelle de 6,5% des prix pendant 2 ans.
						<br />
						<br />
						En réaction, créez des scénarios positifs crédibles qui améliorent la situation financière.
						<br />
						<br />
						Par exemple :<br />
						Si vos délais de paiement fournisseurs sont à 33 jours, et que vos délais de paiement clients sont à 58 jours, vous pouvez créer un scénario « Renégociation des délais
						de paiement » et ajoutant les hypothèses suivantes :<br />
						- Délai de paiement fournisseurs : + 20% (33j -&gt; 40 jours)
						<br />
						- Délai de paiement client : - 20% (58j -&gt; 46 jours)
						<br />
						<br />
						Observez l&apos;influence des scénarios en les ajoutant à une simulation.
						<br />
						<br />
						Pour plus de détails sur l&apos;utilisation des scénarios, regardez le didacticiel &quot;Simulations&quot;.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SimulFactorsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "SimulFactorsInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Visualisez ici le détail des hypothèses des scénarios sélectionnés dans la simulation active.
						<br />
						<br />
						En passant la souris sur les graphiques vous pourrez voir leur valeur de manière détaillée.
						<br />
						<br />
						Pour ajouter des hypothèses, créez ou modifiez le scénario correspondant.
						<br />
						<br />
						Pour plus de détails sur l&apos;utilisation des hypothèses, regardez le didacticiel &quot;Simulations&quot;.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ScenarFactorsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "ScenarFactorsInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Visualisez ici le détail des hypothèses du scénario actif.</p>
						<p>Pour ajouter des hypothèses, glissez les facteurs de la liste de gauche dans le cadre ci-dessous.</p>
						<p>
							Sélectionnez ci-contre si les variations s&apos;appliquent sur des années fiscales (du premier au dernier mois de l&apos;exercice fiscal de l&apos;entreprise), ou
							bien en années civiles de janvier à décembre.
						</p>
						<p>En passant la souris sur les graphiques vous pourrez voir leur valeur de manière détaillée.</p>
						<p>Pour plus de détails sur l&apos;utilisation des hypothèses, regardez le tutoriel &quot;Simulations&quot;.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutoFundingInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "AutoFundingInfoTip"} className="infoPopover large">
					<Popover.Body>
						L&apos;emprunt nécessaire au financement de votre projet peut être calculé <b>automatiquement</b>.<br />
						<br />
						Nous déterminons le meilleur compromis entre le montant, la date de début d&apos;emprunt, et la durée de remboursement couvrant les besoins de trésorerie sur la durée
						de projection.
						<br />
						Par défaut le taux d&apos;intérêt annuel appliqué sur les montants restant à rembourser est fixé à 2%. Les montants d&apos;intérêts correspondants sont pris en compte
						dans le poste &quot;Autres charges&quot; du compte de résultat synthétique.
						<br />
						<br />
						Vous avez la possibilité de sauvegarder le résultat comme hypothèse du prévisionnel, puis si vous le souhaitez d&apos;en ajuster les paramètres via la section
						&quot;Saisie avancée&quot; de l&apos;hypothèse créée.
						<br />
						<br />
						Note : il se peut, dans certains cas, que votre projet ne puisse être financé de manière optimale par un emprunt à moyen terme. Dans ce cas vous verrez s&apos;afficher
						un message en ce sens.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DividendsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "DividendsInfoTip"} className="infoPopover small">
					<Popover.Body>
						Vous pouvez définir le taux de distribution de dividendes sur chacune des années de votre business plan.
						<br />
						Le taux est appliqué au total du résultat distribuable, incluant le report à nouveau*.
						<br />
						<br />
						Pensez à utiliser le taux de dividendes comme levier de robustesse de votre business plan, ou comme marge de manoeuvre dans un scénario adverse.
						<br />
						<br />* Report à Nouveau = bénéfice d&apos;un exercice comptable précédent, qui n&apos;a été ni distribué en dividendes ni mis en réserve de fonds propres.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

function RevSectInfoTip() {
	return (
		<span>Une hypothèse d&apos;évolution du chiffre d&apos;affaires du secteur s&apos;applique uniformément aux 3 catégories de CA, de négoce, de service, et de production.</span>
	);
}

const ScenarFactorInfoTipComponents = {
	CoutsMarch: CoutsMarchInfoTip,
	CoutsPerso: CoutsPersoInfoTip,
	CoutsMatPrem: CoutsMatPremInfoTip,
	CoutsExt: CoutsExtInfoTip,
	CoutsExploit: CoutsExploitInfoTip,
	FinDetCT: FinDetCTInfoTip,
	RevSecteur: RevSectInfoTip,
	RevNego: RevNegoInfoTip,
	RevProd: RevProdInfoTip,
	RevServ: RevServInfoTip,
	RevExploit: RevExploitInfoTip,
	DelaisPaieCli: DelaiCliInfoTip,
	DelaisPaieFour: DelaiFourInfoTip,
	DelaisLivrCli: DeliveryDelayInfoTip,
	DelaisLivrFour: DeliveryDelayInfoTip,
	TauxMargeComm: TauxMargeCommMarchInfoTip,
	TauxMargeBrut: TauxMargeBruteProdInfoTip,
};

export function ScenarFactorInfoTip(props) {
	const InfoTipComp = ScenarFactorInfoTipComponents[props.factorType];
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "ScenarFactorInfoTip"} className="infoPopover small">
					<Popover.Body>
						<InfoTipComp />
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function PaymentDelayInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "PaymentDelayInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le délai de paiement correspond au nombre de jours qui s&apos;écoulent entre la date de facturation (ou de signature, selon le type d&apos;hypothèse) et la date de
						règlement.
						<br />
						Pour saisir un paiement comptant, saisissez 0 jour.
						<br />
						<br />
						Par défaut, <b>si vous ne saisissez rien</b>, la mention{" "}
						<b>
							<i>auto</i>
						</b>{" "}
						apparaît.
						<br />
						Dans ce cas, c&apos;est le délai moyen de l&apos;année en cours par type de revenu ou de dépense, qui est appliqué.
						<br />
						<br />
						Pour revenir au délai par défaut, supprimez simplement votre saisie.
						<br />
						<br />
						Vous pouvez retrouver les délais moyens des paiements clients et fournisseurs dans le tableau &quot;Indicateurs opérationnels&quot;.
						<br />
						<br />
						<b>Notes</b> :<br />
						<ul>
							<li>
								Dans le cas d&apos;approvisionnements avec un délai de livraison, le délai de paiement d&apos;applique à partir de la date de facturation, soit à la fin du délai de
								livraison.
							</li>
							<li>L&apos;hypothèse aura un impact sur le compte de résultat à la date de facturation, et un impact sur la trésorerie au moment du paiement.</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ConsoMarchInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="left"
			overlay={
				<Popover id={(props.id || "") + "ConsoMarchInputInfoTip"} className="infoPopover large">
					<Popover.Body>
						Choisissez l&apos;impact qu&apos;auront les ventes sur la consommation du <b>stock de marchandises</b>.
						<br />
						<br />
						<u>
							<b>Si les ventes consomment des marchandises stockées</b>
						</u>
						, utilisez le <b>taux de marge commerciale</b> de négoce pour indiquer quelle quantité de marchandises sera consommée.
						<br />
						Choisissez de quelle manière vous définissez le taux de marge commerciale : soit par rapport au coût de revient, soit par rapport au chiffre d&apos;affaires (vous
						pouvez utiliser la calculatrice&nbsp;
						{/*<Isvg wrapper={React.createFactory('span')} src={iconCalculator} className="textSvg" />*/}
						<SvgWrap wrapperTag="span" src={iconCalculator} className="textSvg" />
						&nbsp;pour faciliter le calcul du taux de marge).
						<br />
						<br />
						<u>
							<b>Si les ventes ne consomment pas de marchandises stockées</b>
						</u>
						, indiquez &quot;<b>Aucune</b>&quot; consommation.
						<br />
						<br />
						<u>
							<b>Si vous ne saisissez rien</b>
						</u>
						, par défaut c&apos;est le taux de marge moyen de l&apos;année en cours calculé automatiquement qui est appliqué.
						<br />
						<br />
						Pour revenir au taux par défaut, supprimez simplement votre saisie.
						<br />
						<br />
						Vous pouvez retrouver l&apos;évolution de la valeur du taux de marge commerciale dans le tableau &quot;Indicateurs opérationnels&quot; / rubrique &quot;Stocks&quot;.
						<br />
						Vous pouvez également suivre les niveaux de stocks découlant de vos hypothèses avec l&apos;outil de visualisation de l&apos;évolution des stocks (menu
						&quot;Outils&quot;) et vérifier que vous ne vendez pas plus que ce que vous achetez ou produisez.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ConsoMatPremInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="left"
			overlay={
				<Popover id={(props.id || "") + "ConsoMatPremInputInfoTip"} className="infoPopover large">
					<Popover.Body>
						Choisissez l&apos;impact qu&apos;auront les ventes sur la consommation du <b>stock de matières premières</b>
						.<br />
						<br />
						<u>
							<b>Si les ventes consomment des matières premières stockées</b>
						</u>
						, utilisez le <b>taux de marge brute</b> de production pour indiquer quelle quantité de matières premières sera consommée.
						<br />
						Choisissez de quelle manière vous définissez le taux de marge brute : soit par rapport au coût de revient, soit par rapport au chiffre d&apos;affaires (vous pouvez
						utiliser la calculatrice&nbsp;
						{/*<Isvg wrapper={React.createFactory('span')} src={iconCalculator} className="textSvg" />*/}
						<SvgWrap wrapperTag="span" src={iconCalculator} className="textSvg" />
						&nbsp;pour faciliter le calcul du taux de marge).
						<br />
						<br />
						<u>
							<b>Si les ventes ne consomment pas de matières premières stockées</b>
						</u>
						, indiquez &quot;<b>Aucune</b>&quot; consommation.
						<br />
						<br />
						<u>
							<b>Si vous ne saisissez rien</b>
						</u>
						, par défaut c&apos;est le taux de marge moyen de l&apos;année en cours calculé automatiquement qui est appliqué.
						<br />
						<br />
						Pour revenir au taux par défaut, supprimez simplement votre saisie.
						<br />
						<br />
						Vous pouvez retrouver l&apos;évolution de la valeur du taux de marge brute dans le tableau &quot;Indicateurs opérationnels&quot; / rubrique &quot;Stocks&quot;.
						<br />
						Vous pouvez également suivre les niveaux de stocks découlant de vos hypothèses avec l&apos;outil de visualisation de l&apos;évolution des stocks (menu
						&quot;Outils&quot;) et vérifier que vous ne vendez pas plus que ce que vous achetez ou produisez.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function TauxMargeCommRefInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="left"
			overlay={
				<Popover id={(props.id || "") + "TauxMargeCommRefInfoTip"} className="infoPopover large">
					<Popover.Body>
						Indiquez comment vous définissez le taux de marge : par rapport au <b>coût de revient</b>, ou bien par rapport au <b>chiffre d&apos;affaires</b>:<br />
						<br />
						<ul>
							<li>
								<b>Taux de marge par rapport au coût de revient :</b>
								<br />
								Le taux de marge est égal à<br />
								<div className="equation">
									<div className="fraction">
										<div>prix de vente HT - prix HT des marchandises consommées</div>
										<div>prix HT des marchandises consommées</div>
									</div>
								</div>
								<br />
								Par exemple, si vous vendez 150 € HT une marchandise que vous avez acquise précédemment à 100 € HT, alors le taux de marge sur coût de revient est égal à :{" "}
								<div className="equation">
									<div className="fraction">
										<div>150 - 100</div>
										<div>100</div>
									</div>
								</div>{" "}
								= 50%.
								<br />
								<b>A noter : le taux de marge saisi dans ce cas doit toujours être supérieur à -100%</b>.
							</li>
							<br />
							<li>
								<b>Taux de marge par rapport au chiffre d&apos;affaires :</b>
								<br />
								Le taux de marge est égal à<br />
								<div className="equation">
									<div className="fraction">
										<div>prix de vente HT - prix HT des marchandises consommées</div>
										<div>prix de vente HT</div>
									</div>
								</div>
								<br />
								Par exemple, si vous vendez 150 € HT une marchandise que vous avez acquise précédemment à 100 € HT, alors le taux de marge sur chiffre d&apos;affaires est égal à :{" "}
								<div className="equation">
									<div className="fraction">
										<div>150 - 100</div>
										<div>150</div>
									</div>
								</div>{" "}
								= 33,33%.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function TauxMargeBrutRefInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="left"
			overlay={
				<Popover id={(props.id || "") + "TauxMargeBrutRefInfoTip"} className="infoPopover large">
					<Popover.Body>
						Indiquez comment vous définissez le taux de marge : par rapport au <b>coût de revient</b>, ou bien par rapport au <b>chiffre d&apos;affaires</b>:<br />
						<br />
						<ul>
							<li>
								<b>Taux de marge par rapport au coût de revient :</b>
								<br />
								Le taux de marge est égal à<br />
								<div className="equation">
									<div className="fraction">
										<div>prix de vente HT - prix HT des matières premières consommées</div>
										<div>prix HT des matières premières consommées</div>
									</div>
								</div>
								<br />
								Par exemple, si vous vendez 150 € HT un produit en ayant utilisé pour 100 € HT de matières premières, alors le taux de marge sur coût de revient est égal à :{" "}
								<div className="equation">
									<div className="fraction">
										<div>150 - 100</div>
										<div>100</div>
									</div>
								</div>{" "}
								= 50%.
								<br />
								<b>A noter : le taux de marge saisi dans ce cas doit toujours être supérieur à -100%</b>.
							</li>
							<br />
							<li>
								<b>Taux de marge par rapport au chiffre d&apos;affaires :</b>
								<br />
								Le taux de marge est égal à<br />
								<div className="equation">
									<div className="fraction">
										<div>prix de vente HT - prix HT des matières premières consommées</div>
										<div>prix de vente HT</div>
									</div>
								</div>
								<br />
								Par exemple, si vous vendez 150 € HT un produit en ayant utilisé pour 100 € HT de matières premières, alors le taux de marge sur chiffre d&apos;affaires est égal à
								:{" "}
								<div className="equation">
									<div className="fraction">
										<div>150 - 100</div>
										<div>150</div>
									</div>
								</div>{" "}
								= 33,33%.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AchatsStockesInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="left"
			overlay={
				<Popover id={(props.id || "") + "AchatsStockesInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le <b>stock</b> a un impact opérationnel et financier sur l&apos;activité de l&apos;entreprise.
						<br />
						<br />
						Choisissez si l&apos;hypothèse concerne des achats stockés ou non pour calculer automatiquement son impact sur le niveau des <b>stocks</b>
						.<br />
						<br />
						Les achats stockées sont notamment les matières premières nécessaires à la fabrication des produits (métaux, bois, etc.).
						<br />
						Les achats non stockés sont par exemple les matières non stockables (eau, énergie, etc.), les fournitures d&apos;entretien, administratives, petits équipements, etc.
						<br />
						<br />
						Si vous choisissez &quot;Achats stockés&quot;, le montant des achats viendra directement augmenter le stock de la catégorie de l&apos;hypothèse, marchandises ou
						matières premières.
						<br />
						Sinon, les achats n&apos;auront aucun impact sur le stock.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ConsumableExpenseInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "ConsumableExpenseInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Certaines activités font appel à des ressources externes considérées comme des achats <i>consommés</i>, entrant dans les coûts de production.
						<br />
						<br />
						Indiquez ici s&apos;il s&apos;agit d&apos;achats consommés, qui seront alors comptés en déduction de la marge commerciale ou marge brute de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DebtInterestRateInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "DebtInterestRateInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Saisissez le <b>taux d&apos;intérêt annuel</b> de l&apos;emprunt.
						<br />
						<br />
						Les valeurs de taux de crédit bancaire aux entreprises sont, actuellement, généralement comprises entre 1 et 4%, selon les maturités.
						<br />
						Dans le cas d&apos;un financement participatif (crowdlending), les taux sont plus élevés : entre 6% et 10% selon les plateformes.
						<br />
						Statistiques disponibles sur le portail de la Banque de France :{" "}
						<a href="https://www.banque-france.fr/statistiques/credit/credit/taux-des-credits-aux-entreprises" target="_blank" rel="noopener noreferrer">
							https://www.banque-france.fr/statistiques/credit/credit/taux-des-credits-aux-entreprises
						</a>
						<br />
						<br />
						Le taux d&apos;intérêt de l&apos;emprunt influera sur le montant des charges financières inscrites au compte de résultat.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function InvestInterestRateInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "DebtInterestRateInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Saisissez le <b>taux d&apos;intérêt ou de rendement annuel</b> de l&apos;investissement.
						<br />
						<br />
						Le taux indiqué influera sur le montant des produits financiers inscrits au compte de résultat.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CapitalizedProductionInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CapitalizedProductionInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Indiquez si l&apos;actif immobilisé résulte d&apos;une production interne à l&apos;entreprise.
						<br />
						<br />
						Par exemple, certaines dépenses de recherche et développement, ou bien des fabrications réalisées par l&apos;entreprise pour son propre usage, peuvent sous certaines
						conditions être valorisée sous forme d&apos;actif au bilan.
						<br />
						<br />
						Comptablement, cette valorisation se traduit par l&apos;enregistrement d&apos;un produit en compte de résultat, inscrit en tant que <i>production immobilisée</i>.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutoReinvestmentInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AutoReinvestmentInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Indiquez si vous souhaitez que l&apos;investissement soit automatiquement renouvelé lorsqu&apos;il a atteint sa durée d&apos;amortissement.<br/>
						Utile par exemple dans le cas de matériels d&apos;une durée de vie limitée qu&apos;il sera nécessaire de racheter régulièrement.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AmortTypeInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AmortTypeInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Choisissez le <b>profil de remboursement</b> de l&apos;emprunt :
						<br />
						<br />
						<b>Linéaire</b> : le capital emprunté est remboursé par fractions égales à chaque période (mensuelle ou annuelle) jusqu&apos;à l&apos;échéance. Les intérêts sont
						calculés (et versés) chaque mois sur le montant du capital restant dû.
						<br />
						<i>
							Par exemple, si la durée de remboursement est fixée à 2 ans, soit 24 mois, avec une périodicité mensuelle, alors chaque mois un montant de capital correspondant à
							1/24ème du montant total emprunté sera remboursé.
						</i>
						<br />
						<br />
						<b>Constant</b> : l&apos;échéancier de remboursement est calculé de telle sorte qu&apos;un montant fixe, comprenant capital et intérêts, soit remboursé chaque année (si
						amortissement annuel) ou chaque mois (si amortissement mensuel). On parle alors d&apos;annuités ou mensualités constantes.
						<br />
						Le montant d&apos;intérêts versé chaque période (annuelle ou mensuelle) est calculé sur le montant du capital restant dû sur cette période.
						<br />
						<br />
						<b>In fine</b> : le capital emprunté est remboursé en totalité à son échéance. Les intérêts sont calculés (et versés) chaque mois sur le montant total du capital
						restant dû.
						<br />
						<br />
						<b>% du CA</b> : l&apos;échéancier de remboursement est déterminé à partir du chiffre d&apos;affaires. Dénommé RBF (<i>Revenue Based Financing</i>), ce type
						d&apos;emprunt permet à l&apos;entreprise de ne rembourser chaque mois qu&apos;un montant proportionnel à ses revenus. Les intérêts sont calculés (et versés) chaque
						mois sur le montant total du capital restant dû.
						<br />
						<br />
						<b>Personnalisé</b> : les échéances de capital correspondent à celles que vous saisissez. Les intérêts sont calculés (et versés) chaque mois sur le montant total du
						capital restant dû.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AmortPeriodicityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AmortPeriodicityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Indiquer la fréquence de remboursement de l&apos;emprunt :<br />
						<br />
						<b>Mensuelle</b> : un montant correspondant à la mensualité est remboursé chaque mois, jusqu&apos;à l&apos;échéance.
						<br />
						<i>Par exemple, si la durée de remboursement est fixée à 2 ans, alors les remboursements auront lieu chaque mois pendant 24 mois.</i>
						<br />
						<br />
						<b>Trimestrielle</b> : les remboursements ont lieu tous les 3 mois, jusqu&apos;à l&apos;échéance.
						<br />
						<i>Par exemple, si la durée de remboursement est fixée à 2 ans, alors l&apos;emprunt sera remboursé en 8 échéances trimestrielles.</i>
						<br />
						<br />
						<br />
						<b>Annuelle</b> : un montant de capital correspondant à l&apos;annuité est remboursé au terme de chaque année, jusqu&apos;à l&apos;échéance.
						<br />
						<i>
							Par exemple, si la durée de remboursement est fixée à 2 ans, alors le capital sera remboursé en 2 fois : une première partie au bout d&apos;1 an puis une deuxième au
							bout de 2 ans.
						</i>
						<br />
						<br />A noter : dans tous les cas les intérêts sont calculés (et versés) chaque mois sur le montant du capital restant dû.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DeferredAmortInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DeferredAmortInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Vous pouvez indiquer ici si l&apos;emprunt fait l&apos;objet d&apos;un différé de remboursement. Dans ce cas, le remboursement du capital emprunté commence après le
						délai spécifié (en mois).
						<br />
						<br />
						Pendant la période de différé, les intérêts sont calculés (et versés) chaque mois sur le montant total du capital.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DepositInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DepositInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Si un acompte est versé à la commande ou à la signature, indiquez-le en pourcentage du montant total facturé.
						<br />
						Si le paiement est comptant, il n&apos;est pas nécessaire de saisir d&apos;acompte.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function InvoiceDelayInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "InvoiceDelayInputInfoTip"} className="infoPopover large">
					<Popover.Body>
						Le délai de livraison correspond au nombre de jours qui s&apos;écoulent entre la date de commande et la date de livraison de la prestation ou des biens vendus.
						<br />
						Il permet de définir la date de facturation de la commande.
						<br />
						Par défaut, aucun délai de livraison n&apos;est appliqué : la transaction est facturée à la commande.
						<br />
						<br />
						<b>Notes</b> :<br />
						<ul>
							<li>La date d&apos;application de l&apos;hypothèse, ou bien des paramètres de saisonnalité, correspondent à la date de commande.</li>
							<li>Lorsqu&apos;il existe un délai de paiement, celui-ci s&apos;applique à partir de la date de facturation, soit après le délai de livraison saisi.</li>
							<li>L&apos;hypothèse aura un impact sur le compte de résultat à la date de facturation, et un impact sur la trésorerie au moment du paiement.</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DeliveryDelayInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DeliveryDelayInputInfoTip"} className="infoPopover large">
					<Popover.Body>
						Le délai de livraison correspond au nombre de jours qui s&apos;écoulent entre la date de commande et la date de livraison de la prestation ou des biens achetés.
						<br />
						Il permet de définir la date de facturation de la commande.
						<br />
						Par défaut, aucun délai de livraison n&apos;est appliqué : la transaction est facturée à la commande.
						<br />
						<br />
						<b>Notes</b> :<br />
						<ul>
							<li>La date d&apos;application de l&apos;hypothèse, ou bien des paramètres de saisonnalité, correspondent à la date de commande.</li>
							<li>Dans le cas d&apos;achat d&apos;approvisionnements stockés, la date de livraison correspond à la date d&apos;entrée en stock.</li>
							<li>Lorsqu&apos;il existe un délai de paiement, celui-ci s&apos;applique à partir de la date de facturation, soit après le délai de livraison saisi.</li>
							<li>L&apos;hypothèse aura un impact sur le compte de résultat à la date de facturation, et un impact sur la trésorerie au moment du paiement.</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DeliveryDelayInfoTip() {
	return (
		<React.Fragment>
			Le délai de livraison correspond au nombre de jours qui s&apos;écoulent entre la date de commande et la date de livraison de la prestation ou des biens achetés.
			<br />
			Il permet de définir la date de facturation de la commande.
			<br />
			<br />
			<b>Notes</b> :<br />
			<ul>
				<li>Dans le cas d&apos;achat d&apos;approvisionnements stockés, la date de livraison correspond à la date d&apos;entrée en stock.</li>
				<li>Lorsqu&apos;il existe un délai de paiement, celui-ci s&apos;applique à partir de la date de facturation, soit après le délai de livraison saisi.</li>
			</ul>
		</React.Fragment>
	);
}

export function AnnuallyPrepaidServiceInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AnnuallyPrepaidServiceInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Indiquez ici s&apos;il s&apos;agit d&apos;un service ou d&apos;une prestation prépayé en un <b>versement unique</b>, et dont la{" "}
						<b>réalisation est étalée sur plusieurs mois</b>.<br />
						<br />
						Par exemple :
						<ul>
							<li>Une prime payée annuellement pour un contrat d&apos;assurance s&apos;exerçant tout au long de l&apos;année</li>
							<li>Un contrat de prestation de service vendu et encaissé d&apos;avance en une seule fois, dont la réalisation est étalée sur plusieurs mois.</li>
						</ul>
						<br />
						L&apos;activation de ce paramètre permet de gérer automatiquement l&apos;étalement comptable des produits (ou des charges) correspondant à la prestation, bien que la
						totalité du montant soit encaissé (ou décaissé) en trésorerie en une seule fois.
						<br />
						<br />
						Saisissez ci-dessous le <b>calendrier de réalisation</b> de la prestation, à partir duquel les produits (ou charges) seront enregistrés en compte de résultat.
						<br />
						Si le service est réparti à l&apos;identique sur les 12 mois de l&apos;année, laissez le choix par défaut, <i>Aucune saisonnalité</i>.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SalesSeasonalityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SalesSeasonalityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Précisez le <b>profil de saisonnalité de vos commandes reçues</b>.
						<br />
						Le chiffre d&apos;affaires est automatiquement calculé en tenant compte du délai de livraison saisi.
						<br />
						L&apos;effet en trésorerie tient compte, lui, du pourcentage d&apos;acompte et du délai de paiement définis.
						<br />
						<br />
						La saisonnalité des ventes (ainsi que des approvisionnements) influe sur le Besoin en Fonds de Roulement (BFR).
						<br />
						Le profil de saisonnalité est défini par les 12 valeurs de pourcentages correspondant aux ventes de chaque mois. Les pourcentages sont appliqués au montant annuel.
						<br />
						Par défaut, aucune saisonnalité ne s&apos;applique : les ventes sont équi-réparties sur l&apos;année. Le pourcentage des ventes de chaque mois est donc d&apos;1/12,
						soit 8,33%.
						<br />
						<br />
						Par exemple :<br />
						Si les ventes annuelles sont de 1000 et que le pourcentage du mois de février est de 20%, un montant de ventes de 200 sera calculé pour février.
						<br />
						<br />
						Note : le profil de saisonnalité est à renseigner en <b>année fiscale</b> de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DeferredSalesSeasonalityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DeferredSalesSeasonalityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Précisez le <b>calendrier de réalisation de vos prestations vendues</b>.<br />
						L&apos;enregistrement du chiffre d&apos;affaires en compte de résultat est automatiquement calculé en tenant compte du délai de livraison saisi.
						<br />
						<br />
						Ce calendrier n&apos;a pas d&apos;effet sur la trésorerie qui est, elle, liée à la date de commande, au pourcentage d&apos;acompte et au délai de paiement définis.
						<br />
						<br />
						Le profil calendaire est défini par les 12 valeurs de pourcentages correspondant aux prestations réalisées chaque mois. Les pourcentages sont appliqués au montant
						annuel.
						<br />
						Par défaut, aucune saisonnalité ne s&apos;applique : les prestations sont équi-réparties sur l&apos;année. Le pourcentage des prestations de chaque mois est donc
						d&apos;1/12, soit 8,33%.
						<br />
						<br />
						Par exemple :<br />
						Si les ventes annuelles sont de 1000 et que le pourcentage du mois de février est de 20%, un montant de chiffre d&apos;affaires de 200 sera calculé pour février.
						<br />
						<br />
						Note : le calendrier est à renseigner en <b>année fiscale</b> de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SuppliesSeasonalityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SuppliesSeasonalityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Précisez le <b>profil de saisonnalité de vos commandes d&apos;approvisionnements ou de prestations</b>
						.<br />
						Le montant des charges en compte de résultat est automatiquement calculé en tenant compte du délai de livraison saisi.
						<br />
						L&apos;effet en trésorerie tient compte, lui, du pourcentage d&apos;acompte et du délai de paiement définis.
						<br />
						<br />
						La saisonnalité des approvisionnements (ainsi que des ventes) influe sur le Besoin en Fonds de Roulement (BFR).
						<br />
						Le profil de saisonnalité est défini par les 12 valeurs de pourcentages correspondant aux ventes de chaque mois. Les pourcentages sont appliqués au montant annuel.
						<br />
						Par défaut, aucune saisonnalité ne s&apos;applique : les approvisionnements sont équi-répartis sur l&apos;année. Le pourcentage des approvisionnements de chaque mois
						est donc d&apos;1/12, soit 8,33%.
						<br />
						<br />
						Par exemple :<br />
						Si les approvisionnements annuels sont de 1000 et que le pourcentage du mois de février est de 20%, un montant d&apos;approvisionnements de 200 sera calculé pour
						février.
						<br />
						<br />
						Note : le profil de saisonnalité est à renseigner en <b>année fiscale</b> de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function DeferredSuppliesSeasonalityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "DeferredSuppliesSeasonalityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Précisez le <b>calendrier de réalisation des prestations ou services achetés</b>
						.<br />
						L&apos;enregistrement des charges en compte de résultat est automatiquement calculé en tenant compte du délai de livraison saisi.
						<br />
						<br />
						Ce calendrier n&apos;a pas d&apos;effet sur la trésorerie qui est, elle, liée à la date de commande, au pourcentage d&apos;acompte et au délai de paiement définis.
						<br />
						<br />
						Le profil calendaire est défini par les 12 valeurs de pourcentages correspondant aux prestations réalisées chaque mois. Les pourcentages sont appliqués au montant
						annuel.
						<br />
						Par défaut, aucune saisonnalité ne s&apos;applique : les prestations sont équi-réparties sur l&apos;année. Le pourcentage des prestations de chaque mois est donc
						d&apos;1/12, soit 8,33%.
						<br />
						<br />
						Par exemple :<br />
						Si les achats annuels sont de 1000 et que le pourcentage du mois de février est de 20%, un montant de charges de 200 sera calculé pour février.
						<br />
						<br />
						Note : le calendrier est à renseigner en <b>année fiscale</b> de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function GenericSeasonalityInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "GenericSeasonalityInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Précisez le <b>profil de saisonnalité</b> de votre hypothèse.
						<br />
						<br />
						Le profil de saisonnalité définit les 12 valeurs de pourcentage correspondant aux montants de charges, produits ou investissements de chaque mois. Les pourcentages sont
						appliqués au montant annuel.
						<br />
						Par défaut, aucune saisonnalité ne s&apos;applique : les montants sont équi-répartis sur l&apos;année. Le pourcentage chaque mois est donc d&apos;1/12, soit 8,33%.
						<br />
						<br />
						Par exemple :<br />
						Si le montant annuel de l&apos;hypothèse est de 1000 et que le pourcentage du mois de février est de 20%, un montant de 200 sera calculé pour février.
						<br />
						<br />
						Note : le profil de saisonnalité est à renseigner en <b>année fiscale</b> de l&apos;entreprise.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function EndMonthInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "EndMonthInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Lorsque vous saisissez une hypothèse applicable sur une partie de l&apos;année seulement, le montant total de l&apos;hypothèse est calculé à partir du montant annuel
						indiqué ci-dessus, au prorata du nombre de mois pendant lesquels l&apos;hypothèse s&apos;applique.
						<br />
						<br />
						Par exemple, une hypothèse d&apos;un montant annuel de 120 000 €, saisie d&apos;avril à juin (soit sur 3 mois), sera appliquée pour un montant total de 30 000 €<br />
						(soit 120 000 &times;{" "}
						<div className="equation">
							<div className="fraction">
								<div>3 mois</div>
								<div>12 mois</div>
							</div>
						</div>
						).
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CategoryPercentInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CategoryPercentInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<React.Fragment>
							Ce mode de saisie permet de modéliser des charges dépendantes du chiffre d&apos;affaires (aussi appelées charges variables).
							<br />
							Le pourcentage saisi s&apos;applique au montant calculé pour l&apos;année en cours, soit du chiffre d&apos;affaires total, soit des hypothèses de chiffre
							d&apos;affaires sélectionnées.
						</React.Fragment>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function VariationPercentInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "VariationPercentInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<React.Fragment>
							Ce mode de saisie permet de modéliser des variations de charges ou de revenus.
							<br />
							Le pourcentage saisi s&apos;applique au montant calculé pour l&apos;année en cours, soit du total de la catégorie concernée, soit des hypothèses sélectionnées,{" "}
							<b>avant application</b> de toute hypothèse saisie en montant ou en variation.
						</React.Fragment>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SplitVariationPercentInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SplitVariationPercentInputInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<React.Fragment>
							La variation peut être appliquée :
							<ul>
								<li>Soit en totalité, en une fois, dès la date d&apos;application de l&apos;hypothèse ;</li>
								<li>
									Soit de manière progressive, ou étalée, sur 12 mois.<br/>
									Par exemple une croissance de 10% étalée sur 12 mois correspondra à un taux de croissance chaque mois de 0,8%.<br/>
								</li>
							</ul>
						</React.Fragment>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function RecurringInputInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "RecurringInputInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>Utilisez ce mode de saisie pour définir des ventes d&apos;abonnements ou de souscriptions dont les paiements sont renouvelés chaque mois.</p>
						<p>Le nombre d&apos;abonnements actifs prévisionnel est automatiquement calculé à partir des paramètres suivants :</p>
						<ul>
							<li>
								Le <b>nombre de nouveaux abonnement</b> souscrits chaque mois.
								<br />
								Note : Un profil de <b>saisonnalité</b> peut être saisi, pour faire varier le nombre de nouveaux abonnements mensuels.
							</li>
							<br />
							<li>
								Le taux d&apos;<b>attrition</b> (ou <i>churn</i>) : définit le pourcentage d&apos;abonnements qui ne se renouvellent pas à chaque échéance, tenant compte de la
								durée d&apos;engagement saisie.
								<br />
								Par exemple, un taux d&apos;attrition de 10% combiné à une durée d&apos;engagement d&apos;1 an signifie que chaque année, 10% des abonnements arrivant à échéance ne
								seront pas renouvelés.
							</li>
							<br />
							<li>
								La <b>durée d&apos;engagement</b> : représente la période pendant laquelle un abonnement ne peut être résilié, et donc pendant laquelle le taux d&apos;attrition ne
								lui est pas appliqué.
							</li>
							<br />
							<li>
								Le <b>nombre initial</b> d&apos;abonnements : indique le stock initial d&apos;abonnements (hors nouveaux abonnements définis ci-dessus), qui s&apos;écoule chaque
								mois en fonction du taux d&apos;attrition et de la durée d&apos;engagement saisis.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function NewMonthlySubscriptionsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "NewMonthlySubscriptionsInfoTip"} className="infoPopover large">
					<Popover.Body>
						Nombre de nouveaux abonnement souscrits chaque mois.
						<br />
						Note : Un profil de <b>saisonnalité</b> peut être saisi, pour faire varier le nombre de nouveaux abonnements mensuels.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function InitSubscriptionsStockInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "InitSubscriptionsStockInfoTip"} className="infoPopover large">
					<Popover.Body>
						Indique le stock initial d&apos;abonnements (hors nouveaux abonnements définis ci-dessus), qui s&apos;écoule chaque mois en fonction du taux d&apos;attrition et de la
						durée d&apos;engagement saisis.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SubscriptionsChurnRateInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SubscriptionsChurnRateInfoTip"} className="infoPopover large">
					<Popover.Body>
						Le taux d&apos;attrition (ou <i>churn</i>) définit le pourcentage d&apos;abonnements qui ne se renouvellent pas à chaque échéance, tenant compte de la durée
						d&apos;engagement saisie.
						<br />
						Par exemple, un taux d&apos;attrition de 10% combiné à une durée d&apos;engagement d&apos;1 an signifie que chaque année, 10% des abonnements arrivant à échéance ne
						seront pas renouvelés.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SubscriptionsCommitmentInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SubscriptionsCommitmentInfoTip"} className="infoPopover large">
					<Popover.Body>
						Représente la période pendant laquelle un abonnement ne peut être résilié, et donc pendant laquelle le taux d&apos;attrition ne lui est pas appliqué.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function TaxExemptionSystemInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "TaxExemptionSystemInfoTip"} className="infoPopover large">
					<Popover.Body>
						<p>
							Dans le cadre d&apos;un groupe de sociétés, les dividendes remontés par une filiale à la société mère (qui constituent pour elle des produits financiers) peuvent être
							exonérés d&apos;impôt. Ce système a vocation à éviter une double imposition de bénéfices, à la fois sur la filiale et sur la société mère.
						</p>
						<p>Plusieurs dispositifs fiscaux sont prévus par la réglementation (conditions d&apos;éligibilité régies par le Code des Impôts) :</p>
						<ul>
							<li>
								<b>Régime mère-fille</b> :<br />
								Chaque société, la filiale et la holding, déclarent leur propre résultat fiscal.
								<br />
								Les dividendes distribués par la filiale sont exonérés à hauteur de 95% dans le résultat fiscal de la société mère.
							</li>
							<li>
								<b>Intégration fiscale</b> :<br />
								L&apos;intégration fiscale consiste à consolider les résultats fiscaux de toutes les sociétés du groupe. Le résultat consolidé est déclaré par la seule société
								mère.
								<br />
								Sous ce régime, les dividendes distribués par la filiale sont exonérés à hauteur de 99% dans le résultat fiscal du groupe.
							</li>
						</ul>
						Par défaut, lorsqu&apos;aucun régime spécifique n&apos;est sélectionné, les produits financiers sont intégrés, comme l&apos;ensemble des autres revenus, au bénéfice
						imposable.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FinancialsInitInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={
				<Popover id={(props.id || "") + "FinancialsInitInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>
							<b>Démarrer avec historique</b>
							<br />
							Sélectionnez une situation financière parmi celles à disposition ou ajoutez-en une nouvelle, pour initialiser le point de départ de vos prévisions (la trésorerie, les
							dettes et créances, ...).
						</p>
						<p>
							<i>
								Notez qu&apos;e vous ne pouvez choisir ici qu&apos;une situation représentant un <b>exercice complet</b> (autrement dit une situation couvrant la totalité de
								l&apos;exercice). Les situations partielles ne peuvent servir de point de départ. Vous pourrez en revanche les utiliser pour suivre le réalisé en cours
								d&apos;exercice et pour mettre à jour vos prévisions à l&apos;étape Prévisionnel
							</i>
							.
						</p>
						<p>
							<b>Démarrer sans historique</b>
							<br />
							Si aucune situation financière n&apos;est disponible, ou que vous ne souhaitez pas utiliser l&apos;historique comme base pour vos prévisions futures, indiquez
							simplement à quelle date commencer votre prévisionnel.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function RNCSInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "RNCSInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Le RNCS (Registre National du Commerce et des Sociétés) met à disposition publiquement les comptes d&apos;entreprises déposés de manière non confidentielle.
						<br />
						Lorsque des comptes sont disponibles et conformes comptablement, ils peuvent être utilisés comme point de départ du prévisionnel.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AccountSourceInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement={props.placement || "top"}
			overlay={
				<Popover id={(props.id || "") + "AccountSourceInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<ul>
							<li>
								<b>RNCS</b> : Il s&apos;agit des comptes annuels déposés au Registre National du Commerce et des Sociétés, centralisés et transmis par l&apos;INPI dans le cadre de
								sa mission de conservation et de diffusion. Ces données, si elles ne sont pas confidentielles, sont accessibles à tous.
							</li>
							<br />
							<li>
								<b>Autres</b> (FEC, PDF, XLSX, API) : Il s&apos;agit des comptes téléchargés sur l&apos;application. Ces données sont privées. Elles ne sont accessibles que par
								vous. Dans le cas d&apos;un accès multi-utilisateurs au dossier, où vous avez la possibilité de collaborer avec des partenaires sur le même dossier, ces données
								sont partagées uniquement avec les utilisateurs explicitement invités par le propriétaire du dossier.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AccountDepositDateInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AccountDepositDateInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>La date de dépôt correspond :</p>
						<ul>
							<li>Pour les liasses fiscales provenant du RNCS : à la date à laquelle les comptes ont été déposés au greffe</li>
							<br />
							<li>Pour les fichiers chargés ou les données synchronisées via API : à la date à laquelle ils ont été importés par l&apos;utilisateur</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AccountAccessInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "AccountAccessInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<ul>
							<li>
								<b>Public</b> : Les états financiers ont été déposés au RNCS qui les met à disposition avec accord de l&apos;entreprise. Ils sont <b>accessibles librement</b>.
							</li>
							<br />
							<li>
								<b>Confidentiel</b> : Les états financiers ont été déposés au RNCS mais l&apos;entreprise a choisi qu&apos;ils restent confidentiels. Ils ne sont donc{" "}
								<b>pas accessibles</b>.
							</li>
							<br />
							<li>
								<b>Privé</b> : Les états financiers ont été déposés sur l&apos;application. Ils ne sont <b>accessibles que par vous</b>.
								<br />
								Si vous utilisez une formule collaborative, ils sont accessibles uniquement par vous et par les utilisateurs avec qui vous partagez le dossier.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AccountStatusInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="bottom"
			overlay={
				<Popover id={(props.id || "") + "AccountStatusInfoTip"} className="infoPopover medium">
					<Popover.Body>
						La validité des comptes déposés est vérifiée par le RNCS ou par l&apos;application selon leur provenance (voir la Source). Ils peuvent être :<br />
						<br />
						<b>Utilisables sans réserve</b>
						<ul>
							<li>
								Statut <span className="statusTag active">Valide</span> : aucune anomalie détectée.
							</li>
						</ul>
						<br />
						<b>Utilisables avec risque d&apos;incohérence sur les calculs de projections</b>
						<ul>
							<li>
								Statut <span className="statusTag inactive">Incohérences</span> : comptes présentant soit une incohérence comptable, soit des données manquantes, soit des
								retaitements non équilibrés.
							</li>
						</ul>
						<br />
						<b>Non utilisables</b>
						<ul>
							<li>
								Statut <span className="statusTag inactive">Confidentiel</span> : comptes confidentiel au RNCS.
							</li>
							<li>
								Statut <span className="statusTag inactive">Non valide</span> : comptes non disponibles en raison d&apos;une erreur sur le dépôt RNCS.
							</li>
						</ul>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function PartialAccountsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			overlay={
				<Popover id={(props.id || "") + "PartialAccountsInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Une situation partielle correspond à une période de dates ne couvrant pas la totalité de l&apos;exercice comptable.</p>
						<p>Vous pouvez utiliser une telle situation par exemple pour suivre le réalisé en cours d&apos;année, ou pour l&apos;importer dans un prévisionnel.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompleteAccountsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			overlay={
				<Popover id={(props.id || "") + "CompleteAccountsInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Un exercice complet correspond à une période de dates couvrant la totalité de l&apos;exercice comptable.</p>
						<p>Vous pouvez utiliser une telle situation comme point de départ d&apos;un prévisionnel.</p>
						<p>
							<i className="bi bi-question-circle marginRight" />
							Même s&apos;il s&apos;agit d&apos;une situation de clôture provisoire (c&apos;est-à-dire d&apos;une situation de fin d&apos;année mais non encore finalisée), vous
							pouvez l&apos;utiliser comme point de départ du prévisionnel en choisissant <i>Exercice complet</i>. Vous aurez toujours la possibilité de mettre à jour cette
							situation une fois la clôture finalisée.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ScenarReadOnlyInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "ScenarReadOnlyInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Par défaut, les scénarios créés par un utilisateur sur un dossier partagé sont modifiables par les autres utilisateurs collaborant sur le dossier.
						<br />
						Toutefois avec votre accès Accompagnement, vous avez la possibilité de créer un scénario en &quot;lecture seule&quot;. Alors les autres utilisateurs collaborant sur le
						dossier pourront utiliser le scénario dans leurs simulations mais ne pourront pas le modifier.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function ScenarIsSharedInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "ScenarIsSharedInfoTip"} className="infoPopover medium">
					<Popover.Body>
						Par défaut, les scénarios créés ne seront utilisables que pour l&apos;entreprise dont le dossier est ouvert.
						<br />
						Toutefois avec votre accès Accompagnement, vous avez la possibilité de créer un scénario &quot;partagé&quot;, accessible et utilisable sur tous les dossiers
						d&apos;entreprises de votre compte.
						<br />
						<br />
						<b>Attention :</b> une fois que le scénario a été partagé, il ne peut plus être restreint à une seule entreprise, étant potentiellement utilisé par les utilisateurs
						collaborant sur vos dossiers.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CardCVCInfoTip(props) {
	return (
		<OverlayTrigger
			trigger={["hover", "click"]}
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CardCVCInfoTip"} className="infoPopover medium">
					<Popover.Body>Le cryptogramme visuel de la carte correspond au code de 3 chiffres présent au verso de votre carte, à droite dans la zone de signature.</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function SCAInfoTip(props) {
	return (
		<OverlayTrigger
			trigger={["hover", "click"]}
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "SCAInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>
							Conformément aux nouvelles règles d&apos;authentification forte des paiements (&quot;Strong Customer Authentication&quot; ou SCA) introduites par l&apos;Union
							Européenne et entrées en vigueur en septembre 2019, il pourra vous être demandé d&apos;authentifier votre carte au moyen par exemple d&apos;un code reçu par SMS ou
							d&apos;une information secrète.
						</p>
						<p>
							Vous avez toujours la possibilité de modifier ultérieurement votre carte de paiement par défaut, via le menu{" "}
							<i>Gérer votre abonnement &gt; Mettre à jour votre moyen de paiement</i>.
						</p>
						<p>
							Nous rappelons que nous ne stockons jamais les données sensibles relatives à vos moyens de paiement. Ces données sont cryptées par notre partenaire de paiement Stripe
							et nous n&apos;y avons pas accès.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export const CompanyRecordsInfoTip = withRouter(
	class CompanyRecordsInfoTip extends React.Component {
		handleToSubscribe = (event) => {
			event.preventDefault();
			this.props.history.push("/subscribe");
		};

		render() {
			return (
				<OverlayTrigger
					trigger="click"
					rootClose
					placement="bottom"
					overlay={
						<Popover id={this.props.id + "CompanyRecordsInfoTip"} className="infoPopover large">
							<Popover.Body>
								Le décompte de vos dossiers d&apos;entreprises pour la période en cours vous permet d&apos;anticiper et de comprendre votre facture en cas d&apos;ouverture
								d&apos;un nombre de dossiers d&apos;entreprises supérieur à celui prévu dans le cadre de votre abonnement.
								<br />
								<br />
								<span className="semiBold">Comment fonctionne la facturation des suppléments hors abonnement ?</span>
								<br />
								<br />
								Si vous ouvrez plus de dossiers d&apos;entreprises que votre abonnement ne le prévoit :
								<ul>
									<li>
										Vous êtes facturé en fin de mois à date anniversaire de votre abonnement, sur la base du nombre de dossiers supplémentaires ayant été actifs (c&apos;est-à-dire
										non archivé et non supprimé) à un moment dans le mois écoulé.
									</li>
									<li>Lorsque vous ajoutez un dossier supplémentaire, celui-ci est facturé pour une période minimale d’un mois.</li>
									<li>
										Si vous utilisez l&apos;archivage de dossiers, seuls les dossiers restés archivés pendant tout le mois écoulé sont facturés au tarif d&apos;un dossier archivé,
										également en fin de mois à date anniversaire.
									</li>
									<li>
										Les dossiers supplémentaires et archivés sont facturés à leur{" "}
										<button className="linkBtn" onClick={this.handleToSubscribe}>
											tarif en vigueur
										</button>
										.
									</li>
								</ul>
							</Popover.Body>
						</Popover>
					}
				>
					<span className="infotip">
						<i className="bi bi-info-circle" />
					</span>
				</OverlayTrigger>
			);
		}
	},
);

export function CompanyLogInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CompanyLogInfoTip"} className="infoPopover medium">
					<Popover.Body>
						<p>Le suivi de consommation vous permet de comprendre le nombre de dossiers utilisés sur la période de facturation.</p>
						<p>
							Chaque mois à date anniversaire de votre abonnement, les nombres de dossiers créés, actifs et archivés pendant la période sont décomptés afin d&apos;établir votre
							facture.
						</p>
						<button className="linkBtn noUnderline" onClick={props.handleToSubscribe}>
							Voir les factures
						</button>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyLogPeriodInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "CompanyLogPeriodInfoTip"} className="infoPopover medium">
					<Popover.Body>
						La période mensuelle correspond à celle sur laquelle les éventuels dossiers supplémentaires ou archivés sont facturés.
						<br />
						Elle est déterminée en fonction des dates anniversaires de votre abonnement.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function CompanyConsolidationShareInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "CompanyConsolidationShareInfoTip"} className="infoPopover small">
					<Popover.Body>
						Indiquez la part des comptes de la filiale qui seront additionnés à ceux de la maison mère.
						<br />
						En cas d&apos;intégration globale, saisissez 100%.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FrCloudInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="right"
			overlay={
				<Popover id={(props.id || "") + "FrCloudInfoTip"} className="infoPopover small">
					<Popover.Body>
						En sélectionnant cette option, l&apos;ensemble de vos données utilisateur sont hébergées sur des serveurs français, gérés par notre partenaire cloud Outscale (
						<a href="https://fr.outscale.com" target="_blank" rel="noreferrer noopener">
							https:&sol;&sol;fr.outscale.com
						</a>
						), société de droit français.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecProduitsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecProduitsInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Ce montant représente une estimation des produits d&apos;exploitation, calculée à partir des opérations renseignées dans la comptabilité à date.</p>
						<p>
							A noter que certains produits liés à des opérations d&apos;inventaire (par exemple, production stockée ou immobilisée) peuvent ne pas avoir encore été comptabilisés
							dans le cas de situations intermédiaires partielles.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecChargesInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecChargesInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Ce montant représente une estimation des charges d&apos;exploitation, calculée à partir des opérations renseignées dans la comptabilité à date.</p>
						<p>
							A noter que certaines charges liées à des opérations d&apos;inventaire (par exemple, variations de stock de marchandises et de matières premières, amortissement des
							immobilisations, provisions...) peuvent ne pas avoir encore été comptabilisées dans le cas de situations intermédiaires partielles.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecMargeInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecMargeInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Ce montant représente une estimation de la marge d&apos;exploitation, calculée à partir des opérations renseignées dans la comptabilité à date.</p>
						<p>
							A noter que certains produits et certaines charges liées à des opérations d&apos;inventaire (par exemple, variations de stock de produits, marchandises et matières
							premières, amortissement des immobilisations, provisions...) peuvent ne pas avoir encore été comptabilisés dans le cas de situations intermédiaires partielles.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecEncoursCliInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecEncoursCliInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Les créances clients correspondent au montant des factures émises auprès des clients, encore en attente d&apos;encaissement.</p>
						<p>Ce montant est ici estimé à la date de la dernière opération renseignée dans la comptabilité.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecEncoursFourInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecEncoursFourInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Les dettes fournisseurs correspondent au montant des factures reçues des fournisseurs, encore en attente de règlement.</p>
						<p>Ce montant est ici estimé à la date de la dernière opération renseignée dans la comptabilité.</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecEncoursCliFourInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecEncoursCliFourInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Le graphique Encours représente l&apos;évolution mois par mois du montant total (stock) des factures en attente de règlement de vos clients (créances) et fournisseurs
							(dettes) ainsi que l&apos;évolution des délais de paiement moyens, estimés à partir des opérations renseignées dans la comptabilité.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecDelaiCliInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecDelaiCliInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Aussi appelé DSO (Days Sales Outstanding), le délai de paiement clients correspond au délai moyen de règlement des factures clients émises et en attente
							d&apos;encaissement.
						</p>
						<p>
							Il est ici estimé selon la méthode d&apos;
							<i>épuisement de l&apos;encours</i> (<i>Roll-back</i>), à partir des opérations renseignées dans la comptabilité.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecDelaiFourInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecDelaiFourInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Aussi appelé DPO (Days Payable Outstanding), le délai de paiement fournisseurs correspond au délai moyen de règlement des factures fournisseurs reçues et en attente
							de décaissement.
						</p>
						<p>
							Il est ici estimé selon la méthode d&apos;
							<i>épuisement de l&apos;encours</i> (<i>Roll-back</i>), à partir des opérations renseignées dans la comptabilité.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecNetCashFlowInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecNetCashFlowInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							La variation de trésorerie correspond à la somme des encaissements et décaissements sur comptes bancaires et instruments de placement liquides (c&apos;est-à-dire
							disponibles immédiatement), enregistrés sur la période sélectionnée.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function FecCashAmountInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="top"
			overlay={
				<Popover id={(props.id || "") + "FecCashAmountInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>
							Le solde à date est calculé à partir de la position de départ (solde début de période) de l&apos;ensemble des comptes bancaires et instruments de placement liquides
							(c&apos;est-à-dire disponibles immédiatement) et de la variation enregistrée sur la période sélectionnée.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardTitleInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardTitleInfoTip"} className="infoPopover small">
					<Popover.Body>
						Le rapport de suivi d&apos;activité sera envoyé automatiquement après avoir réalisé une synchronisation avec les données issues de votre outil comptable.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardRecipientsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardRecipientsInfoTip"} className="infoPopover small">
					<Popover.Body>Choisissez les destinataires du rapport parmi les contributeurs du dossier, ou bien saisissez les adresses email.</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardApiVendorInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardApiVendorInfoTip"} className="infoPopover small">
					<Popover.Body>
						<p>Sélectionnez l&apos;outil auquel se connecter pour synchroniser vos données à la date définie.</p>
						<p>
							Période d&apos;import : selon la fréquence de mise à jour de la comptabilité, vous pouvez choisir d&apos;importer les données à la date du jour (adapté au cas
							d&apos;un envoi quotidien ou hebdomadaire), ou bien à la fin du mois précédent (adapté à un envoi mensuel).
							<br />
							<i>A noter que dans le cas d&apos;un import à la fin du mois précédent, celui-ci ne sera possible qu&apos;à partir du 2ème mois de l&apos;exercice.</i>
						</p>
						<p>
							Par défaut les données comptables synchronisées ne sont pas sauvegardées. Indiquez-le si vous souhaitez qu&apos;elles soient enregistrées pour une utilisation
							ultérieure dans la liste des situations financières disponibles.
						</p>
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardViewInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardViewInfoTip"} className="infoPopover small">
					<Popover.Body>
						Choisissez le tableau de bord que vous souhaitez envoyer, parmi l&apos;un des modèles pré-paramétrés, ou bien l&apos; de ceux créés sur le dossier.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardRefProjectInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardRefProjectInfoTip"} className="infoPopover small">
					<Popover.Body>Sélectionnez la version de prévisionnel qui servira de référence pour calculer les indicateurs de comparaison.</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardRefYearInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardRefYearInfoTip"} className="infoPopover small">
					<Popover.Body>
						Si des situations comptables antérieures sont disponibles, sélectionnez celle qui servira de référence pour calculer les indicateurs de comparaison.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export function AutomatedDashboardProductsInfoTip(props) {
	return (
		<OverlayTrigger
			trigger="click"
			rootClose
			placement="auto"
			overlay={
				<Popover id={(props.id || "") + "AutomatedDashboardProductsInfoTip"} className="infoPopover small">
					<Popover.Body>
						Dans le cas où la segmentation analytique est activée sur le projet de référence, vous avez également la possibilité de recevoir des rapports de suivi d&apos;activité
						distincts sur chacun des segments paramétrés.
					</Popover.Body>
				</Popover>
			}
		>
			<span className="infotip">
				<i className="bi bi-info-circle" />
			</span>
		</OverlayTrigger>
	);
}

export const ChangeContributorAccessDateInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "ChangeContributorAccessDateInfoTip"} className="infoPopover medium">
				<Popover.Body>
					Vous pouvez fixer ou modifier la date limite d&apos;accès du contributeur au dossier partagé. Utile par exemple si vous souhaitez qu&apos;au terme de la mission
					d&apos;accompagnement, le client ne puisse plus accéder gratuitement au dossier.
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const LockPDFExportInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "LockPDFExportInfoTip"} className="infoPopover medium">
				<Popover.Body>
					Par défaut seul le propriétaire du dossier peut exporter le dossier au format PDF. Vous pouvez ici autoriser ou bloquer l&apos;export pour le contributeur sélectionné.
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const SuspendContributorInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "SuspendContributorInfoTip"} className="infoPopover medium">
				<Popover.Body>
					La suspension de l&apos;accès signifie que le contributeur ne pourra temporairement plus accéder au dossier partagé. Vous pourrez réactiver son accès ultérieurement.
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const CompaniesManagementInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "CompaniesManagementInfoTip"} className="infoPopover medium">
				<Popover.Body>
					<p>Cette autorisation permet à l&apos;utilisateur de créer ses propres nouveaux dossiers d&apos;entreprises, et d&apos;éventuellement les supprimer ensuite.</p>
					<p>Si l&apos;utilisateur créé ses propres dossiers, vous y aurez accès en tant qu&apos;administrateur du compte.</p>
					<p>Si l&apos;option n&apos;est pas activée, l&apos;utilisateur pourra seulement accéder aux dossiers sur lesquels vous l&apos;inviterez en tant que contributeur.</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const FecImportLevelInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "FecImportLevelInfoTip"} className="infoPopover large">
				<Popover.Body>
					<p>
						Choisissez le niveau <b>agrégé</b> si vous souhaitez que les montants importés soient regroupés par catégories d&apos;hypothèse (par exemple : charges externes, ventes
						de produits, etc.).
					</p>
					<p>
						Le niveau <b>détaillé</b> permet à l&apos;inverse de créer des hypothèses distinctes correspondant à chaque compte comptable.
						<br />
						<u>A noter</u> : Ce mode n&apos;est disponible que lorsque le nombre total de comptes comptables différents à importer est inférieur à {props.maxNumber || 100}.
					</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

/*
export const AccountImportGrowthValueInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		// placement="top"
		placement="auto"
		overlay={
			<Popover id={(props.id || "") + "AccountImportGrowthValueInfoTip"} className="infoPopover large">
				<Popover.Body>
					<p>
						Le taux de variation saisi permet de modéliser une prévision de croissance ou de décroissance globale par rapport à la période sélectionnée.
					</p>
					<p>
						Le taux indiqué s&apos;appliquera forfaitairement aux montants de <b>chaque catégorie de produits et de charges</b>.
					</p>
					<p>
						Vous aurez ensuite la possibilité d&apos;ajuster les variations d&apos;hypothèses une fois celles-ci créées. 
					</p>
					<p>
						Si vous souhaitez conserver tels-quels les montants importés, laissez le champ vide.
					</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);
*/

export const CashFlowOrAccountedViewInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="top"
		overlay={
			<Popover id={(props.id || "") + "CashFlowOrAccountedViewInfoTip"} className="infoPopover medium">
				<Popover.Body>
					<p>
						La <b>vision trésorerie</b> affiche les montants TTC encaissés (ou décaissés), tenant compte des délais de paiement définis.
					</p>
					<p>
						La <b>vision comptable</b> correspond aux produits / charges HT enregistrés en compte de résultat à la date de facturation.
					</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const ItemTagInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="auto"
		overlay={
			<Popover id={(props.id || "") + "ItemTagInfoTip"} className="infoPopover medium">
				<Popover.Body>
					<p>Vous pouvez ajouter un ou plusieurs tags à votre hypothèse.</p>
					<p>
						Les tags sont utiles par exemple pour filter une liste d&apos;hypothèses d&apos;une même catégorie, ou pour faire référence à certaines hypothèses dans des indicateurs
						personnalisés.
					</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);

export const AnalyticalApiInfoTip = (props) => (
	<OverlayTrigger
		trigger="click"
		rootClose
		placement="auto"
		overlay={
			<Popover id={(props.id || "") + "AnalyticalApiInfoTip"} className="infoPopover medium">
				<Popover.Body>
					<p>
						Lorsque vos opérations comportent une ventilation analytique en comptabilité, indiquez si vous souhaitez intégrer cette segmentation aux écritures 
						importées, de sorte à l&apos;utiliser dans vos prévisionnels et tableaux de bord.
					</p>
				</Popover.Body>
			</Popover>
		}
	>
		<span className="infotip">
			<i className="bi bi-info-circle" />
		</span>
	</OverlayTrigger>
);
