import React, {Component, useState, useCallback, useEffect, useRef} from "react";

import "../App.css";
import "./EditableText.css";

export class EditableTextArea extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: typeof this.props.defaultValue !== "undefined" && this.props.defaultValue !== null ? this.props.defaultValue : "",
			textAreaHeight: 34,
		};

		this.textAreaDOMNode = null;

		this.propagChangeTimeout = null;
	}

	componentDidMount() {
		if (this.state.textAreaHeight !== this.textAreaDOMNode.scrollHeight) {
			this.setState({textAreaHeight: this.textAreaDOMNode.scrollHeight + 2});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.textAreaHeight > 0 && this.state.textAreaHeight === 0) {
			this.setState({textAreaHeight: this.textAreaDOMNode.scrollHeight + 2});
		}
	}

	handleChange = (event) => {
		if (this.props.readOnly !== true) {
			const newVal = event.target.value;

			if (typeof this.props.maxCharacters === "undefined" || newVal.length <= this.props.maxCharacters) {
				if (typeof this.props.maxLines === "undefined" || newVal.split("\n").length <= this.props.maxLines) {
					this.setState({value: newVal, textAreaHeight: 0});

					if (this.propagChangeTimeout !== null) {
						clearTimeout(this.propagChangeTimeout);
					}

					this.propagChangeTimeout = setTimeout(() => this.propagChange(newVal), typeof this.props.changeEventDelay !== "undefined" ? this.props.changeEventDelay : 400);
				}
			}
		}
	};

	propagChange = (newVal) => {
		this.propagChangeTimeout = null;
		if (typeof this.props.onChange !== "undefined") this.props.onChange(newVal);
	};

	render() {
		const textAreaStyle = {height: this.state.textAreaHeight};
		const className = typeof this.props.className !== "undefined" ? this.props.className : "";

		return this.props.staticRender === true ? (
			<div className={className}>
				{this.state.value === null || this.state.value === ""
					? this.props.staticRenderEmptyFallback
					: this.state.value.split("\n").map((item, key) => (
							<React.Fragment key={key}>
								{item}
								<br />
							</React.Fragment>
						))}
			</div>
		) : (
			<div className={className}>
				<textarea
					className={
						"editableTextArea " + (typeof this.props.inputClassName !== "undefined" ? this.props.inputClassName : "") + (this.state.value === "" ? " placeholderStyle" : "")
					}
					readOnly={this.props.readOnly === true}
					style={textAreaStyle}
					placeholder={this.props.placeholder || ""}
					value={this.state.value}
					onChange={this.handleChange}
					ref={(node) => {
						this.textAreaDOMNode = node;
					}}
				/>
				{typeof this.props.maxCharacters !== "undefined" &&
					(typeof this.props.displayNbCharHelper === "undefined" || this.props.displayNbCharHelper === true) &&
					this.props.readOnly !== true && (
						<div className="maxCharacters">
							<div>
								{this.state.value.length + " / " + this.props.maxCharacters + " caractères"}
								{typeof this.props.maxLines !== "undefined" && (
									<React.Fragment>
										<br />
										{this.state.value.split("\n").length + " / " + this.props.maxLines + " lignes"}
									</React.Fragment>
								)}
							</div>
						</div>
					)}
			</div>
		);
	}
}

export class EditableTextField extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: typeof this.props.defaultValue !== "undefined" && this.props.defaultValue !== null ? this.props.defaultValue : "",
		};

		this.propagChangeTimeout = null;
	}

	handleChange = (event) => {
		if (this.props.readOnly !== true) {
			const newVal = event.target.value;

			if (typeof this.props.maxCharacters === "undefined" || newVal.length <= this.props.maxCharacters || newVal.length < this.state.value.length) {
				if (typeof this.props.validationRegExp === "undefined" || this.props.validationRegExp(newVal)) {
					const cleanedVal = typeof this.props.cleaningFunction !== "undefined" ? this.props.cleaningFunction(newVal) : newVal;
					this.setState({value: cleanedVal});

					if (this.propagChangeTimeout !== null) {
						clearTimeout(this.propagChangeTimeout);
					}

					this.propagChangeTimeout = setTimeout(() => this.propagChange(cleanedVal), typeof this.props.changeEventDelay !== "undefined" ? this.props.changeEventDelay : 400);
				}
			}
		}
	};

	propagChange = (newVal) => {
		this.propagChangeTimeout = null;
		if (typeof this.props.onChange !== "undefined") this.props.onChange(newVal);
	};

	render() {
		return this.props.staticRender === true ? (
			<span>{this.state.value}</span>
		) : (
			<React.Fragment>
				<input
					type="text"
					readOnly={this.props.readOnly === true}
					className={"editableTextField " + (typeof this.props.inputClassName !== "undefined" ? this.props.inputClassName : "")}
					placeholder={this.props.placeholder || ""}
					value={this.state.value}
					onChange={this.handleChange}
				/>
				{this.props.displayNbCharHelper && typeof this.props.maxCharacters !== "undefined" && this.props.readOnly !== true && (
					<div className="maxCharacters">
						<div>{this.state.value.length + " / " + this.props.maxCharacters + " caractères"}</div>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export function EditableTextArea2 (props) {
	
	const {
		value: propsValue,
		readOnly,
		maxCharacters,
		maxLines,
		onChange,
		changeEventDelay,
	} = props;
	
	const [value, setValue] = useState(typeof propsValue !== "undefined" && propsValue !== null ? propsValue : "");
	const [textAreaHeight, setTextAreaHeight] = useState(34);
	
	const propagChangeTimeout = useRef(null);
	const textAreaDOMNode = useRef(null);
	
	const propagChange = useCallback((newVal) => {
		propagChangeTimeout.current = null;
		if (typeof onChange !== "undefined") onChange(newVal);
	}, [onChange]);

	const handleChange = useCallback((event) => {
		if (readOnly !== true) {
			const newVal = event.target.value;

			if (typeof maxCharacters === "undefined" || newVal.length <= maxCharacters || newVal.length < value.length) {
				if (typeof maxLines === "undefined" || newVal.split("\n").length <= maxLines) {
					setValue(newVal);

					if (propagChangeTimeout.current !== null) {
						clearTimeout(propagChangeTimeout.current);
					}

					propagChangeTimeout.current = setTimeout(() => propagChange(newVal), typeof changeEventDelay !== "undefined" ? changeEventDelay : 400);
				}
			}
		}
	}, [
		readOnly,
		value,
		maxCharacters,
		maxLines,
		propagChange,
		changeEventDelay,
	]);
	
	const handleFocus = useCallback(() => {
		setTextAreaHeight(textAreaDOMNode.current.scrollHeight);
	}, []);
	
	const handleBlur = useCallback(() => {
		// setTextAreaHeight(34);
	}, []);
	
	useEffect(() => {
		setValue(typeof propsValue !== "undefined" && propsValue !== null ? propsValue : "");
	}, [propsValue]);
	
	/*
	useEffect(() => {
		if (textAreaHeight !== textAreaDOMNode.current.scrollHeight) {
			setTextAreaHeight(textAreaDOMNode.current.scrollHeight + 2);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	*/
	useEffect(() => {
		if (textAreaHeight < textAreaDOMNode.current.scrollHeight) {
			setTextAreaHeight(textAreaDOMNode.current.scrollHeight);
		}
	}, [value]); // eslint-disable-line react-hooks/exhaustive-deps
	
	useEffect(() => {
		if (textAreaHeight === 0) {
			// setTextAreaHeight(textAreaDOMNode.current.scrollHeight + 2);
			setTextAreaHeight(textAreaDOMNode.current.scrollHeight);
		}
	}, [textAreaHeight]);
	
// console.log(textAreaHeight);
	return (
		<div className={props.className || ""}>
			<textarea
				className={
					"editableTextArea " + (props.inputClassName || "") + (value === "" ? " placeholderStyle" : "")
				}
				readOnly={readOnly === true}
				style={{height: textAreaHeight + "px"}}
				placeholder={props.placeholder || ""}
				value={value}
				onChange={handleChange}
				ref={textAreaDOMNode}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			{typeof props.maxCharacters !== "undefined" &&
				(typeof props.displayNbCharHelper === "undefined" || props.displayNbCharHelper === true) &&
				props.readOnly !== true && (
					<div className="maxCharacters">
						<div>
							{value.length + " / " + props.maxCharacters + " caractères"}
							{typeof props.maxLines !== "undefined" && (
								<React.Fragment>
									<br />
									{value.split("\n").length + " / " + props.maxLines + " lignes"}
								</React.Fragment>
							)}
						</div>
					</div>
				)}
		</div>
	);
}

export function EditableTextField2 (props) {
	
	const {
		value: propsValue,
		readOnly,
		maxCharacters,
		validationRegExp,
		cleaningFunction,
		onChange,
		changeEventDelay,
	} = props;
	
	const [value, setValue] = useState(typeof propsValue !== "undefined" && propsValue !== null ? propsValue : "");
	
	const propagChangeTimeout = useRef(null);
	
	const propagChange = useCallback((newVal) => {
		propagChangeTimeout.current = null;
		if (typeof onChange !== "undefined") onChange(newVal);
	}, [onChange]);

	const handleChange = useCallback((event) => {
		if (readOnly !== true) {
			const newVal = event.target.value;

			if (typeof maxCharacters === "undefined" || newVal.length <= maxCharacters || newVal.length < value.length) {
				if (typeof validationRegExp === "undefined" || validationRegExp(newVal)) {
					const cleanedVal = typeof cleaningFunction !== "undefined" ? cleaningFunction(newVal) : newVal;
					setValue(cleanedVal);

					if (propagChangeTimeout.current !== null) {
						clearTimeout(propagChangeTimeout.current);
					}

					propagChangeTimeout.current = setTimeout(() => propagChange(cleanedVal), typeof changeEventDelay !== "undefined" ? changeEventDelay : 400);
				}
			}
		}
	}, [
		readOnly,
		value,
		maxCharacters,
		validationRegExp,
		cleaningFunction,
		propagChange,
		changeEventDelay,
	]);
	
	useEffect(() => {
		setValue(typeof propsValue !== "undefined" && propsValue !== null ? propsValue : "");
	}, [propsValue]);
	
	return (
		<div className={"editableTextField2 " + (props.className || "")}>
			<input
				type="text"
				readOnly={readOnly === true}
				className={props.inputClassName || ""}
				placeholder={props.placeholder || ""}
				value={value}
				onChange={handleChange}
			/>
			{props.displayNbCharHelper && typeof props.maxCharacters !== "undefined" && props.readOnly !== true && (
				<div className="maxCharacters">
					<div>{value.length + " / " + props.maxCharacters + " caractères"}</div>
				</div>
			)}
		</div>
	);
}
