import React from "react";
import axios from "axios";
import "core-js/features/array/splice";
import "core-js/features/array/concat";
import ReactDOMServer from "react-dom/server";
import {OverlayTrigger, Popover} from "react-bootstrap";
import dayjs from "./dayjs-extended";
import {monthsLowerLong} from "./misc-parameters";

export const cloneObject = (obj) => {
	if (obj === null) return null;
	const clone = Array.isArray(obj) ? [] : {};
	for (let i in obj) {
		if (obj[i] !== null && typeof obj[i] === "object") clone[i] = cloneObject(obj[i]);
		else clone[i] = obj[i];
	}
	return clone;
};

export const flattenObject = (obj, parent, result = {}) => {
	for (let key in obj) {
		let propName = parent ? parent + "." + key : key;
		if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
			flattenObject(obj[key], propName, result);
		} else {
			result[propName] = obj[key];
		}
	}
	return result;
};

export const formatNumberLocaleString = (num, precision, fixedDecimalScale) => {
	if (typeof num === "undefined" || num === null || isNaN(num)) return "-";

	if (typeof precision !== "undefined") {
		num = Math.round(num * 10 ** precision) / 10 ** precision;
	}
	var parts = num.toString().split(".");
	if (fixedDecimalScale === true) {
		if (parts.length === 2) {
			while (parts[1].length < precision) {
				parts[1] += "0";
			}
		}
	}
	return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (parts[1] ? "," + parts[1] : "");
};

export const validateEmail = (email) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

export const validatePhone = (phone) => {
	const re = /^[+\d\s]+$/;
	return re.test(phone);
};

export const passwordTestRE =
	// 1 chiffre
	// 1 min
	// 1 maj
	// 1 car spécial
	/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/;

const policyCheckPasswordInitError = (password) => password && password.length > 0 && !passwordTestRE.test(password);

export const passwordPolicyCheckMsg = (password) =>
	policyCheckPasswordInitError(password) ? (
		<span className="inputError">
			La sécurité du mot de passe est insuffisante
			<OverlayTrigger
				trigger="click"
				rootClose
				placement="bottom"
				overlay={
					<Popover id="pwdPolicyInfoTip" className={"infoPopover inputErrorPopover" + (window.appName === "evaltonbiz" ? " brandPopover" : "")}>
						<Popover.Body>
							Le mot de passe doit contenir au moins :
							<ul>
								<li>8 caractères</li>
								<li>1 chiffre</li>
								<li>1 lettre minuscule</li>
								<li>1 lettre majuscule</li>
								<li>1 caractère spécial</li>
							</ul>
						</Popover.Body>
					</Popover>
				}
			>
				<span className="infotip">
					<i className="bi bi-info-circle" />
				</span>
			</OverlayTrigger>
		</span>
	) : null;

export const addEvent = (obj, evt, fn) => {
	if (obj.addEventListener) {
		obj.addEventListener(evt, fn, false);
	} else if (obj.attachEvent) {
		obj.attachEvent("on" + evt, fn);
	}
};

export const removeEvent = (obj, evt, fn) => {
	if (obj.removeEventListener) {
		obj.removeEventListener(evt, fn, false);
	} else if (obj.detachEvent) {
		obj.detachEvent("on" + evt, fn);
	}
};

export const getUserIPInfo = () => {
	return axios
		.get("https://api.ipgeolocation.io/ipgeo?apiKey=f0efc0147106443ca396858a16151090")
		.then((resp) => {
			// console.log(resp);
			if (resp && resp.data) {
				return resp.data;
			} else {
				return null;
			}
		})
		.catch((error) => {
			console.log(error);
			return null;
		});
};

export const getSum = (total, val) => total + (val !== null ? val : 0);

export const getConcat = (total, curr) => (curr !== null ? total.concat(curr) : total);

export const arrayShift = (arr, n) => {
	const arr2 = arr.slice();
	return arr2.concat(arr2.splice(0, n));
};

export const sumArrays = (a, b) => {
	if (!a && !b) return null;
	if (!a) return b;
	if (!b) return a;
	const length = Math.max(a.length, b.length);
	const result = Array(length).fill(0);
	for (let i = 0; i < length; i++) {
		result[i] = (typeof a[i] !== "undefined" ? a[i] : 0) + (typeof b[i] !== "undefined" ? b[i] : 0);
	}
	return result;
};

/*
export const filterReactTableTextColumn = (filter, row, column) => {
// console.log(filter, row, column);
	if (typeof filter.id !== "undefined" && typeof row[filter.id] !== "undefined") {
		const re = new RegExp(".*" + filter.value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + ".*", "i");
		return re.test(row[filter.id]);
	}
	else return true;
};
*/

export const filterReactTableTextColumn2 = (rows, colIds, filterValue) => {
	// console.log(rows, colIds, filterValue);
	const re = new RegExp(".*" + filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + ".*", "i");
	// return rows.filter(row => colIds.some(colId => re.test(row.values[colId])));
	return rows.filter((row) => colIds.some((colId) => re.test(row.original[colId])));
};

/*
export const filterReactTableExactTextColumn = (filter, row, column) => {
// console.log(filter, row, column);
	if (typeof filter.id !== "undefined" && typeof row[filter.id] !== "undefined") {
		const re = new RegExp("^" + filter.value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + ".*", "i");
		return re.test(row[filter.id]);
	}
	else return true;
};
*/

export const filterReactTableExactTextColumn2 = (rows, colIds, filterValue) => {
	// console.log(filter, row, column);
	const re = new RegExp("^" + filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + ".*", "i");
	return rows.filter((row) => colIds.some((colId) => re.test(row.values[colId])));
};

/*
export const filterDashboardUserColumn = (filter, row, column) => {
	const cellString = (row._original ? row._original.name + row._original.email : "");
	const re = new RegExp(".*" + filter.value.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&') + ".*", "i");
	return re.test(cellString);
};
*/

export const filterDashboardUserColumn2 = (rows, colIds, filterValue) => {
	const re = new RegExp(".*" + filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + ".*", "i");
	return rows.filter((row) => {
		const cellString = row.original ? row.original.name + row.original.email : "";
		return re.test(cellString);
	});
};

/*
export const sortReactTableDateColumns = (a, b, id) => {
// console.log(a, b, id);
	
	const ma = (a === null || a === undefined || a === 'nd' || a === '') ? moment(0) : moment(a, "DD/MM/YYYY");
	const mb = (b === null || b === undefined || b === 'nd' || b === '') ? moment(0) : moment(b, "DD/MM/YYYY");
	
	if (ma.isAfter(mb)) return 1;
	if (ma.isBefore(mb)) return -1;
	return 0;
};
*/

export const sortReactTableDateColumns2 = (rowA, rowB, colId) => {
	const a = rowA.values[colId];
	const b = rowB.values[colId];

	const ma = a === null || a === undefined || a === "nd" || a === "" ? dayjs(0) : dayjs(a, "DD/MM/YYYY");
	const mb = b === null || b === undefined || b === "nd" || b === "" ? dayjs(0) : dayjs(b, "DD/MM/YYYY");

	if (ma.isAfter(mb)) return 1;
	if (ma.isBefore(mb)) return -1;
	return 0;
};

/*
export const sortReactTableDateTimeColumns = (a, b) => {
	
	const ma = (a === null || a === undefined || a === 'nd') ? moment(0) : moment(a, "DD/MM/YYYY - HH:mm");
	const mb = (b === null || b === undefined || b === 'nd') ? moment(0) : moment(b, "DD/MM/YYYY - HH:mm");
	
	if (ma.isAfter(mb)) return 1;
	if (ma.isBefore(mb)) return -1;
	return 0;
};
*/

export const sortReactTableDateHourString = (rowA, rowB, colId) => {
	// console.log(rowA, rowB, colId);
	// const a = rowA.values[colId];
	// const b = rowB.values[colId];
	const a = rowA.original[colId];
	const b = rowB.original[colId];
	// const ma = a === null || a === undefined || a === "nd" || a === "" ? moment(0) : moment(a, "DD/MM/YYYY HH:mm");
	// const mb = b === null || b === undefined || b === "nd" || b === "" ? moment(0) : moment(b, "DD/MM/YYYY HH:mm");
	const ma = a === null || a === undefined || a === "nd" || a === "" ? dayjs(0) : dayjs(a);
	const mb = b === null || b === undefined || b === "nd" || b === "" ? dayjs(0) : dayjs(b);
	if (ma.isAfter(mb)) return 1;
	if (ma.isBefore(mb)) return -1;
	return 0;
};

// const sortContributorNames = (a,b) => {
export const sortContributorNames = (rowA, rowB, colId) => {
	// const aHtml = ReactDOMServer.renderToStaticMarkup(a);
	// const bHtml = ReactDOMServer.renderToStaticMarkup(b);
	const aHtml = ReactDOMServer.renderToStaticMarkup(rowA.values[colId]);
	const bHtml = ReactDOMServer.renderToStaticMarkup(rowB.values[colId]);
	if (aHtml > bHtml) return 1;
	else if (bHtml > aHtml) return -1;
	else return 0;
};

export const sortAccountCodes = (rowA, rowB, colId) => {
	if (rowA.values[colId] > rowB.values[colId]) return 1;
	else if (rowB.values[colId] > rowA.values[colId]) return -1;
	else return 0;
};

export const sortAlpha = (a, b) => {
	if (!a || !b) return 0;
	return a.localeCompare(b);
};

export const sortReactTableAlpha = (rowA, rowB, colId) => {
	if (typeof rowA.original[colId] === "undefined" || rowA.original[colId] === null || typeof rowB.original[colId] === "undefined" || rowB.original[colId] === null) return 0;
	return rowA.original[colId].toString().localeCompare(rowB.original[colId].toString());
};

export const sortReactTableNumberColumn2 = (rowA, rowB, colId) => {
	var na = Number(rowA.values[colId].replace(/,/, ".").replace(/[BnsMdk%j€\s]*/g, ""));
	var nb = Number(rowB.values[colId].replace(/,/, ".").replace(/[BnsMdk%j€\s]*/g, ""));
	if (isNaN(na)) na = 0;
	if (isNaN(nb)) nb = 0;
	return na - nb;
};

export const sortConsolidationShare = (rowA, rowB, colId) => {
	return rowA.original[colId] - rowB.original[colId];
};

// export const filterReactTableMinMaxAmountColumn = (filter, row, column) => {
export const filterReactTableMinMaxAmountColumn = (propName) => (rows, colIds, filterValue) => {
	// console.log(filterValue);
	if (filterValue) {
		return rows.filter((row) => {
			const amount = row.original[propName];
			if (typeof filterValue[0] !== "undefined" && filterValue[0] !== "" && !isNaN(Number(filterValue[0])) && amount < Number(filterValue[0])) return false;
			if (typeof filterValue[1] !== "undefined" && filterValue[1] !== "" && !isNaN(Number(filterValue[1])) && amount > Number(filterValue[1])) return false;
			return true;
		});
	}
	return rows;
};

export const filterCompanyName = (rows, colIds, filterValue) => {
	const re = new RegExp(".*" + filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + ".*", "i");
	return rows.filter((row) => {
		const cellString = row.original
			? (row.original.company_name ? row.original.company_name : row.original.companyName) +
				(testIsNewCompany(row.original.siren) ? "" : row.original.siren) +
				// (row.original.parentCompany ? row.original.parentCompany.companyName + (testIsNewCompany(row.original.parentCompany.siren) ? "" : row.original.parentCompany.siren) : "") +
				(row.original.parentCompanies ? (
					row.original.parentCompanies.map(parentCompany => parentCompany.companyName + (testIsNewCompany(parentCompany.siren) ? "" : parentCompany.siren)).join("")
				) : "") +
				(row.original.subsidiaries ? row.original.subsidiaries.map(({siren, companyName}) => companyName + (testIsNewCompany(siren) ? "" : siren)).join("") : "")
			: "";
		return re.test(cellString);
	});
};

export const filterComponentText = (rows, colIds, filterValue) => {
	// console.log(rows);
	// console.log(colIds);
	// console.log(filterValue);

	const re = new RegExp(".*" + filterValue.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + ".*", "i");
	return rows.filter((row) => {
		const cellString = row.original ? colIds.map((colId) => row.original[colId] || "").join("") : "";
		return re.test(cellString);
	});
};

export const filterComponentSelector = (rows, colIds, filterValue) => {
	if (typeof filterValue !== "undefined" && colIds && colIds[0]) {
		if (filterValue === null) return rows;

		return rows.filter((row) => {
			if (row.original) {
				return row.original[colIds[0]] === filterValue;
			} else return false;
		});
	} else return rows;
};

export const filterMonthYearColumn = (columnId, rows, colIds, filterValue) => {
	if (typeof filterValue !== "undefined") {
		return rows.filter((row) => {
			if (typeof row.original[columnId] !== "undefined" && row.original[columnId] !== null) {
				// const fcd = dayjs(row.original.fiscalClosingDate);
				const fcd = dayjs(row.original[columnId]);
				if (typeof filterValue[0] !== "undefined" && filterValue[0] !== "" && !isNaN(Number(filterValue[0])) && fcd.month() !== Number(filterValue[0])) return false;
				if (typeof filterValue[1] !== "undefined" && filterValue[1] !== "" && !isNaN(Number(filterValue[1])) && fcd.year() !== Number(filterValue[1])) return false;
				return true;
			}
			return true;
		});
	} else return rows;
};

export const testIsNewCompany = (siren) => (siren ? siren.substring(0, 6) === "NewCo-" : false);

export const dispatchWindowResize = () => {
	if (typeof Event === "function") {
		// modern browsers
		window.dispatchEvent(new Event("resize"));
	} else {
		// for IE and other old browsers
		// causes deprecation warning on modern browsers
		var evt = window.document.createEvent("UIEvents");
		evt.initUIEvent("resize", true, false, window, 0);
		window.dispatchEvent(evt);
	}
};

export const navToExternalLink = (event) => {
	event.preventDefault();
	window.open(event.currentTarget.getAttribute("href"), "_blank");
};

export const base64ToBytesArray = (encoded) => {
	const decodedString = atob(encoded);
	const length = decodedString.length;
	const buffer = new ArrayBuffer(length);
	const array = new Uint8Array(buffer);
	for (let i = 0; i < length; i++) {
		array[i] = decodedString.charCodeAt(i);
	}
	return array;
};

export const dateToLitteralDate = (date, withYear) => {
	const moment = dayjs.isDayjs(date) || date instanceof Date ? dayjs(date) : dayjs(date, "YYYY-MM-DD");
	const day = moment.date();
	return day + (day === 1 ? "er" : "") + " " + monthsLowerLong[moment.month()] + (withYear ? " " + moment.year() : "");
};

export const dateToLitteralMonth = (date) => {
	const moment = dayjs.isDayjs(date) || date instanceof Date ? dayjs(date) : dayjs(date, "YYYY-MM-DD");
	return monthsLowerLong[moment.month()] + " " + moment.year();
};

export const dateToMMMMYYYY = (date) => {
	const moment = dayjs.isDayjs(date) || date instanceof Date ? dayjs(date) : dayjs(date, "YYYY-MM-DD");
	return monthsLowerLong[moment.month()] + " " + moment.year();
};

export const toUpperCaseFirstLetter = (str) => (str && str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : "");

export const isVowel = (ch) => {
	ch = ch.toLowerCase();
	return ch === "a" || ch === "e" || ch === "i" || ch === "o" || ch === "u";
};

export const excelRawTextToNumericDataArray = (rawText) => {
	
	var values = [];
	
	if (rawText) {
		
		const rows = rawText.replace(/\r\n$/, "").replace(/\r$/, "").replace(/\n$/, "").split(/\r\n|\r|\n/g);
		
		rows.forEach(row => {
			const cols = row.split("\t").map(val => {
				const num = parseFloat(val.replace(/,/g, "."));
				if (!isNaN(num) && isFinite(num)) return num;
				else return 0;
			});
			values = values.concat(cols);
		});
	}
	
	return values;
};

export const getExcelClipboardNumericData = (event) => {
	
	if (event && event.clipboardData) {
		return excelRawTextToNumericDataArray(event.clipboardData.getData("Text"));
	}
	else return [];
};
