import {useEffect} from "react";
import config from "../config.js";
import axios from "axios";

export default function SSOCallback (props) {
	
	const {history, initUserAuthData, initUserAppData, location} = props;
	
	// const [loading, setLoading] = useState(true);
	// const [email, setEmail] = useState(null);
	
	useEffect(() => {
		
// console.log(document.cookie);
		
		const queryParams = new URLSearchParams(location.search);
		
		if (queryParams.has("idp")) {
			
			const idp = queryParams.get("idp");
			
			const authBaseUrl = config.auth.server[window.serverName];

			axios.get(
				authBaseUrl + "/saml/" + idp + "/sso/check", 
				{
					withCredentials: true,
					// headers: {
						// appname: window.appName,
					// },
				}
			)
			.then((response) => {
				// console.log(response);
				
				if (response && response.data.success) {
					// setEmail(response.data.user.email);
					initUserAuthData(response.data)
					.then(() => initUserAppData(response.data))
					.then(() => {
						history.push("/login");
					});
				}
				else {
					history.push("/login");
				}
				
				// setLoading(false);
			})
			.catch((error) => {
				console.log(error);
				// setLoading(false);
				history.push("/login");
			});
		}
		else {
			history.push("/login");
		}
		
		
		// history.push("/login", {
			// processSSOConnection(
		// });
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	
	return (
		// <p>{loading ? "Chargement..." : "Salut, je suis " + (email ? email : "non authentifié")}</p>
		null
	);
}

