import React from "react";
import $ from "jquery";
import {formatNumberLocaleString} from "../../components/utils";
// import iconStarEmpty from '../../images/icons/star-empty.svg';
// import iconStarFull from '../../images/icons/star-full.svg';
// import iconFeedback from '../../images/icons/feedback2.svg';
import iconCoutsMarch from "../../images/icons/bpitem_CoutsMarch.svg";
import iconCoutsMatPrem from "../../images/icons/bpitem_CoutsMatPrem.svg";
import iconCoutsPerso from "../../images/icons/bpitem_CoutsPerso.svg";
import iconCoutsExt from "../../images/icons/bpitem_CoutsExt.svg";
import iconCoutsExploit from "../../images/icons/bpitem_CoutsExploit.svg";
import iconRevSect from "../../images/icons/bpitem_RevSect.svg";
import iconRevNego from "../../images/icons/bpitem_RevNego.svg";
import iconRevProd from "../../images/icons/bpitem_RevProd.svg";
import iconRevServ from "../../images/icons/bpitem_RevServ.svg";
import iconRevExploit from "../../images/icons/bpitem_RevExploit.svg";
import iconFinDetCT from "../../images/icons/bpitem_FinDetCT.svg";
// import iconDelaisCli from '../../images/icons/bpitem_DelaisCli.svg';
import iconDelaiPaie from "../../images/icons/delai_paiement.svg";
// import iconDelaisFour from '../../images/icons/bpitem_DelaisFour.svg';
import iconDelaiLivr from "../../images/icons/delai_livraison.svg";
import iconTxMargeNego from "../../images/icons/taux_marge_negoce.svg";
import iconTxMargeProd from "../../images/icons/taux_marge_prod.svg";
import {CoutsPersoAmountInfoTip, DebtInterestRateInputInfoTip, InvestInterestRateInputInfoTip} from "../../components/InfoTips";
// import versionConfig from "../../version-config";
// import {defaultAppStyles} from "../../components/AppThemeManager";

export const sharedContent = {
	/*
	projectColors: [
		["#FF6700", "#FFFFFF", "light"],
		["#FE9100", "#FFFFFF", "light"],
		["#FDBA00", "#FFFFFF", "light"],
		["#C9EC42", defaultAppStyles["-color-text-dark-grey"], "dark"],
		["#82DB42", "#FFFFFF", "light"],
		["#4CD646", "#FFFFFF", "light"],
		["#48CC74", "#FFFFFF", "light"],
		["#45D3AA", "#FFFFFF", "light"],
		["#41E5F4", defaultAppStyles["-color-text-dark-grey"], "dark"],
		["#4AA3F0", "#FFFFFF", "light"],
		["#6478E3", "#FFFFFF", "light"],
		["#7B62D4", "#FFFFFF", "light"],
		["#A968D6", "#FFFFFF", "light"],
		["#EB154E", "#FFFFFF", "light"],
		["#F15079", "#FFFFFF", "light"],
		["#F78DA8", "#FFFFFF", "light"],
	],
	defaultProjectColorIndexes: [
		// 8,
		// 6,
		// 5,
		// 2,
		// 10,
		// 13,
		// 7,
		// 3,
		// 11,
		// 14,
		// 15,
		// 1,
		// 9,
		// 4,
		// 12,
		// 0,
		10, 6, 15, 8, 4, 0, 12, 5, 13, 9, 1, 7, 11, 2, 14, 3,
	],
	*/

	listBPItemTypes: (unitVariablesInput) =>
		unitVariablesInput
			? [
					"InvCorp",
					"InvIncorp",
					"InvFin",
					"CoutsMarch",
					"CoutsMatPrem",
					"CoutsPerso",
					"CoutsExt",
					"CoutsTax",
					"CoutsExploit",
					"CoutsFin",
					"CoutsExcep",
					"RevNego",
					"RevProd",
					"RevServ",
					"RevExploit",
					"RevFin",
					"RevExcep",
					"FinKP",
					"FinCCAsso",
					"FinDetLT",
					"FinSubvExploit",
					"FinSubvInv",
					"FinDetCT",
					"CoutsProvExploit",
					"FinCrImpot",
					"FinExImpot",
					"CoutsDivid",
					"UnitVariable",
				]
			: [
					"InvCorp",
					"InvIncorp",
					"InvFin",
					"CoutsMarch",
					"CoutsMatPrem",
					"CoutsPerso",
					"CoutsExt",
					"CoutsTax",
					"CoutsExploit",
					"CoutsFin",
					"CoutsExcep",
					"RevNego",
					"RevProd",
					"RevServ",
					"RevExploit",
					"RevFin",
					"RevExcep",
					"FinKP",
					"FinCCAsso",
					"FinDetLT",
					"FinSubvExploit",
					"FinSubvInv",
					"FinDetCT",
					"CoutsProvExploit",
					"FinCrImpot",
					"FinExImpot",
					"CoutsDivid",
				],

	/*
	listBPIconSvgs : {
		InvIncorp: "brain-inside-human-head.svg",
		InvCorp: "robotic.svg",
		InvFin: "bank-building.svg",
		CoutsMarch: "box.svg",
		CoutsMatPrem: "matieres_premieres.svg",
		CoutsPerso: "worker-silhouette.svg",
		CoutsExt: "couts_externes.svg",
		CoutsExploit: "exploitation.svg",
		CoutsTax: "tax.svg",
		CoutsFin: "bank-building.svg",
		CoutsProvExploit: "provisions.svg",
		CoutsExcep: "charge-exceptionnelle.svg",
		RevSect: "CA_secteur.svg",
		RevNego: "negoce.svg",
		RevProd: "production.svg",
		RevServ: "CA_service.svg",
		RevExploit: "exploitation.svg",
		RevFin: "bank-building.svg",
		RevExcep: "produit-exceptionnel.svg",
		FinKP: "piggy-bank.svg",
		FinCCAsso: "compte-courant-associe.svg",
		FinDetCT: "dette_CT.svg",
		FinDetLT: "dette_LT.svg",
		FinSubvExploit: "subvention_exploitation2.svg",
		FinSubvInv: "subvention.svg",
		FinCrImpot: "credit-impot.svg",
		FinExImpot: "exoneration-impot.svg",
		CreancesCli: "delai_client.svg",
		DettesFour: "delai_fournisseur.svg",
		DelaisCli: "delai_client.svg",
		DelaisFour: "delai_fournisseur.svg",
		CoutsDivid: "dividendes.svg",
	},
	*/

	get listBPIcons() {
		const list = {};
		this.listBPItemTypes(true).forEach((item) => {
			// if (item && this.listBPIconSvgs[item]) list[item] = require("../../images/icons/" + this.listBPIconSvgs[item]);
			// if (item) list[item] = require("../../images/icons/bpitem_" + item + ".svg").default;
			if (item) list[item] = require("../../images/icons/bpitem_" + item + ".svg");
		});
		return list;
	},

	getBPItemIconSvgStrings(items) {
		items = items || this.listBPItemTypes(true);

		if (items.length === 0) return Promise.resolve({});

		const svgPromises = [];
		items.forEach((item) => {
			// if (item) svgPromises.push($.get(require("../../images/icons/bpitem_" + item + ".svg").default, null, null, "text"));
			if (item) svgPromises.push($.get(require("../../images/icons/bpitem_" + item + ".svg"), null, null, "text"));
		});

		return $.when(...svgPromises).then((...svgOutputs) => {
			// console.log(svgOutputs);

			if (items.length === 1) svgOutputs = [svgOutputs];

			const iconSvgStrings = {};
			svgOutputs.forEach((svgOutput, idx) => {
				iconSvgStrings[items[idx]] = svgOutput[1] === "success" ? svgOutput[0] : "";
			});

			return iconSvgStrings;
		});
	},

	listBPCategories: {
		InvCorp: "BPItemCat1",
		InvIncorp: "BPItemCat1",
		InvFin: "BPItemCat1",
		CoutsMarch: "BPItemCat2",
		CoutsMatPrem: "BPItemCat2",
		CoutsPerso: "BPItemCat2",
		CoutsExt: "BPItemCat2",
		CoutsExploit: "BPItemCat2",
		CoutsTax: "BPItemCat2",
		CoutsFin: "BPItemCat2",
		CoutsDivid: "BPItemCat5",
		CoutsProvExploit: "BPItemCat5",
		CoutsExcep: "BPItemCat2",
		RevNego: "BPItemCat3",
		RevProd: "BPItemCat3",
		RevServ: "BPItemCat3",
		RevExploit: "BPItemCat3",
		RevFin: "BPItemCat3",
		RevExcep: "BPItemCat3",
		FinKP: "BPItemCat4",
		FinCCAsso: "BPItemCat4",
		FinDetCT: "BPItemCat5",
		FinDetLT: "BPItemCat4",
		FinSubvExploit: "BPItemCat4",
		FinSubvInv: "BPItemCat4",
		FinCrImpot: "BPItemCat5",
		FinExImpot: "BPItemCat5",
		// "FinFinPart" : "BPItemCat4"
		UnitVariable: "BPItemCat6",
	},

	listScenarFactorsCategories: {
		CoutsMarch: "scenarFactorCat2",
		CoutsMatPrem: "scenarFactorCat2",
		CoutsPerso: "scenarFactorCat2",
		CoutsExt: "scenarFactorCat2",
		CoutsExploit: "scenarFactorCat2",
		RevSecteur: "scenarFactorCat3",
		RevNego: "scenarFactorCat3",
		RevProd: "scenarFactorCat3",
		RevServ: "scenarFactorCat3",
		RevExploit: "scenarFactorCat3",
		FinDetCT: "scenarFactorCat4",
		DelaisPaieCli: "scenarFactorCat6",
		DelaisPaieFour: "scenarFactorCat6",
		DelaisLivrCli: "scenarFactorCat6",
		DelaisLivrFour: "scenarFactorCat6",
		TauxMargeComm: "scenarFactorCat3",
		TauxMargeBrut: "scenarFactorCat3",
	},

	listScenarIcons: {
		CoutsMarch: iconCoutsMarch,
		CoutsMatPrem: iconCoutsMatPrem,
		CoutsPerso: iconCoutsPerso,
		CoutsExt: iconCoutsExt,
		CoutsExploit: iconCoutsExploit,
		RevSecteur: iconRevSect,
		RevNego: iconRevNego,
		RevProd: iconRevProd,
		RevServ: iconRevServ,
		RevExploit: iconRevExploit,
		FinDetCT: iconFinDetCT,
		DelaisPaieCli: iconDelaiPaie,
		DelaisPaieFour: iconDelaiPaie,
		DelaisLivrCli: iconDelaiLivr,
		DelaisLivrFour: iconDelaiLivr,
		TauxMargeComm: iconTxMargeNego,
		TauxMargeBrut: iconTxMargeProd,
	},

	/*
	listActionIcons : {
		// "edit" : iconEdit,
		// "close" : iconClose,
		// "view" : iconView,
		"feedback" : iconFeedback,
		// "pin" : iconPin,
		"star-empty" : iconStarEmpty,
		"star-full" : iconStarFull,
	},
	*/

	listTxtBPLong: {
		InvCorp: "Investissement corporel",
		InvIncorp: "Investissement incorporel",
		InvFin: "Investissement financier",
		CoutsMarch: "Achats de marchandises",
		CoutsMatPrem: "Achats de matières premières",
		CoutsPerso: "Coûts de personnel",
		CoutsExt: "Coûts externes",
		CoutsExploit: "Autres coûts d'exploitation",
		CoutsTax: "Impôts et taxes",
		CoutsFin: "Charges financières",
		CoutsDivid: "Dividendes",
		CoutsProvExploit: "Provisions d'exploitation",
		CoutsExcep: "Coûts exceptionnels",
		RevNego: "Ventes de marchandises",
		RevProd: "Ventes de produits",
		RevServ: "Ventes de services",
		RevExploit: "Autres revenus d'exploitation",
		RevFin: "Revenus financiers",
		RevExcep: "Revenus exceptionnels",
		FinKP: "Capitaux propres",
		FinCCAsso: "Comptes courants d'associés",
		// "FinDetCT" : 				"Emprunt financier court terme",
		FinDetCT: "Crédit de trésorerie",
		FinDetLT: "Emprunt financier",
		FinSubvExploit: "Subvention d'exploitation",
		FinSubvInv: "Subvention d'investissement",
		FinCrImpot: "Crédit d'impôt",
		FinExImpot: "Exonération d'impôt",
		// "FinFinPart" : 			"Financement participatif"
		UnitVariable: "Variable élémentaire",
	},

	listTxtBPLong2Lines: {
		InvCorp: (
			<React.Fragment>
				Investissement
				<br />
				corporel
			</React.Fragment>
		),
		InvIncorp: (
			<React.Fragment>
				Investissement
				<br />
				incorporel
			</React.Fragment>
		),
		InvFin: (
			<React.Fragment>
				Investissement
				<br />
				financier
			</React.Fragment>
		),
		CoutsMarch: (
			<React.Fragment>
				Achats de
				<br />
				marchandises
			</React.Fragment>
		),
		CoutsMatPrem: (
			<React.Fragment>
				Achats de
				<br />
				matières premières
			</React.Fragment>
		),
		CoutsPerso: (
			<React.Fragment>
				Coûts de
				<br />
				personnel
			</React.Fragment>
		),
		CoutsExt: (
			<React.Fragment>
				Coûts
				<br />
				externes
			</React.Fragment>
		),
		CoutsExploit: (
			<React.Fragment>
				Autres coûts
				<br />
				d&apos;exploitation
			</React.Fragment>
		),
		CoutsTax: (
			<React.Fragment>
				Impôts
				<br />
				et taxes
			</React.Fragment>
		),
		CoutsFin: (
			<React.Fragment>
				Charges
				<br />
				financières
			</React.Fragment>
		),
		CoutsDivid: (
			<React.Fragment>
				Distribution
				<br />
				de dividendes
			</React.Fragment>
		),
		CoutsProvExploit: (
			<React.Fragment>
				Provisions
				<br />
				d&apos;exploitation
			</React.Fragment>
		),
		CoutsExcep: (
			<React.Fragment>
				Coûts
				<br />
				exceptionnels
			</React.Fragment>
		),
		RevNego: (
			<React.Fragment>
				Ventes de
				<br />
				marchandises
			</React.Fragment>
		),
		RevProd: (
			<React.Fragment>
				Ventes de
				<br />
				produits
			</React.Fragment>
		),
		RevServ: (
			<React.Fragment>
				Ventes de
				<br />
				services
			</React.Fragment>
		),
		RevExploit: (
			<React.Fragment>
				Autres revenus
				<br />
				d&apos;exploitation
			</React.Fragment>
		),
		RevFin: (
			<React.Fragment>
				Revenus
				<br />
				financiers
			</React.Fragment>
		),
		RevExcep: (
			<React.Fragment>
				Revenus
				<br />
				exceptionnels
			</React.Fragment>
		),
		FinKP: (
			<React.Fragment>
				Capitaux
				<br />
				propres
			</React.Fragment>
		),
		FinCCAsso: (
			<React.Fragment>
				Comptes courants
				<br />
				d&apos;associés
			</React.Fragment>
		),
		// "FinDetCT" : <React.Fragment>Emprunt financier<br/>court terme</React.Fragment>,
		FinDetCT: (
			<React.Fragment>
				Crédit de
				<br />
				trésorerie
			</React.Fragment>
		),
		FinDetLT: (
			<React.Fragment>
				Emprunt
				<br />
				financier
			</React.Fragment>
		),
		FinSubvExploit: (
			<React.Fragment>
				Subvention
				<br />
				d&apos;exploitation
			</React.Fragment>
		),
		FinSubvInv: (
			<React.Fragment>
				Subvention
				<br />
				d&apos;investissement
			</React.Fragment>
		),
		FinCrImpot: (
			<React.Fragment>
				Crédit
				<br />
				d&apos;impôt
			</React.Fragment>
		),
		FinExImpot: (
			<React.Fragment>
				Exonération
				<br />
				d&apos;impôt
			</React.Fragment>
		),
		UnitVariable: (
			<React.Fragment>
				Variable
				<br />
				élémentaire
			</React.Fragment>
		),
	},

	listTxtBPShort: {
		InvCorp: "Invest. corporels",
		InvIncorp: "Invest. incorporels",
		InvFin: "Invest. financiers",
		CoutsMarch: "Achats marchandises",
		CoutsMatPrem: "Achats matières premières",
		CoutsPerso: "Coûts de personnel",
		CoutsExt: "Coûts externes",
		CoutsExploit: "Autres coûts exploitation",
		CoutsTax: "Impôts et taxes",
		CoutsFin: "Charges financières",
		CoutsDivid: "Dividendes",
		CoutsProvExploit: "Provisions d'exploitation",
		CoutsExcep: "Coûts exceptionnels",
		RevNego: "Ventes de marchandises",
		RevProd: "Ventes de produits",
		RevServ: "Ventes de services",
		RevExploit: "Autres revenus exploitation",
		RevFin: "Revenus financiers",
		RevExcep: "Revenus exceptionnels",
		FinKP: "Capitaux propres",
		FinCCAsso: "Comptes courants associés",
		// "FinDetCT" : 				"Emprunt financier CT",
		FinDetCT: "Crédit de trésorerie",
		FinDetLT: "Emprunt financier",
		FinSubvExploit: "Subvention d'exploitation",
		FinSubvInv: "Subvention d'investissement",
		FinCrImpot: "Crédit d'impôt",
		FinExImpot: "Exonération d'impôt",
		UnitVariable: "Variable élémentaire",
	},

	listTxtScenarLong: {
		CoutsMarch: "Coûts des marchandises",
		CoutsMatPrem: "Coûts des matières premières",
		CoutsPerso: "Coûts de personnel",
		CoutsExt: "Coûts externes",
		CoutsExploit: "Autres coûts d'exploitation",
		RevSecteur: "Chiffre d'affaires du secteur",
		RevNego: "Chiffre d'affaires négoce",
		RevProd: "Chiffre d'affaires production",
		RevServ: "Chiffre d'affaires services",
		RevExploit: "Autres revenus d'exploitation",
		FinDetCT: "Dettes financières court terme",
		DelaisPaieCli: "Délais de paiement clients",
		DelaisPaieFour: "Délais de paiement fournisseurs",
		DelaisLivrCli: "Délais de livraison clients",
		DelaisLivrFour: "Délais de livraison fournisseurs",
		TauxMargeComm: "Taux de marge commerciale",
		TauxMargeBrut: "Taux de marge brute",
	},

	listBPCategoryPercentInputText: {
		InvCorp: "du chiffre d'affaires",
		InvIncorp: "du chiffre d'affaires",
		InvFin: "du chiffre d'affaires",
		CoutsMarch: "du chiffre d'affaires",
		CoutsMatPrem: "du chiffre d'affaires",
		CoutsPerso: "du chiffre d'affaires",
		CoutsExt: "du chiffre d'affaires",
		CoutsExploit: "du chiffre d'affaires",
		CoutsTax: "du chiffre d'affaires",
		CoutsFin: "du chiffre d'affaires",
		CoutsDivid: "du résultat distribuable",
		CoutsProvExploit: "du chiffre d'affaires",
		CoutsExcep: "du chiffre d'affaires",
		RevNego: "du chiffre d'affaires",
		RevProd: "du chiffre d'affaires",
		RevServ: "du chiffre d'affaires",
		RevExploit: "du chiffre d'affaires",
		RevFin: "du chiffre d'affaires",
		RevExcep: "du chiffre d'affaires",
		FinKP: "du chiffre d'affaires",
		FinCCAsso: "du chiffre d'affaires",
		FinDetCT: "du chiffre d'affaires",
		FinDetLT: "du chiffre d'affaires",
		FinSubvExploit: "du chiffre d'affaires",
		FinSubvInv: "du chiffre d'affaires",
		FinCrImpot: "du chiffre d'affaires",
		FinExImpot: "du montant d'impôt",
		UnitVariable: "",
	},

	listBPDefaultValTypeParam: {
		InvCorp: "amount",
		InvIncorp: "amount",
		InvFin: "amount",
		CoutsMarch: "variation-percent",
		CoutsMatPrem: "variation-percent",
		CoutsPerso: "variation-percent",
		CoutsExt: "variation-percent",
		CoutsExploit: "amount",
		CoutsTax: "variation-percent",
		CoutsFin: "amount",
		CoutsDivid: "category-percent",
		CoutsProvExploit: "amount",
		CoutsExcep: "amount",
		RevNego: "variation-percent",
		RevProd: "variation-percent",
		RevServ: "variation-percent",
		RevExploit: "amount",
		RevFin: "amount",
		RevExcep: "amount",
		FinKP: "amount",
		FinCCAsso: "amount",
		FinDetCT: "amount",
		FinDetLT: "amount",
		FinSubvExploit: "amount",
		FinSubvInv: "amount",
		FinCrImpot: "amount",
		FinExImpot: "category-percent",
		UnitVariable: "number",
	},

	listBPMinMaxPercentValParam: {
		CoutsDivid: {
			"category-percent": {
				min: 0,
				max: 100,
			},
		},
		FinExImpot: {
			"category-percent": {
				min: 0,
				max: 100,
			},
		},
	},

	listBPValTypeParamChoosable: (unitVariablesInput) =>
		unitVariablesInput
			? {
					InvCorp: true,
					InvIncorp: true,
					InvFin: true,
					CoutsMarch: true,
					CoutsMatPrem: true,
					CoutsPerso: true,
					CoutsExt: true,
					CoutsExploit: true,
					CoutsTax: true,
					CoutsFin: true,
					CoutsDivid: true,
					CoutsProvExploit: true,
					CoutsExcep: true,
					RevNego: true,
					RevProd: true,
					RevServ: true,
					RevExploit: true,
					RevFin: true,
					RevExcep: true,
					FinKP: true,
					FinCCAsso: true,
					FinDetCT: true,
					FinDetLT: true,
					FinSubvExploit: true,
					FinSubvInv: true,
					FinCrImpot: true,
					FinExImpot: false,
					UnitVariable: true,
				}
			: {
					InvCorp: false,
					InvIncorp: false,
					InvFin: false,
					CoutsMarch: true,
					CoutsMatPrem: true,
					CoutsPerso: true,
					CoutsExt: true,
					CoutsExploit: true,
					CoutsTax: true,
					CoutsFin: true,
					CoutsDivid: true,
					CoutsProvExploit: true,
					CoutsExcep: true,
					RevNego: true,
					RevProd: true,
					RevServ: true,
					RevExploit: true,
					RevFin: true,
					RevExcep: true,
					FinKP: false,
					FinCCAsso: false,
					FinDetCT: false,
					FinDetLT: false,
					FinSubvExploit: false,
					FinSubvInv: false,
					FinCrImpot: false,
					FinExImpot: false,
					UnitVariable: true,
				},

	listBPHasSubTypeParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPAdvancedParamsType: {
		InvCorp: "B",
		InvIncorp: "B",
		InvFin: "C",
		CoutsMarch: "A",
		CoutsMatPrem: "A",
		CoutsPerso: "A",
		CoutsExt: "A",
		CoutsExploit: "A",
		CoutsTax: "A",
		CoutsFin: "A",
		CoutsDivid: "A",
		CoutsProvExploit: "A",
		CoutsExcep: "A",
		RevNego: "A",
		RevProd: "A",
		RevServ: "A",
		RevExploit: "A",
		RevFin: "A",
		RevExcep: "A",
		FinKP: "B",
		FinCCAsso: "B",
		FinDetCT: "B",
		FinDetLT: "B",
		FinSubvExploit: "B",
		FinSubvInv: "B",
		FinCrImpot: "B",
		FinExImpot: "B",
		UnitVariable: "B",
	},

	listBPHasAmountValTypeParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: true,
		FinExImpot: true,
		UnitVariable: false,
	},

	listBPHasNumberValTypeParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPHasVariationPercentValTypeParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: true,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPHasCategoryPercentValTypeParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPHasUnitVariableFunctionValTypeParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: true,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPAmountInputText(item, isMicroCompany, countryCode) {
		if (item.type === "CoutsPerso" && !isMicroCompany) {
			return (
				<>
					Coût total
					<CoutsPersoAmountInfoTip countryCode={countryCode} />
				</>
			);
		} else {
			return null;
		}
	},

	listBPUnitPriceInputText(item /*, isMicroCompany, countryCode*/) {
		if (item.type === "CoutsPerso") {
			return <>Montant unitaire</>;
		} else {
			if (this.listBPRecurringParamChoosable[item.type] && item.isRecurring) {
				return "Prix mensuel de l'abonnement";
			} else {
				return "Prix unitaire";
			}
		}
	},

	listBPIsSalesCatPercentValType: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: true,
		FinExImpot: false,
		UnitVariable: true,
	},

	bpItemTypeValToValues(item) {
		const result = {
			valueType: "",
			valueAmount: 0,
			valueVariationPercent: 0,
			valueCategoryPercent: 0,
		};

		if (item.valueType === "montant") {
			result.valueType = "amount";
			result.valueAmount = item.value;
		} else if (item.valueType === "nombre") {
			result.valueType = "number";
			result.valueAmount = item.value;
		} else if (item.valueType === "pourcent") {
			// deprecated
			if (item.type === "CoutsTax" || item.type === "CoutsDivid" || item.type === "FinExImpot") {
				result.valueType = "category-percent";
				result.valueCategoryPercent = item.value;
			} else {
				result.valueType = "variation-percent";
				result.valueVariationPercent = item.value;
			}
		} else if (item.valueType === "pourcent-variation") {
			result.valueType = "variation-percent";
			result.valueVariationPercent = item.value;
		} else if (item.valueType === "pourcent-categorie") {
			result.valueType = "category-percent";
			result.valueCategoryPercent = item.value;
		} else if (item.valueType === "unit-variable-function") {
			result.valueType = "unit-variable-function";
			if (item.unitVariableFunctionType === "percent") {
				result.valueCategoryPercent = item.value;
			} else if (item.unitVariableFunctionType === "currency") {
				result.valueAmount = item.value;
			}
		}

		return result;
	},

	listBPIncomeStatementItem: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPHasCounterpartyCompanyParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPAmortizableParam(itemType, isMicroCompany) {
		if (itemType === "InvCorp" || itemType === "InvIncorp" || itemType === "InvFin" || itemType === "FinSubvInv") {
			return !isMicroCompany;
		} else if (itemType === "FinDetLT") {
			return true;
		} else return false;
	},

	listBPTaxSystemParam(itemType, isMicroCompany) {
		return itemType === "RevFin" && !isMicroCompany;
	},

	listBPDefaultIsAmortParam: {
		InvCorp: true,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: true,
		FinExImpot: true,
		UnitVariable: false,
	},

	listBPAmortMaturityIntervalChoosable: (props) => ({
		InvCorp: props.curProjectData && props.curProjectData.ExpertParametersMode && props.curProjectData.InvestMonthlyAmort,
		InvIncorp: props.curProjectData && props.curProjectData.ExpertParametersMode && props.curProjectData.InvestMonthlyAmort,
		InvFin: props.curProjectData && props.curProjectData.ExpertParametersMode && props.curProjectData.InvestMonthlyAmort,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: true,
		FinSubvExploit: false,
		FinSubvInv: props.curProjectData && props.curProjectData.ExpertParametersMode && props.curProjectData.InvestMonthlyAmort,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	}),

	listBPAmortTypeChoosable: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: true,
		FinSubvExploit: false,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPAmortTypes: {
		InvCorp: ["linéaire", "custom"],
		InvIncorp: ["linéaire", "custom"],
		InvFin: undefined,
		CoutsMarch: undefined,
		CoutsMatPrem: undefined,
		CoutsPerso: undefined,
		CoutsExt: undefined,
		CoutsExploit: undefined,
		CoutsTax: undefined,
		CoutsFin: undefined,
		CoutsDivid: undefined,
		CoutsProvExploit: undefined,
		CoutsExcep: undefined,
		RevNego: undefined,
		RevProd: undefined,
		RevServ: undefined,
		RevExploit: undefined,
		RevFin: undefined,
		RevExcep: undefined,
		FinKP: undefined,
		FinCCAsso: undefined,
		FinDetCT: undefined,
		FinDetLT: ["linéaire", "constant", "in fine", "rbf", "custom"],
		FinSubvExploit: undefined,
		FinSubvInv: ["linéaire", "custom"],
		FinCrImpot: undefined,
		FinExImpot: undefined,
		UnitVariable: undefined,
	},

	listBPCustomAmortInputableMonths: {
		InvCorp: [11],
		InvIncorp: [11],
		InvFin: undefined,
		CoutsMarch: undefined,
		CoutsMatPrem: undefined,
		CoutsPerso: undefined,
		CoutsExt: undefined,
		CoutsExploit: undefined,
		CoutsTax: undefined,
		CoutsFin: undefined,
		CoutsDivid: undefined,
		CoutsProvExploit: undefined,
		CoutsExcep: undefined,
		RevNego: undefined,
		RevProd: undefined,
		RevServ: undefined,
		RevExploit: undefined,
		RevFin: undefined,
		RevExcep: undefined,
		FinKP: undefined,
		FinCCAsso: undefined,
		FinDetCT: undefined,
		FinDetLT: undefined,
		FinSubvExploit: undefined,
		FinSubvInv: [11],
		FinCrImpot: undefined,
		FinExImpot: undefined,
		UnitVariable: undefined,
	},

	listBPAmortPeriodChoosable: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: true,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDeferredAmortChoosable: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: true,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDefaultAmortTypeParam: {
		InvCorp: "linéaire",
		InvIncorp: "linéaire",
		InvFin: "in fine",
		CoutsMarch: "immédiat",
		CoutsMatPrem: "immédiat",
		CoutsPerso: "immédiat",
		CoutsExt: "immédiat",
		CoutsExploit: "immédiat",
		CoutsTax: "immédiat",
		CoutsFin: "immédiat",
		CoutsDivid: "immédiat",
		CoutsProvExploit: "immédiat",
		CoutsExcep: "immédiat",
		RevNego: "immédiat",
		RevProd: "immédiat",
		RevServ: "immédiat",
		RevExploit: "immédiat",
		RevFin: "immédiat",
		RevExcep: "immédiat",
		FinKP: "perpétuel",
		FinCCAsso: "perpétuel",
		FinDetCT: "immédiat",
		FinDetLT: "constant",
		FinSubvExploit: "immédiat",
		FinSubvInv: "linéaire",
		FinCrImpot: "immédiat",
		FinExImpot: "immédiat",
		UnitVariable: "",
	},

	listBPDefaultAmortPeriodParam: {
		InvCorp: 12,
		InvIncorp: 12,
		InvFin: 12,
		CoutsMarch: 12,
		CoutsMatPrem: 12,
		CoutsPerso: 12,
		CoutsExt: 12,
		CoutsExploit: 12,
		CoutsTax: 12,
		CoutsFin: 12,
		CoutsDivid: 12,
		CoutsProvExploit: 12,
		CoutsExcep: 12,
		RevNego: 12,
		RevProd: 12,
		RevServ: 12,
		RevExploit: 12,
		RevFin: 12,
		RevExcep: 12,
		FinKP: 12,
		FinCCAsso: 12,
		FinDetCT: 12,
		FinDetLT: 1,
		FinSubvExploit: 12,
		FinSubvInv: 12,
		FinCrImpot: 12,
		FinExImpot: 12,
		UnitVariable: 0,
	},

	listBPMonthChoosableOnAmountInputParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPMonthChoosableOnPercentInputParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPMonthChoosableOnUnitVariableFunctionInputParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDefaultBeginMonthParam: {
		InvCorp: 0,
		InvIncorp: 0,
		InvFin: 0,
		CoutsMarch: 0,
		CoutsMatPrem: 0,
		CoutsPerso: 0,
		CoutsExt: 0,
		CoutsExploit: 0,
		CoutsTax: 0,
		CoutsFin: 0,
		CoutsDivid: 5,
		CoutsProvExploit: 0,
		CoutsExcep: 0,
		RevNego: 0,
		RevProd: 0,
		RevServ: 0,
		RevExploit: 0,
		RevFin: 0,
		RevExcep: 0,
		FinKP: 0,
		FinCCAsso: 0,
		FinDetCT: 0,
		FinDetLT: 0,
		FinSubvExploit: 0,
		FinSubvInv: 0,
		FinCrImpot: 0,
		FinExImpot: 0,
		UnitVariable: 0,
	},

	listBPEndMonthChoosableParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPPropagatableParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: true,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPDefaultPropagParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: true,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPFrequencyParamChoosable: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPQuantityParamChoosable: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPRecurringParamChoosable: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPRecurringInvestItems: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPSeasonalityParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: true,
	},

	listBPSeasonalityLabels: {
		InvCorp: "Calendrier des investissements",
		InvIncorp: "Calendrier des investissements",
		InvFin: "Calendrier des investissements",
		CoutsMarch: "Saisonnalité des commandes",
		CoutsMatPrem: "Saisonnalité des commandes",
		CoutsPerso: "Saisonnalité",
		CoutsExt: "Saisonnalité des commandes",
		CoutsExploit: "Saisonnalité",
		CoutsTax: "Saisonnalité",
		CoutsFin: "Saisonnalité",
		CoutsDivid: null,
		CoutsProvExploit: "Calendrier",
		CoutsExcep: "Saisonnalité",
		RevNego: "Saisonnalité des commandes",
		RevProd: "Saisonnalité des commandes",
		RevServ: "Saisonnalité des commandes",
		RevExploit: "Saisonnalité des commandes",
		RevFin: "Saisonnalité",
		RevExcep: "Saisonnalité",
		FinKP: null,
		FinCCAsso: null,
		FinDetCT: null,
		FinDetLT: null,
		FinSubvExploit: "Calendrier",
		FinSubvInv: "Calendrier",
		FinCrImpot: null,
		FinExImpot: null,
		UnitVariable: "Saisonnalité",
	},

	listBPSeasonalitySwitchParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDepositParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDepositInputText: {
		InvCorp: "Acompte à la commande",
		InvIncorp: "Acompte à la commande",
		InvFin: null,
		CoutsMarch: "Acompte à la commande",
		CoutsMatPrem: "Acompte à la commande",
		CoutsPerso: null,
		CoutsExt: "Acompte à la commande",
		CoutsExploit: null,
		CoutsTax: null,
		CoutsFin: null,
		CoutsDivid: null,
		CoutsProvExploit: null,
		CoutsExcep: null,
		RevNego: "Acompte à la commande",
		RevProd: "Acompte à la commande",
		RevServ: "Acompte à la commande",
		RevExploit: null,
		RevFin: null,
		RevExcep: null,
		FinKP: null,
		FinCCAsso: null,
		FinDetCT: null,
		FinDetLT: null,
		FinSubvExploit: "Acompte à la signature",
		FinSubvInv: "Acompte à la signature",
		FinCrImpot: null,
		FinExImpot: null,
		UnitVariable: null,
	},

	listBPDeliveryDelayParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPClientPaymentDelayParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPSupplierPaymentDelayParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPTradeMarginParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPGrossMarginParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: true,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPStockableSupply: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPConsumableExpense: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPDeferrableRecurring: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPHasVATParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: false,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPHasInterestRateParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: true,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	listBPInterestRateParamInfoTip: {
		InvCorp: null,
		InvIncorp: null,
		InvFin: <InvestInterestRateInputInfoTip id="intRate" />,
		CoutsMarch: null,
		CoutsMatPrem: null,
		CoutsPerso: null,
		CoutsExt: null,
		CoutsExploit: null,
		CoutsTax: null,
		CoutsFin: null,
		CoutsDivid: null,
		CoutsProvExploit: null,
		CoutsExcep: null,
		RevNego: null,
		RevProd: null,
		RevServ: null,
		RevExploit: null,
		RevFin: null,
		RevExcep: null,
		FinKP: null,
		FinCCAsso: null,
		FinDetCT: <DebtInterestRateInputInfoTip id="intRate" />,
		FinDetLT: <DebtInterestRateInputInfoTip id="intRate" />,
		FinSubvExploit: null,
		FinSubvInv: null,
		FinCrImpot: null,
		FinExImpot: null,
		UnitVariable: null,
	},

	listBPDefaultInterestRateParam: {
		InvCorp: 0,
		InvIncorp: 0,
		InvFin: 0,
		CoutsMarch: 0,
		CoutsMatPrem: 0,
		CoutsPerso: 0,
		CoutsExt: 0,
		CoutsExploit: 0,
		CoutsTax: 0,
		CoutsFin: 0,
		CoutsDivid: 0,
		CoutsProvExploit: 0,
		CoutsExcep: 0,
		RevNego: 0,
		RevProd: 0,
		RevServ: 0,
		RevExploit: 0,
		RevFin: 0,
		RevExcep: 0,
		FinKP: 0,
		FinCCAsso: 0,
		FinDetCT: 0.02,
		FinDetLT: 0.02,
		FinSubvExploit: 0,
		FinSubvInv: 0,
		FinCrImpot: 0,
		FinExImpot: 0,
		UnitVariable: 0,
	},

	listBPHasCapitalizedProductionParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	/*
	listBPAmountInfoTipParam : {
		"InvCorp" : null,
		"InvIncorp" : null,
		"InvFin" : null,
		"CoutsMarch" : null,
		"CoutsMatPrem" : null,
		"CoutsPerso" : (
			// <div className="bpAmountInfoTipCont">
				// <i>Salaire chargé</i><CoutsPersoAmountInfoTip/>
			// </div>
			<CoutsPersoAmountInfoTip/>
		),
		"CoutsExt" : null,
		"CoutsExploit" : null,
		"CoutsTax" : null,
		"CoutsFin" : null,
		"CoutsDivid" : null,
		"CoutsProvExploit" : null,
		"CoutsExcep" : null,
		"RevNego" : null,
		"RevProd" : null,
		"RevServ" : null,
		"RevExploit" : null,
		"RevFin" : null,
		"RevExcep" : null,
		"FinKP" : null,
		"FinCCAsso" : null,
		"FinDetCT" : null,
		"FinDetLT" : null,
		"FinSubvExploit" : null,
		"FinSubvInv" : null,
		"FinCrImpot" : null,
		"FinExImpot" : null,
		"UnitVariable" : null,
	},
	*/

	listBPHasAmountChargeRateParam: {
		InvCorp: () => false,
		InvIncorp: () => false,
		InvFin: () => false,
		CoutsMarch: () => false,
		CoutsMatPrem: () => false,
		CoutsPerso: (isMicroCompany) => !isMicroCompany,
		CoutsExt: () => false,
		CoutsExploit: () => false,
		CoutsTax: () => false,
		CoutsFin: () => false,
		CoutsDivid: () => false,
		CoutsProvExploit: () => false,
		CoutsExcep: () => false,
		RevNego: () => false,
		RevProd: () => false,
		RevServ: () => false,
		RevExploit: () => false,
		RevFin: () => false,
		RevExcep: () => false,
		FinKP: () => false,
		FinCCAsso: () => false,
		FinDetCT: () => false,
		FinDetLT: () => false,
		FinSubvExploit: () => false,
		FinSubvInv: () => false,
		FinCrImpot: () => false,
		FinExImpot: () => false,
		UnitVariable: () => false,
	},

	listBPDefaultAmountChargeRateValue: {
		InvCorp: 0,
		InvIncorp: 0,
		InvFin: 0,
		CoutsMarch: 0,
		CoutsMatPrem: 0,
		CoutsPerso: 0.42,
		CoutsExt: 0,
		CoutsExploit: 0,
		CoutsTax: 0,
		CoutsFin: 0,
		CoutsDivid: 0,
		CoutsProvExploit: 0,
		CoutsExcep: 0,
		RevNego: 0,
		RevProd: 0,
		RevServ: 0,
		RevExploit: 0,
		RevFin: 0,
		RevExcep: 0,
		FinKP: 0,
		FinCCAsso: 0,
		FinDetCT: 0,
		FinDetLT: 0,
		FinSubvExploit: 0,
		FinSubvInv: 0,
		FinCrImpot: 0,
		FinExImpot: 0,
		UnitVariable: 0,
	},

	listBPModalHeightParam: {
		InvCorp: "bpModalSmall",
		InvIncorp: "bpModalSmall",
		InvFin: "bpModalSmall",
		CoutsMarch: "bpModalTall",
		CoutsMatPrem: "bpModalTall",
		CoutsPerso: "bpModalTall",
		CoutsExt: "bpModalTall",
		CoutsExploit: "bpModalTall",
		CoutsTax: "bpModalTall",
		CoutsFin: "bpModalTall",
		CoutsDivid: "bpModalTall",
		CoutsProvExploit: "bpModalTall",
		CoutsExcep: "bpModalTall",
		RevNego: "bpModalTall",
		RevProd: "bpModalTall",
		RevServ: "bpModalTall",
		RevExploit: "bpModalTall",
		RevFin: "bpModalTall",
		RevExcep: "bpModalTall",
		FinKP: "bpModalSmall",
		FinCCAsso: "bpModalSmall",
		FinDetCT: "bpModalSmall",
		FinDetLT: "bpModalSmall",
		FinSubvExploit: "bpModalSmall",
		FinSubvInv: "bpModalSmall",
		FinCrImpot: "bpModalSmall",
		FinExImpot: "bpModalSmall",
		UnitVariable: "bpModalSmall",
	},

	listBPProductAvailableParam: {
		InvCorp: true,
		InvIncorp: true,
		InvFin: true,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: true,
		RevFin: true,
		RevExcep: true,
		FinKP: true,
		FinCCAsso: true,
		FinDetCT: true,
		FinDetLT: true,
		FinSubvExploit: true,
		FinSubvInv: true,
		FinCrImpot: true,
		FinExImpot: true,
		UnitVariable: true,
	},

	listBPProductSalesMappingAvailableParam: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: true,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: true,
		FinSubvInv: false,
		FinCrImpot: true,
		FinExImpot: true,
		UnitVariable: false,
	},

	isExpenseBPItem: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: true,
		CoutsMatPrem: true,
		CoutsPerso: true,
		CoutsExt: true,
		CoutsExploit: true,
		CoutsTax: true,
		CoutsFin: true,
		CoutsDivid: false,
		CoutsProvExploit: true,
		CoutsExcep: true,
		RevNego: false,
		RevProd: false,
		RevServ: false,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	isRevenueBPItem: {
		InvCorp: false,
		InvIncorp: false,
		InvFin: false,
		CoutsMarch: false,
		CoutsMatPrem: false,
		CoutsPerso: false,
		CoutsExt: false,
		CoutsExploit: false,
		CoutsTax: false,
		CoutsFin: false,
		CoutsDivid: false,
		CoutsProvExploit: false,
		CoutsExcep: false,
		RevNego: true,
		RevProd: true,
		RevServ: true,
		RevExploit: false,
		RevFin: false,
		RevExcep: false,
		FinKP: false,
		FinCCAsso: false,
		FinDetCT: false,
		FinDetLT: false,
		FinSubvExploit: false,
		FinSubvInv: false,
		FinCrImpot: false,
		FinExImpot: false,
		UnitVariable: false,
	},

	BPItemTypeToAccountName: {
		InvCorp: "bs_immobilisations_corporelles",
		InvIncorp: "bs_immobilisations_incorporelles",
		InvFin: "bs_immobilisations_financieres",
		CoutsMarch: "is_achats_marchandises",
		CoutsMatPrem: "is_achats_matieres_premieres",
		CoutsPerso: "is_charges_personnel",
		CoutsExt: "is_charges_externes",
		CoutsExploit: "is_autres_charges_exploitation",
		CoutsTax: "is_impots_taxes",
		CoutsFin: "is_charges_financieres",
		CoutsDivid: "is_dividendes_montant",
		CoutsProvExploit: "is_dotations_provisions_exploitation",
		CoutsExcep: "is_charges_exceptionnelles",
		RevNego: "is_chiffre_affaires_negoce",
		RevProd: "is_chiffre_affaires_production",
		RevServ: "is_chiffre_affaires_services",
		RevExploit: "is_autres_produits_exploitation",
		RevFin: "is_produits_financiers",
		RevExcep: "is_produits_exceptionnels",
		FinKP: "bs_capital_autres_fonds_propres",
		FinCCAsso: "bs_comptes_courants_associes",
		FinDetCT: "bs_dettes_financieres_court_terme",
		FinDetLT: "bs_dettes_financieres_moyen_long_terme",
		FinSubvExploit: "is_subventions_exploitation",
		FinSubvInv: "bs_subventions_investissement",
		FinCrImpot: "is_credit_impot",
		FinExImpot: "is_exoneration_impot",
		UnitVariable: undefined,
	},

	scaleNumber(num, unit) {
		unit = typeof unit !== "undefined" ? unit : 1;
		return unit === 0 ? num : Math.round(num / (unit / 10000)) / 10000;
		//Math.round(num/(unit/10000))/10000 // pour affichage provisoire avec 3 chiffres après la virgule
	},

	formatNumber(num, unit, decimalPrecision) {
		// console.log(num, unit, decimalPrecision);
		const dec = typeof decimalPrecision !== "undefined" ? decimalPrecision : 1;
		return unit === 0
			? //num.toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1})
				formatNumberLocaleString(num, dec)
			: //(Math.round(num/(unit/10))/10).toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1})
				formatNumberLocaleString(Math.round(num / (unit / 10 ** dec)) / 10 ** dec, dec);
		//(Math.round((num)/(unit/10000))/10000).toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: 3, maximumFractionDigits: 3}) // pour affichage provisoire avec 3 chiffres après la virgule
	},

	formatNumberAmount(num, amountsUnitTxt, decimalPrecision) {
		var val = Number(num);
		decimalPrecision = typeof decimalPrecision === "undefined" ? 1 : decimalPrecision;
		//return (val > 0 ? "+" : "") + val.toLocaleString("fr-FR", {style: "currency", currency: "EUR", minimumFractionDigits: 0, maximumFractionDigits: 0});
		//return (val > 0 ? "+" : "") + val.toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1}) + " " + amountsUnitTxt;
		return (val > 0 ? "+" : "") + formatNumberLocaleString(val, decimalPrecision) + " " + amountsUnitTxt;
	},

	formatNumberPercent(num, decimalPrecision) {
		var val = Number(num);
		//return (val > 0 ? "+" : "") + val.toLocaleString("fr-FR", {style: "decimal", minimumFractionDigits: 1, maximumFractionDigits: 1}) + " %";
		return (val > 0 ? "+" : "") + formatNumberLocaleString(val, typeof decimalPrecision !== "undefined" ? decimalPrecision : 1) + " %";
	},

	ccyUnitTxtLong(numberUnit, currencyText) {
		var unitTxt = "";
		const ccyTxt = currencyText === "euros" ? " d'euros" : " de " + currencyText;
		switch (numberUnit) {
			case 0.01:
				unitTxt = "%";
				break;
			case 1:
				unitTxt = currencyText;
				break;
			case 1000:
				unitTxt = "milliers" + ccyTxt;
				break;
			case 1000000:
				unitTxt = "millions" + ccyTxt;
				break;
			case 1000000000:
				unitTxt = "milliards" + ccyTxt;
				break;
			case 1000000000000:
				unitTxt = "billions" + ccyTxt;
				break;
			default:
				break;
		}
		return unitTxt;
	},

	ccyUnitTxtShort(numberUnit, currencySymbol) {
		var unitTxt = "";
		switch (numberUnit) {
			case 0.01:
				unitTxt = "%";
				break;
			case 1:
				unitTxt = currencySymbol;
				break;
			case 1000:
				unitTxt = "k" + currencySymbol;
				break;
			case 1000000:
				unitTxt = "M" + currencySymbol;
				break;
			case 1000000000:
				unitTxt = "Mds" + currencySymbol;
				break;
			case 1000000000000:
				unitTxt = "Bns" + currencySymbol;
				break;
			default:
				break;
		}
		return unitTxt;
	},

	unitTxtShort(numberUnit) {
		var unitTxt = "";
		switch (numberUnit) {
			case 0.01:
				unitTxt = "%";
				break;
			case 1000:
				unitTxt = "k";
				break;
			case 1000000:
				unitTxt = "M";
				break;
			case 1000000000:
				unitTxt = "Mds";
				break;
			case 1000000000000:
				unitTxt = "Bns";
				break;
			default:
				break;
		}
		return unitTxt;
	},

	/*
	hexColor(h, s, v) {
		// h, s, v compris entre 0 et 1
		
		var r, g, b;
		var i;
		var f, p, q, t;
 
		// Make sure our arguments stay in-range
		h = Math.max(0, Math.min(1, h));
		s = Math.max(0, Math.min(1, s));
		v = Math.max(0, Math.min(1, v));
		
		h *= 360;

		if (s === 0) {
			// Achromatic (grey)
			r = g = b = v;
			//return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
			return (Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16));
		}

		h /= 60; // sector 0 to 5
		i = Math.floor(h);
		f = h - i; // factorial part of h
		p = v * (1 - s);
		q = v * (1 - s * f);
		t = v * (1 - s * (1 - f));

		switch (i) {
			case 0:
				r = v;
				g = t;
				b = p;
				break;
			case 1:
				r = q;
				g = v;
				b = p;
				break;
			case 2:
				r = p;
				g = v;
				b = t;
				break;
			case 3:
				r = p;
				g = q;
				b = v;
				break;
			case 4:
				r = t;
				g = p;
				b = v;
				break;
			default: // case 5:
				r = v;
				g = p;
				b = q;
		}

		//return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
		return (Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16));
	},
	
	randomNiceHexColor() {
		var h = ((Math.random() + (Math.sqrt(5) - 1) / 2) % 1); // ratio nbre d'or
		var s = 0.6;
		var v = 0.95;
		
		return this.hexColor(h, s, v);
	},
	*/
};
