import React from "react";
import {FrCloudInfoTip} from "../components/InfoTips";
import config from "../config";

export const subscriptionParams = {
	subscriptionProfiles: () => {
		switch (window.appName) {
			case "forekasts-africa":
				return ["ebizview-business-starter", "ebizview-business-pilot", "ebizview-business-partner"];
			case "evaltonbiz":
			case "ysibiz":
			default:
				// return ["createur", "dirigeant", "evaluateur", "accompagnateur", "accompagnement", "etudiant", "enseignant"];
				return ["createur", "dirigeant", "evaluateur", "accompagnement", "etudiant", "enseignant"];
		}
	},
	offerCatalog: ["createur", "entrepreneur", "dirigeant", "evaluateur", "accompagnateur", "accompagnement"],
	offerNames: {
		entrepreneur: "Entrepreneur",
		// createur: "Créateur",
		createur: "Création",
		// dirigeant: "Dirigeant",
		dirigeant: "Entreprise",
		// evaluateur: "Évaluateur",
		evaluateur: "Multi-Entreprises",
		accompagnateur: "Accompagnateur",
		accompagnement: "Accompagnement",
		etudiant: "Étudiant",
		enseignant: "Enseignant",
		"ebizview-business-starter": "Business Starter",
		"ebizview-business-pilot": "Business Pilot",
		"ebizview-business-partner": "Business Partner",
	},
	offerTaglines: {
		createur: "Transformez votre vision en prévisionnel financier",
		dirigeant: "Anticipez et maîtrisez vos performances",
		evaluateur: "Pilotez et consolidez vos entreprises",
		accompagnement: "Accompagnez et outillez vos clients sur leurs enjeux stratégiques",
	},
	offerPriceInfo: {
		createur: null,
		dirigeant: null,
		evaluateur: "5 entreprises incluses",
		accompagnement: "+ facturation au module/dossier",
	},
	offerDescriptionPages: {
		createur: config.appParams[config.defaultAppName].publicSiteUrl + "/details-creation",
		dirigeant: config.appParams[config.defaultAppName].publicSiteUrl + "/details-entreprise",
		evaluateur: config.appParams[config.defaultAppName].publicSiteUrl + "/details-multi-entreprises",
		accompagnement: config.appParams[config.defaultAppName].publicSiteUrl + "/details-accompagnement",
	},
	offerColors: {
		createur: "#3142C6",
		dirigeant: "#18107F",
		evaluateur: "#559196",
		accompagnateur: "#21666C",
		accompagnement: "#21666C",
		etudiant: "#21666C",
		enseignant: "#21666C",
	},
	subscriptionTypeNames: (isFeatureBasedSubscription) => ({
		Abonnement: "Abonnement",
		AbonnementSecondaire: "Abonnement supplémentaire",
		Option: "Option",
		DossierArchive: "Dossier archivé",
		DossierSupplement: "Dossier supplémentaire",
		SupplementDossier: isFeatureBasedSubscription ? "Dossiers" : "Suppléments dossiers",
	}),
	customPlanNbrCompaniesDisplay: {
		"2-companies": "2",
		"5-companies": "5",
		"10-companies": "10",
		"unlimited-companies": <React.Fragment>&infin;</React.Fragment>,
	},
	customPlanNames: {
		"2-companies": "2 dossiers",
		"5-companies": "5 dossiers",
		"10-companies": "10 dossiers",
		"unlimited-companies": "illimité",
		"white-label": "marque blanche",
		"white-label-5-companies": "marque blanche",
	},
	optionFeatures: {
		aws: ["benchmark", "market-data", "simulations", "products"],
		ows: ["benchmark", "market-data", "simulations", "french-cloud"],
	},
	optionFeatureLabels: {
		benchmark: "Comparaison aux pairs",
		"market-data": "Données sectorielles et Recherche d'entreprises",
		// simulations: "Simulations",
		simulations: "Stress-tests",
		products: "Segmentation analytique",
		"secondary-account": "Comptes supplémentaires",
		"french-cloud": (
			<React.Fragment>
				Cloud français sécurisé
				<FrCloudInfoTip />
			</React.Fragment>
		),
	},
	availableOptions: {
		aws: {
			entrepreneur: ["benchmark"],
			createur: [
				// "benchmark",
				"simulations",
				"products",
			],
			dirigeant: ["benchmark", "simulations", "products"],
			evaluateur: ["market-data", "products"],
			accompagnateur: ["products"],
			accompagnement: ["products"],
			"ebizview-business-starter": ["simulations"],
			"ebizview-business-pilot": ["simulations", "products"],
			"ebizview-business-partner": ["products"],
		},
		ows: {
			entrepreneur: ["benchmark"],
			createur: [
				// "benchmark",
				"simulations",
				"french-cloud",
			],
			dirigeant: ["benchmark", "simulations", "products", "french-cloud"],
			evaluateur: ["market-data", "products", "french-cloud"],
			accompagnateur: ["products", "french-cloud"],
			accompagnement: ["products", "french-cloud"],
			"ebizview-business-starter": ["simulations"],
			"ebizview-business-pilot": ["simulations", "products"],
			"ebizview-business-partner": ["products"],
		},
	},
	whiteLabelParameters: {
		customPlan: "white-label",
		subscriptionType: "Abonnement",
		// subscriptionProfile: "accompagnateur",
		subscriptionProfile: "accompagnement",
		maxCompanies: 50,
	},
};

export const sortSubscriptionsDescStart = (a, b) => {
	return new Date(b.created).getTime() - new Date(a.created).getTime();
};

export const companyFeatures = ["forecasts", "dashboard"];

export const advisorFeatureParameters = {
	forecasts: {
		label: "Prévisionnel",
		// color: "#2534A8",
		color: "#3142c6",
		// color: "#99a2e6",
	},
	dashboard: {
		label: "Tableau de bord",
		// color: "#216872",
		color: "#559196",
		// color: "#adced1",
	},
	"all_features": {
		label: "Tous modules",
		// color: "#18107F",
		color: "#21242F",
	},
	"no_feature": {
		label: "Aucun module",
		color: "#B8B8BA",
	},
};
