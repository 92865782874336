import React from "react";
import {Route, Redirect} from "react-router-dom";

const SessionConditionalRoute = ({component: C, props: cProps, ...rest}) => (
	<Route
		{...rest}
		render={(props) =>
			cProps.userToken === null || cProps.userToken === "" ? (
				/*<Redirect to="/login" />*/
				<Redirect
					to={{
						pathname: "/login",
						state: {referrer: props.location.pathname},
					}}
				/>
			) : cProps.userActivation !== "2" ? (
				<Redirect to="/activationpending" />
			) : props.match.path === "/company" || cProps.userSiren ? (
				<div className="appPage">
					<div className="appContainer inAppContent">
						<C {...props} {...cProps} />
					</div>
				</div>
			) : (
				cProps.userAppAccess.featuresAccess && cProps.userAppAccess.featuresAccess["companies-portfolio"] ? (
					<Redirect to="/companies" />
				) : (
					<Redirect to="/company" />
				)
			)
		}
	/>
);
export default SessionConditionalRoute;
